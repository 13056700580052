import FeedbackService from '@/services/FeedbackService'
import { useFiltersStore } from '@/store'
import useChatStore from '@/store/useChatStore'
import useToastMessageStore from '@/store/useToastMessageStore'
import { useCallback } from 'react'
import { shallow } from 'zustand/shallow'
import { cloneObject } from '@/utils/object'
import { ChatModel, SearchWithChatPayload } from '@/types/feedbacks/FeedbackRequests'
import { FilterItem } from '@/types/filters/Filters'
import { useChatFeedbackStore } from '@/store/useFeedbackStore'
import useLetterCase from './useLetterCase'
import useAccountsToFeedback from './useAccountsToFeedback'
import useCustomerUsersToFeedbacks from './useCustomerUsersToFeedback'
import useAccountsFilters from './filters/useAccountsFilters'
import useLogging from './useLogging'

const useChat = () => {
  const { history, fieldsToUse, model } = useChatStore(
    state => ({
      fieldsToUse: state.fieldsToUse,
      history: state.history,
      model: state.model
    }),
    shallow
  )
  const toPayload = useFiltersStore(state => state.toFeedbackRequestPayload)

  const setChatData = useChatStore(state => state.setChatData)
  const setIsLoadingChatData = useChatStore(state => state.setIsLoadingChatData)
  const setFieldsToUse = useChatStore(state => state.setFieldsToUse)

  const setLoading = useChatFeedbackStore(state => state.setLoading)
  const setData = useChatFeedbackStore(state => state.setData)
  const setHasMore = useChatFeedbackStore(state => state.setHasMore)
  const setTotal = useChatFeedbackStore(state => state.setTotal)
  const reset = useChatFeedbackStore(state => state.reset)

  const { fields: accountFields } = useAccountsFilters()
  const { checkAccountsFromFeedbacks } = useAccountsToFeedback()
  const { checkUsersFromFeedbacks } = useCustomerUsersToFeedbacks()

  const { capitalizeFirst } = useLetterCase()

  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const { logException } = useLogging({ context: 'chat' })

  const getPreDefinedPrompts = useCallback(async () => {
    const [error, data] = await FeedbackService.getChatPrompts()

    if (error) {
      const message = 'Failed to get pre-defined prompts for chat.'
      logException(error, { message })
      addErrorToast({ text: `${message} Message: ${error.key}` })
      return
    }

    return data
  }, [addErrorToast, logException])

  const isAccountsField = useCallback(
    (key: string) => {
      return !!accountFields.find(field => field.key === key && field.path)
    },
    [accountFields]
  )

  /**
   * Get info about the chat such as feedback taken into account
   *
   * The API also return the feedback list, so re-use it with the feedbacks store and hooks
   */
  const getChatFeedbacks = useCallback(
    async (customModel?: ChatModel) => {
      setIsLoadingChatData(true)
      setLoading(true)

      const size = (customModel || model) === 'openai' ? 200 : 1000
      const searchPayload: SearchWithChatPayload['feedback_search'] = cloneObject(
        toPayload(0, size, false)
      )

      searchPayload.filter.feedback_keyword_classes =
        searchPayload.filter.feedback_keyword_classes?.map(capitalizeFirst)
      searchPayload.order_by = 'feedback_hash'

      searchPayload.filter.has_signal = true

      const filteredHistory = history
        .filter(message => !message.isInfoMessage)
        .map(item => ({ role: item.role, content: item.content }))

      const payload: SearchWithChatPayload = {
        feedback_search: searchPayload,
        question: '',
        stream: false,
        feedback_id_list: [],
        model: 'openai',
        history: filteredHistory,
        feedback_input_fields: fieldsToUse.map(field => ({
          type: field.type,
          key:
            isAccountsField(field.key) && field.path ? `account_fields.${field.path}` : field.key,
          name: isAccountsField(field.key) ? `Account fields: ${field.displayName}` : field.name
        }))
      }
      const [error, data] = await FeedbackService.getChatFeedbackCount(payload)

      if (error) {
        console.error(error)
        setChatData(undefined)
        setLoading(false)
        setData([])
        setHasMore(false)
        setIsLoadingChatData(false)

        if (error.key === 'no_feedbacks_to_perform_answering') return

        const message = 'Failed to get chat data with current filters and fields.'
        logException(error, { message })
        addErrorToast({ text: `${message} Message: ${error.key}` })

        return undefined
      }

      setIsLoadingChatData(false)
      setLoading(false)

      setChatData(data)
      setData(data.feedbackList)
      setHasMore(data.feedbackList.length === 0)
      setTotal(data.feedbackTotalHits)
      checkAccountsFromFeedbacks(data.feedbackList)
      checkUsersFromFeedbacks(data.feedbackList)
      // if (feedbackSize === undefined) setFeedbackSize(data.feedbackTakenIntoAccount)

      return data.feedbackTakenIntoAccount
    },
    [
      history,
      fieldsToUse,
      model,
      toPayload,
      addErrorToast,
      capitalizeFirst,
      checkAccountsFromFeedbacks,
      logException,
      checkUsersFromFeedbacks,
      isAccountsField,
      setChatData,
      setData,
      setHasMore,
      setIsLoadingChatData,
      setLoading,
      setTotal
    ]
  )

  const saveFields = useCallback(
    (newFields: FilterItem[]) => {
      reset()
      setFieldsToUse(newFields)
    },
    [reset, setFieldsToUse]
  )

  return { getPreDefinedPrompts, getChatFeedbacks, saveFields }
}

export default useChat
