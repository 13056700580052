import MetricsService from '@/services/MetricsService'
import useDateFilterStore from '@/store/useFiltersStore/useDateFilterStore'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import { FeedbackListQueryParams } from '@/types/feedbacks/FeedbackRequests'
import { AllMetricsKey, MetricsRequests } from '@/types/metrics'
import { BreakdownOption, TimeSeriesIntervalOption } from '@/types/time-series/TimeSeries'
import { endDateParam, startDateParam } from '@/utils/date'
import { allMetricItems } from '@/utils/metrics'
import { useQuery } from '@tanstack/react-query'
import useLogging from '@/hooks/useLogging'
import useOpportunityStore from '@/store/useOpportunityStore'
import useMetricListPayload from '@/hooks/metrics/useMetricListPayload'
import useCompetitorsModeStore from '@/store/useCompetitorsModeStore'

interface Params {
  filters?: FeedbackListQueryParams[]
  metric?: AllMetricsKey
  interval: TimeSeriesIntervalOption
  group?: string
  breakdown?: BreakdownOption
  enabled?: boolean
}

const useMetricTimeSeriesQuery = ({
  filters = [],
  metric = 'count',
  interval,
  group,
  breakdown,
  enabled = true
}: Params) => {
  const currentInterestArea = useCurrentInterestAreaStore(state => state.currentInterestArea)
  const currentOpportunity = useOpportunityStore(state => state.currentOpportunity)

  const { logException } = useLogging({ context: 'useMetricTimeSeriesQuery' })

  const { dateRange, datePeriod } = useDateFilterStore(state => ({
    dateRange: state.dateRange,
    datePeriod: state.datePeriod
  }))

  const { getMetricAndAddShareFilter } = useMetricListPayload()

  const { competitorsModeEnabled, competitorsFilterValues } = useCompetitorsModeStore(state => ({
    competitorsModeEnabled: state.competitorsModeEnabled,
    competitorsFilterValues: state.competitorsFilterValues
  }))

  const queryFn = async () => {
    let startDate: string | undefined
    let endDate: string | undefined
    if (datePeriod !== 'allTime' && dateRange) {
      startDate = startDateParam(dateRange.start)
      endDate = endDateParam(dateRange.end)
    }

    const timeseriesMetric = allMetricItems[metric]
    if (!timeseriesMetric) {
      const metricError = new Error(`Metric '${metric}' not found`)
      logException(metricError, { message: metricError.message })
      throw metricError
    }

    const filterList = filters.map(filter => {
      return {
        ...filter,
        opportunity_id: filter.opportunity_id || currentOpportunity?.id,
        label: undefined,
        'posted_at.lt': undefined,
        'posted_at.gte': undefined
      }
    })

    const currentMetric = getMetricAndAddShareFilter(metric)

    if (competitorsModeEnabled) {
      currentMetric.share_filter = {
        ...currentMetric.share_filter,
        'review.owner': competitorsFilterValues
      } as FeedbackListQueryParams
    }

    const payload: MetricsRequests.MetricsTimeseriesPayload = {
      filter_list: filterList,
      metric_list: [currentMetric],
      group: group ? [group] : [],
      interval: interval,
      posted_at_gte: startDate,
      posted_at_lt: endDate,
      include_extra: true
    }

    const [error, data] = await MetricsService.timeseries(payload)
    if (error) throw error
    return data
  }

  const { data, isLoading } = useQuery({
    queryKey: [
      'metric-timeseries',
      filters,
      { dateRange, datePeriod },
      metric,
      interval,
      group,
      breakdown,
      currentInterestArea,
      currentOpportunity?.id
    ],
    queryFn,
    enabled: filters.length > 0 && enabled,
    retry: false
  })

  return {
    data,
    isLoading
  }
}

export default useMetricTimeSeriesQuery
