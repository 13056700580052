import { useCallback } from 'react'
import { NavigateOptions, To, useLocation, useNavigate } from 'react-router-dom'

const useNavitagateTo = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  /**
   * navigate function that set "from" in state
   */
  const navigateTo = useCallback(
    (to: To, options?: NavigateOptions) => {
      return navigate(to, { ...options, state: { ...options?.state, from: pathname } })
    },
    [pathname, navigate]
  )

  return { navigateTo }
}

export default useNavitagateTo
