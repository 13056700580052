import { NPS_METRICS_KEYS, npsTimeSeries } from './npsMetrics'
import { REVIEW_METRICS_KEYS, reviewTimeseries } from './reviewMetrics'
import { SUPPORT_TICKET_METRICS_KEYS, supportTicketTimeseries } from './supportTicketMetrics'
import { CSAT_METRICS_KEYS, csatTimeSeries } from './csatMetrics'
import { ISSUE_METRICS_KEYS, issueTimeSeries } from './issueMetrics'
import { OVERALL_METRIC_KEYS, overallTimeseries } from './overallMetrics'
import { SOCIAL_MEDIA_METRICS_KEYS, socialMediaTimeSeries } from './socialMediaMetrics'
import { TRANSCRIPT_METRICS_KEYS, transcriptTimeseries } from './transcriptMetrics'
import type { AllMetricsKey, MetricItem, MetricKey } from '@/types/metrics'

export const allMetricItems: Record<AllMetricsKey, MetricItem> = {
  ...supportTicketTimeseries,
  ...npsTimeSeries,
  ...overallTimeseries,
  ...socialMediaTimeSeries,
  ...issueTimeSeries,
  ...csatTimeSeries,
  ...reviewTimeseries,
  ...transcriptTimeseries
}

export const metricKeyToAllMetricsKeys: Record<MetricKey, AllMetricsKey[]> = {
  count: OVERALL_METRIC_KEYS,
  feedback_share: OVERALL_METRIC_KEYS,
  nps: NPS_METRICS_KEYS,
  support_ticket: SUPPORT_TICKET_METRICS_KEYS,
  csat: CSAT_METRICS_KEYS,
  issue: ISSUE_METRICS_KEYS,
  review: REVIEW_METRICS_KEYS,
  social_media_post: SOCIAL_MEDIA_METRICS_KEYS,
  transcript: TRANSCRIPT_METRICS_KEYS
}

export const allMetricItemsList = Object.entries(allMetricItems).map(([key, value]) => ({
  key,
  ...value
})) as (MetricItem & { key: AllMetricsKey })[]

export const allMetricsGroupedByMetricKey: Record<
  MetricKey,
  Partial<Record<AllMetricsKey, MetricItem>>
> = {
  count: overallTimeseries,
  feedback_share: overallTimeseries,
  nps: npsTimeSeries,
  support_ticket: supportTicketTimeseries,
  csat: csatTimeSeries,
  issue: issueTimeSeries,
  review: reviewTimeseries,
  social_media_post: socialMediaTimeSeries,
  transcript: transcriptTimeseries
}

export const metricNames: Record<MetricKey, string> = {
  count: 'Overall count',
  support_ticket: 'Tickets count',
  issue: 'Issues count',
  social_media_post: 'Social media count',
  nps: 'NPS score',
  csat: 'CSAT score',
  review: 'Review score',
  feedback_share: 'Feedback share',
  transcript: 'Transcripts count'
}

export const COLLECTION_METRICS: AllMetricsKey[] = [
  'ticket_share_over_collection',
  'nps_impact_over_collection'
]

export const AREA_METRICS: AllMetricsKey[] = [
  'ticket_share_over_area',
  'share_over_area',
  'nps_detractors_over_area_responses',
  'nps_detractors_over_area_responses_with_comments',
  'review_share_over_area',
  'review_share_unsatisfied_over_area_reviews_with_comments'
]

export const getMetricByColumn = (column: string) => {
  const metricType = column.split(':')[0] as MetricKey
  const metricKey = column.split(':')[1] as AllMetricsKey

  const metric = allMetricsGroupedByMetricKey[metricType][metricKey]
  return metric
}
