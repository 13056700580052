import { useEffect, useMemo, useRef, useState } from 'react'
import { TimeSeriesGroup, TimeSeriesIntervalOption } from '@/types/time-series/TimeSeries'
import moment from 'moment'
import { getXAxisData } from '../analytics/trend-line/trendLineEChartOptions'
import { mapTimeSeriesIntervalToAnalyticsInterval } from '@/hooks/analytics/useTimeSeriesOptions'
import { ArrowUp, ArrowUUpRight, CopySimple } from '@phosphor-icons/react'
import useToastMessageStore from '@/store/useToastMessageStore'
import copyHTMLToClipboard from '@/utils/clipboard'
import {
  ColumnDef,
  ColumnSort,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'
import TableV2 from '@/components/atoms/table-v2'
import TimeseriesTableStyles from './TimeseriesTable.styles'
import { colors } from '@/theme'
import CompetitorsEnabledButton from '../trend-line/CompetitorsEnabledButton'
import FlexContainer from '@/components/atoms/flex-container'
import { useTranslation } from 'react-i18next'

interface Props {
  timestamps: moment.Moment[]
  interval: TimeSeriesIntervalOption
  timeSeries: TimeSeriesGroup[]
  isTransposed: boolean
  onCopy?: () => void
  onTransposeClick: () => void
}

type TimeSeriesTableItem = { name: string } & Record<string, number>

const TimeseriesTable = ({
  timestamps,
  interval,
  timeSeries,
  isTransposed,
  onCopy,
  onTransposeClick
}: Props) => {
  const [sorting, setSorting] = useState<ColumnSort[]>([])
  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)
  const tableRef = useRef<HTMLTableElement>(null)

  const { t } = useTranslation()

  const timestampsAxis = useMemo(
    () => getXAxisData(timestamps, mapTimeSeriesIntervalToAnalyticsInterval[interval]),
    [timestamps, interval]
  )

  const nameAxis = useMemo(() => {
    return timeSeries.map(timeserie => timeserie.name)
  }, [timeSeries])

  const data = useMemo(() => {
    if (isTransposed) {
      const transposedData: TimeSeriesTableItem[] = timeSeries.reduce((acc, item) => {
        let previuousSeries = [...acc]
        item.values.forEach((v, i) => {
          const name = String(timestampsAxis[i])
          const serie =
            previuousSeries.find(s => s.name === name) ?? ({ name } as TimeSeriesTableItem)
          serie[item.name] = v

          if (!previuousSeries.find(s => s.name === name)) {
            previuousSeries.push(serie)
          } else {
            previuousSeries = previuousSeries.map(pSerie => (pSerie.name === name ? serie : pSerie))
          }
        })

        return previuousSeries
      }, [] as TimeSeriesTableItem[])

      return transposedData
    }

    return timeSeries.map(item => {
      const serie: TimeSeriesTableItem = { name: item.name } as TimeSeriesTableItem
      item.values.forEach((v, i) => {
        serie[String(timestampsAxis[i])] = v
      })
      return serie
    })
  }, [timestampsAxis, timeSeries, isTransposed])

  const onClickCopy = () => {
    copyHTMLToClipboard(tableRef.current)
    addSuccessToast({ text: `Copied to clipboard` })
    onCopy?.()
  }

  const xAxisColumns = useMemo(() => {
    const currentXAxis = isTransposed ? nameAxis : timestampsAxis

    return currentXAxis.map(
      (x): ColumnDef<TimeSeriesTableItem> => ({
        accessorKey: String(x),
        id: String(x),
        header: String(x),
        enableHiding: false,
        enableSorting: true,
        sortDescFirst: true,
        minSize: 180,
        maxSize: 190,
        cell: data => {
          return (
            <TimeseriesTableStyles.Cell>
              <span>{data.row.original[String(x)]}</span>
            </TimeseriesTableStyles.Cell>
          )
        }
      })
    )
  }, [timestampsAxis, isTransposed, nameAxis])

  const table = useReactTable({
    data,
    columns: [
      {
        accessorKey: 'name',
        id: 'name',
        header: () => (
          <TimeseriesTableStyles.TableButton
            onClick={onTransposeClick}
            icon={<ArrowUUpRight />}
            text="Transpose axis"
          />
        ),
        enableHiding: false,
        enableSorting: false,
        minSize: 360,
        maxSize: 400,
        cell: data => (
          <TimeseriesTableStyles.NameCell>
            <span>{data.row.original.name}</span>
          </TimeseriesTableStyles.NameCell>
        )
      } as ColumnDef<TimeSeriesTableItem>,
      ...xAxisColumns
    ],
    manualFiltering: false,
    enableSorting: true,
    getRowId: row => row.name,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
      columnPinning: {
        left: ['name']
      }
    }
  })

  // biome-ignore lint/correctness/useExhaustiveDependencies: reset sorting on interval or isTransposed changes
  useEffect(() => {
    table.resetSorting()
  }, [table, isTransposed, interval])

  return (
    <>
      <TableV2
        ref={tableRef}
        table={table}
        thContainerProps={TimeseriesTableStyles.thContainerProps}
        thCss={TimeseriesTableStyles.thCss}
        tdCss={TimeseriesTableStyles.tdCss}
        sortIndicator={<ArrowUp color={colors.neutralLowPure} size={16} />}
        enableSort
      />

      <FlexContainer alignItems="center" justifyContent="flexEnd" gap="micro">
        <CompetitorsEnabledButton />

        <TimeseriesTableStyles.TableButton
          onClick={onClickCopy}
          text={t('copyTable')}
          icon={<CopySimple />}
        />
      </FlexContainer>
    </>
  )
}

export default TimeseriesTable
