import { BaseInterestArea } from '@/types/area/AreaOfInterest'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { AreaRequests } from '@/types/area'
import AreaService from '@/services/AreaService'
import { mapArea } from '@/utils/areaOfInterest'

interface Params {
  enabled: boolean
  collectionId?: string
}

const useBasicAreaOfInterestQuery = (
  { enabled = true, collectionId }: Params = { enabled: true }
) => {
  const queryFn = async () => {
    const params: AreaRequests.SearchAreasParams = {
      limit: 300,
      page: 1,
      transform: true
    }

    if (collectionId) {
      params.collection_id = collectionId
    }

    const [error, response] = await AreaService.searchAreas(params)
    if (error) throw error
    return response?.areas ?? []
  }

  const queryKey = useMemo(() => ['interest-areas', collectionId], [collectionId])

  const { data, ...query } = useQuery({
    queryKey,
    queryFn,
    enabled,
    refetchOnMount: false
  })

  const areas: BaseInterestArea[] = useMemo(() => data?.map(mapArea) ?? [], [data])

  return { areas, queryKey, ...query }
}

export default useBasicAreaOfInterestQuery
