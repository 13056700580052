import NewOpportunity from '../NewOpportunity'
import useSegment from '@/hooks/useSegment'
import { styled } from '@/theme'
import FlexContainer from '@/components/atoms/flex-container'
import shortUUID from 'short-uuid'
import { useMemo } from 'react'
import useAdvancedFilters from '@/hooks/advancedFilters/useAdvancedFilters'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import { OpportunityItemWithMetrics } from '@/types/opportunity/Opportunity'
import { LinkFromTo } from '@/components/atoms/links'

const NameContainer = styled(FlexContainer, {
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  padding: '$xxs',
  pt: 'calc($xxs + 3px)',
  width: '100%',
  position: 'relative'
})

const StyledLink = styled(LinkFromTo, {
  all: 'unset',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'flex-start',
  gap: '$nano',
  alignSelf: 'center'
})

const StyledNameCell = styled('span', {
  fontWeight: '$regular',
  fontSize: '$micro',
  textAlign: 'left',
  lineHeight: '13.8px',
  my: 'auto'
})

interface Props {
  data: OpportunityItemWithMetrics
}

const OpportunityNameCell = ({ data }: Props) => {
  const { track } = useSegment()

  const currentArea = useCurrentInterestAreaStore(state => state.currentInterestArea)
  // const setCurrentArea = useCurrentInterestAreaStore(state => state.setCurrentInterestArea)

  const { applyOpportunityFilters } = useAdvancedFilters()

  const onClickName = () => {
    // setCurrentOpportunity(data)

    applyOpportunityFilters({
      opportunity: data,
      areaIds: currentArea ? [currentArea?.id] : [],
      clearAllFilters: true
    })
    // setCurrentArea(undefined)
    track('opportunity_access', { opportunity_name: data.name, from: 'area' })
  }

  const translator = useMemo(() => shortUUID(), [])
  // const shortAreaId = useMemo(
  //   () => (currentArea?.id ? translator.fromUUID(currentArea.id) : ''),
  //   [currentArea, translator]
  // )
  const shortOpportunityId = useMemo(
    () => (data.id ? translator.fromUUID(data.id) : ''),
    [data, translator]
  )

  const to = `/opportunity/${shortOpportunityId}`

  return (
    <NameContainer>
      <StyledLink onClick={onClickName} to={to}>
        <StyledNameCell>{data.name}</StyledNameCell>
      </StyledLink>
      {data.new && (
        <NewOpportunity.Badge
          css={{ whiteSpace: 'pre', maxHeight: 16, position: 'absolute', top: 1, left: 16 }}
        />
      )}
    </NameContainer>
  )
}

export default OpportunityNameCell
