import { FilterDatetimeValue } from '@/types/filters/Filters'
import { Period } from '@/types/periods'
import { getDateRangeFromNow } from '@/utils/date'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'
import { persist, createJSONStorage } from 'zustand/middleware'
import { parseDate } from '@internationalized/date'

interface DateFilterState {
  dateRange: FilterDatetimeValue
  datePeriod: Period
  setDateFilter: (dateRange: FilterDatetimeValue, datePeriod?: Period) => void
}

// biome-ignore lint/suspicious/noExplicitAny: too generic
const replacer = (key: string, value: any): any => {
  if (key === 'start' || key === 'end') {
    const isoString = new Date(value).toISOString()
    return isoString
  }
  return value
}

// biome-ignore lint/suspicious/noExplicitAny: too generic
const reviver = (key: string, value: any): any => {
  if (key === 'start' || key === 'end') {
    const parsedDate = parseDate(value.slice(0, 10))
    return parsedDate
  }
  return value
}

const customStorage = createJSONStorage(() => localStorage, {
  reviver,
  replacer
})

const useDateFilterStore = createWithEqualityFn<DateFilterState>()(
  persist(
    set => ({
      dateRange: getDateRangeFromNow('30days'),
      datePeriod: '30days',
      setDateFilter: (dateRange, datePeriod) =>
        set(state => ({
          dateRange,
          datePeriod: datePeriod || state.datePeriod
        }))
    }),
    {
      name: 'date-filter-store',
      version: 1,
      storage: customStorage
    }
  ),
  shallow
)

export default useDateFilterStore
