import { AreaOfInterestData } from '@/types/area/AreaOfInterest'
import { useMemo } from 'react'
import { NormalizedAreaTableData, NormalizedAreaTypes } from './AreaOfInterestTable.types'
import { ONGOING_STATUS } from '@/utils/opportunityUtils'

const useNormalizedAreas = (areas: AreaOfInterestData[], unmappedAreas?: AreaOfInterestData) => {
  const areasArray = useMemo(
    () => (unmappedAreas ? [unmappedAreas, ...areas] : areas),
    [areas, unmappedAreas]
  )

  const normalizedAreas = useMemo(() => {
    const _normalizedAreas: NormalizedAreaTableData[] = areasArray.map(area => ({
      type: 'area-interest' as NormalizedAreaTypes,
      originalArea: area,
      id: area.id,
      name: area.name,
      opportunityCount: area.opportunityCount,
      metrics: area.metrics,
      opportunities: (area.opportunities ?? [])
        .filter(opportunity => ONGOING_STATUS.includes(opportunity.status))
        .map(opportunity => ({
          type: 'opportunity' as NormalizedAreaTypes,
          originalOpportunity: opportunity,
          originalArea: area,
          id: opportunity.id,
          favorite: false,
          name: opportunity.name,
          new: opportunity.new,
          opportunityCount: 0,
          metrics: opportunity.metrics,
          status: opportunity.status,
          createdAt: opportunity.createdAt
        })) as NormalizedAreaTableData[]
    }))

    return _normalizedAreas
  }, [areasArray])

  return normalizedAreas
}

export default useNormalizedAreas
