import { useCallback, useMemo } from 'react'
import useCollections from '../collections/useCollections'
import { getParamsFromFilterContent } from '@/utils/filters'
import { SavedFilterContent } from '@/types/filters/Filters'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import useFeedQueryParams from '../feedback/new/useFeedQueryParams'
import { FeedbackListQueryParams } from '@/types/feedbacks/FeedbackRequests'
import { MetricListPayloadItem } from '@/types/metrics/MetricsRequests'
import {
  allMetricItems,
  allMetricItemsList,
  AREA_METRICS,
  COLLECTION_METRICS,
  metricKeyToAllMetricsKeys,
  NPS_TIMESERIES_METRICS_KEYS,
  OVERALL_METRIC_KEYS,
  SUPPORT_TICKET_TIMESERIES_METRICS_KEYS
} from '@/utils/metrics'
import { BreakdownOption } from '@/types/time-series/TimeSeries'
import { AllMetricsKey, MetricKey } from '@/types/metrics'
import useUser from '../useUser'
import useBasicAreaOfInterestQuery from '../areaOfInterest/useBasicAreaOfInterestQuery'

const useMetricListPayload = () => {
  const { contentData } = useCollections()
  const { currentInterestArea } = useCurrentInterestAreaStore()

  const { queryParams } = useFeedQueryParams()

  const { areas: advancedAreas } = useBasicAreaOfInterestQuery()

  const { organization } = useUser()

  const collectionShareFilter = useMemo(() => {
    const rawContent = contentData?.content
    const content = Array.isArray(rawContent) ? (rawContent as SavedFilterContent[]) : []

    return {
      ...getParamsFromFilterContent(content),
      context: contentData?.context,
      label: undefined,
      'posted_at.lt': undefined,
      'posted_at.gte': undefined
    }
  }, [contentData])

  const areaShareFilter = useMemo(() => {
    if (!currentInterestArea) return {}

    if (!advancedAreas.length) return {}

    const currentAdvancedArea = currentInterestArea.isUnmappedArea
      ? currentInterestArea
      : advancedAreas.find(area => area.id === currentInterestArea.id)

    return {
      context: currentAdvancedArea?.context,
      label: undefined,
      'posted_at.lt': undefined,
      'posted_at.gte': undefined
    } as FeedbackListQueryParams
  }, [advancedAreas, currentInterestArea])

  const getMetricKeys = useCallback(
    (
      metricKey: MetricKey,
      options?: {
        breakdown?: BreakdownOption
        specificBreakdowns?: BreakdownOption[]
        enableShareOverFilters?: boolean
        useTimeseriesMetrics?: boolean
      }
    ) => {
      let list: AllMetricsKey[] = metricKeyToAllMetricsKeys[metricKey]

      if (!list) return

      if (metricKey === 'support_ticket') {
        const supportTicketMetrics = options?.useTimeseriesMetrics
          ? SUPPORT_TICKET_TIMESERIES_METRICS_KEYS
          : metricKeyToAllMetricsKeys.support_ticket

        const hasContactRatePremise =
          Object.keys(organization?.config?.activeUsersByDate ?? {}).length > 0

        list = hasContactRatePremise
          ? supportTicketMetrics
          : supportTicketMetrics.filter(key => key !== 'ticket_contact_rate')
      }
      if (metricKey === 'nps' && options?.useTimeseriesMetrics) {
        list = NPS_TIMESERIES_METRICS_KEYS
      }

      if (
        metricKey === 'count' &&
        options?.breakdown &&
        options?.specificBreakdowns?.includes(options.breakdown) &&
        options?.enableShareOverFilters
      ) {
        const subMetrics = [...OVERALL_METRIC_KEYS]
        const defaultFeedbackShareIndex = subMetrics.findIndex(
          metricKey => metricKey === 'feedback_share'
        )

        subMetrics.splice(defaultFeedbackShareIndex + 1, 0, 'feedback_share_group')
        list = subMetrics
      }

      if (!contentData) {
        list = list.filter(item => !COLLECTION_METRICS.includes(item))
      }

      if (!currentInterestArea) {
        list = list.filter(item => !AREA_METRICS.includes(item))
      }

      return list
    },
    [organization, contentData, currentInterestArea]
  )

  const getMetricAndAddShareFilter = useCallback(
    (metricKey: AllMetricsKey): MetricListPayloadItem => {
      const metricItem = allMetricItems[metricKey]

      if (COLLECTION_METRICS.includes(metricKey) && contentData) {
        return {
          ...metricItem.metric,
          args: metricItem.metric.filter,
          share_filter: {
            ...metricItem.metric.share_filter,
            ...collectionShareFilter,
            label: undefined,
            'posted_at.lt': undefined,
            'posted_at.gte': undefined
          }
        }
      }

      if (AREA_METRICS.includes(metricKey) && currentInterestArea) {
        return {
          ...metricItem.metric,
          args: metricItem.metric.filter,
          share_filter: {
            ...metricItem.metric.share_filter,
            ...areaShareFilter,
            label: undefined,
            'posted_at.lt': undefined,
            'posted_at.gte': undefined
          }
        }
      }

      if (metricKey === 'feedback_share_group') {
        return {
          ...metricItem.metric,
          args: metricItem.metric.filter,
          share_filter: {
            ...metricItem.metric.share_filter,
            ...queryParams,
            label: undefined,
            'posted_at.lt': undefined,
            'posted_at.gte': undefined
          }
        }
      }

      return {
        ...metricItem.metric,
        args: metricItem.metric.filter
      }
    },
    [areaShareFilter, collectionShareFilter, queryParams, contentData, currentInterestArea]
  )

  const addShareFiltersToMetrics = useCallback(
    (metricList: MetricListPayloadItem[]) => {
      const metricListWithShareFilters: (MetricListPayloadItem | null)[] = metricList.map(
        metricPayloadItem => {
          const metricItem = allMetricItemsList.find(
            item => item.metric.label === metricPayloadItem.label
          )
          if (!metricItem)
            return {
              ...metricPayloadItem,
              args: metricPayloadItem.filter
            }

          if (COLLECTION_METRICS.includes(metricItem.key)) {
            if (!contentData) return null
            return {
              ...metricPayloadItem,
              args: metricPayloadItem.filter,
              share_filter: {
                ...metricPayloadItem.share_filter,
                ...collectionShareFilter,
                label: undefined,
                'posted_at.lt': undefined,
                'posted_at.gte': undefined
              }
            }
          }

          if (AREA_METRICS.includes(metricItem.key)) {
            if (!currentInterestArea) return null
            return {
              ...metricPayloadItem,
              args: metricPayloadItem.filter,
              share_filter: {
                ...metricPayloadItem.share_filter,
                ...areaShareFilter,
                label: undefined,
                'posted_at.lt': undefined,
                'posted_at.gte': undefined
              }
            }
          }

          if (metricItem.key === 'feedback_share_group') {
            return {
              ...metricPayloadItem,
              args: metricPayloadItem.filter,
              share_filter: {
                ...metricPayloadItem.share_filter,
                ...queryParams,
                label: undefined,
                'posted_at.lt': undefined,
                'posted_at.gte': undefined
              }
            }
          }

          return {
            ...metricPayloadItem,
            args: metricPayloadItem.filter
          }
        }
      )

      return metricListWithShareFilters.filter(Boolean) as MetricListPayloadItem[]
    },
    [areaShareFilter, collectionShareFilter, queryParams, contentData, currentInterestArea]
  )

  return { addShareFiltersToMetrics, getMetricKeys, getMetricAndAddShareFilter }
}

export default useMetricListPayload
