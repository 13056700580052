import Dialog from '@/components/atoms/dialog'
import Divider from '@/components/atoms/divider'
import FlexContainer from '@/components/atoms/flex-container'
import Input from '@/components/atoms/input'
import Text from '@/components/atoms/text'
import useBasicAreaOfInterestQuery from '@/hooks/areaOfInterest/useBasicAreaOfInterestQuery'
import useBasicOpportunitiesQuery from '@/hooks/opportunity/useBasicOpportunitiesQuery'
import type { Collection } from '@/types/collection'
import { useEffect, useMemo, useState } from 'react'
import Search from '../search'
import Checkbox, { type CheckboxState } from '@/components/atoms/checkbox'
import Button from '@/components/atoms/button'
import Bone from '@/components/atoms/bone'
import { useTranslation } from 'react-i18next'

const SKELETON_COUNT = 5
const Skeleton = () => {
  return (
    <>
      {Array.from({ length: SKELETON_COUNT }).map((_, index) => (
        <Bone css={{ height: 16, width: '100%' }} key={index} />
      ))}
    </>
  )
}

interface CheckboxItem {
  id: string
  name: string
}

interface Props {
  collection: Collection
  onSaveCollection: (name: string, filterIds: string[]) => void
  open: boolean
  onOpenChange: (open: boolean) => void
}

const EditCollectionModal = ({ collection, onSaveCollection, open, onOpenChange }: Props) => {
  const { type, name } = collection

  const { t } = useTranslation()
  const [nameInput, setNameInput] = useState(name)
  const [checkedIds, setCheckedIds] = useState<string[]>([])

  const { areas: allAreas, isLoading: isAllAreasLoading } = useBasicAreaOfInterestQuery({
    enabled: type === 'area_interest' && open
  })
  const { areas: collectionAreas, isLoading: isCollectionAreasLoading } =
    useBasicAreaOfInterestQuery({
      enabled: type === 'area_interest' && open,
      collectionId: collection.collectionId
    })

  const { opportunities: allOpportunities, isLoading: isAllOpportunitiesLoading } =
    useBasicOpportunitiesQuery({
      enabled: type === 'opportunity' && open
    })
  const { opportunities: collectionOpportunities, isLoading: isCollectionOpportunitiesLoading } =
    useBasicOpportunitiesQuery({
      enabled: type === 'opportunity' && open,
      collectionId: collection.collectionId
    })

  useEffect(() => {
    if (collectionAreas) {
      setCheckedIds(collectionAreas.map(area => area.id))
      return
    }

    if (collectionOpportunities) {
      setCheckedIds(collectionOpportunities.map(opportunity => opportunity.id))
    }
  }, [collectionAreas, collectionOpportunities])

  const checkboxList: CheckboxItem[] = useMemo(() => {
    if (type === 'area_interest') {
      const firstAreas: CheckboxItem[] = collectionAreas.map(area => ({
        name: area.name,
        id: area.id
      }))

      const otherAreas: CheckboxItem[] = allAreas
        .filter(area => !firstAreas.map(area => area.id).includes(area.id))
        .map(area => ({
          name: area.name,
          id: area.id
        }))

      return [...firstAreas, ...otherAreas]
    }

    const firstOpportunities: CheckboxItem[] = collectionOpportunities.map(opportunity => ({
      name: opportunity.name,
      id: opportunity.id
    }))

    const otherOpportunities: CheckboxItem[] = allOpportunities
      .filter(
        opportunity =>
          !firstOpportunities.map(opportunity => opportunity.id).includes(opportunity.id)
      )
      .map(opportunity => ({
        name: opportunity.name,
        id: opportunity.id
      }))

    return [...firstOpportunities, ...otherOpportunities]
  }, [allAreas, collectionAreas, allOpportunities, collectionOpportunities, type])

  const [searchInput, setSearchInput] = useState('')
  const filteredCheckboxList = useMemo(() => {
    return checkboxList.filter(checkbox =>
      checkbox.name.toLowerCase().includes(searchInput.toLowerCase())
    )
  }, [checkboxList, searchInput])

  const onCheckedChange = (checkedState: CheckboxState, id: string) => {
    if (checkedState) {
      setCheckedIds([...checkedIds, id])
    } else {
      setCheckedIds(checkedIds.filter(checkboxId => checkboxId !== id))
    }
  }
  const isChecked = (id: string) => checkedIds.includes(id)

  const title = type === 'area_interest' ? t('editAreaCollection') : t('editOpportunityCollection')
  const selectSectionText =
    type === 'area_interest'
      ? t('selectedAreasOnCollection')
      : t('selectedOpportunitiesOnCollection')

  const onSaveClick = () => {
    onSaveCollection(nameInput, checkedIds)
    onOpenChange(false)
  }

  const isLoading =
    type === 'area_interest'
      ? isAllAreasLoading || isCollectionAreasLoading
      : isAllOpportunitiesLoading || isCollectionOpportunitiesLoading

  const isSaveDisabled = nameInput === '' || checkedIds.length === 0

  return (
    <Dialog align="center" closeIcon modal onOpenChange={onOpenChange} open={open}>
      <FlexContainer direction="column" gap="xs">
        <Text as="h1" typeface="titleBoldXXS">
          {title}
        </Text>

        <FlexContainer direction="column" gap="xxs">
          <Text as="p" fontSize="xxxs">
            {t('collectionName')}
          </Text>
          <Input onChange={e => setNameInput(e.currentTarget.value)} small value={nameInput} />
        </FlexContainer>

        <Divider line="solid" orientation="horizontal" />

        <FlexContainer direction="column" gap="xxs">
          <FlexContainer alignItems="center" justifyContent="spaceBetween">
            <Text as="p" fontSize="xxxs">
              {selectSectionText}
            </Text>
            <Search
              clearable
              expandOnFocus
              onChange={e => setSearchInput(e.currentTarget.value)}
              onClear={() => setSearchInput('')}
              size="small"
              small
              value={searchInput}
            />
          </FlexContainer>
          {isLoading ? (
            <Skeleton />
          ) : (
            <FlexContainer css={{ overflowY: 'auto', maxHeight: 350 }} direction="column" gap="xxs">
              {filteredCheckboxList.map(checkbox => (
                <Checkbox
                  checked={isChecked(checkbox.id)}
                  key={checkbox.id}
                  onCheckedChange={checked => onCheckedChange(checked, checkbox.id)}
                  text={checkbox.name}
                  value={`collection-${checkbox.id}`}
                />
              ))}
            </FlexContainer>
          )}
        </FlexContainer>

        <Button disabled={isSaveDisabled} fullWidth onClick={onSaveClick} size="small">
          {t('save')}
        </Button>
      </FlexContainer>
    </Dialog>
  )
}

export default EditCollectionModal
