import { useFiltersStore } from '@/store'
import { NumericRangeOption } from '@/types/filters/Filters'
import {
  CustomerRecordType,
  CustomerRecordsNumberFieldValue
} from '@/types/manage-customers/CustomerRecordsFilters'
import { useMemo, useState } from 'react'
import { shallow } from 'zustand/shallow'
import useCustomerRecordsFilterQuery from '../common/useCustomerRecordsFilterQuery'
import { useFeedFiltersStore } from '@/store/useFiltersStore'
import useAdvancedFilters from '@/hooks/advancedFilters/useAdvancedFilters'

interface Params {
  filterKey: string
  type: string
  name: string
  enabled: boolean
  recordType: CustomerRecordType
  newFeed?: boolean
}

const useCustomerRecordsNumericFilter = ({
  filterKey,
  type,
  name,
  enabled,
  recordType,
  newFeed = false
}: Params) => {
  const defaultSetParams = { name, type, key: filterKey }

  const { data, isLoading } = useCustomerRecordsFilterQuery[recordType]({ filterKey, enabled })

  const { min, max } = useMemo(() => {
    const defaultRange = { min: 0, max: 10 }
    if (!data) return defaultRange
    const [error, result] = data
    if (error) return defaultRange

    if (result.type === 'number') {
      const resultFilter = result as CustomerRecordsNumberFieldValue
      return { min: resultFilter.min, max: resultFilter.max }
    }
    return defaultRange
  }, [data])

  const useStore = newFeed ? useFeedFiltersStore : useFiltersStore

  const store = {
    accounts: {
      numericFilters: useStore(state => state.accountNumericFilters, shallow),
      setNumericFilter: useStore(state => state.setAccountNumericFilter),
      setNumericOption: useStore(state => state.setAccountNumericOption)
    },
    customerUsers: {
      numericFilters: useStore(state => state.usersNumericFilters, shallow),
      setNumericFilter: useStore(state => state.setUsersNumericFilter),
      setNumericOption: useStore(state => state.setUsersNumericOption)
    }
  }

  const numericFilters = store[recordType].numericFilters
  const setNumericFilter = store[recordType].setNumericFilter
  const setNumericOption = store[recordType].setNumericOption

  const storedFilter = useMemo(
    () => numericFilters.find(filter => filter.key === filterKey),
    [numericFilters, filterKey]
  )

  const selectedOption = storedFilter?.draftOption ?? 'all'

  function onOptionChange(value: string) {
    const option = value as NumericRangeOption
    setNumericOption({ ...defaultSetParams, option })
    if (option === 'null') {
      setNumericFilter({ ...defaultSetParams, value: null })
    } else if (option === 'all') {
      setNumericFilter({ ...defaultSetParams, value: null })
    } else if (option === 'is') {
      const range = storedFilter?.draftValue ?? [min, max]
      setNumericFilter({ ...defaultSetParams, value: range })
    } else {
      let range = storedFilter?.draftValue ?? [min, max]
      if (range[0] === range[1]) {
        const value = range[0] === max ? max - 1 : range[0]
        range = [value, value + 1]
      }
      setNumericFilter({ ...defaultSetParams, value: range })
    }
  }

  const isDisabled = useMemo(
    () =>
      JSON.stringify(storedFilter?.draftValue) === JSON.stringify(storedFilter?.value) &&
      storedFilter?.draftOption === storedFilter?.option,
    [storedFilter]
  )

  const { addFilter } = useAdvancedFilters()
  const isAdvanced = newFeed

  const [numberRange, setNumberRange] = useState<[number, number] | null>(null)
  const [advancedSelectedOption, setAdvancedSelectedOption] = useState<NumericRangeOption>('all')

  const isRangeOpen = (isAdvanced ? advancedSelectedOption : selectedOption) === 'between'
  const isExactValueOpen = (isAdvanced ? advancedSelectedOption : selectedOption) === 'is'

  const advancedOnOptionChange = (value: string) => {
    const option = value as NumericRangeOption
    setAdvancedSelectedOption(option)

    if (option === 'null' || option === 'all') {
      setNumberRange(null)
      return
    }

    if (option === 'is') {
      const range = numberRange ?? [min, max]
      setNumberRange(range)
      return
    }

    let range = numberRange ?? [min, max]
    if (range[0] === range[1]) {
      const value = range[0] === max ? max - 1 : range[0]
      range = [value, value + 1]
    }
    setNumberRange(range)
  }

  const rangeValue = isAdvanced ? numberRange ?? [min, max] : storedFilter?.draftValue ?? [min, max]
  const exactValue = isAdvanced ? [numberRange?.[0] ?? min] : [storedFilter?.draftValue?.[0] ?? min]

  const rangeText = `${rangeValue[0]} to ${rangeValue[1]}`
  const exactValueText = exactValue.toString()

  function onRangeChange(range: [number, number]) {
    if (isAdvanced) {
      setNumberRange(range)
      return
    }

    setNumericFilter({ ...defaultSetParams, value: range })
  }

  function onValueChange(range: [number]) {
    const [value] = range
    if (isAdvanced) {
      setNumberRange([value, value])
      return
    }

    setNumericFilter({ ...defaultSetParams, value: [value, value] })
  }

  const onApply = () => {
    if (isAdvanced) {
      if (advancedSelectedOption === 'all') return
      const prefix = recordType === 'accounts' ? 'account' : 'user'
      addFilter({
        type: 'number',
        name: `${prefix}.${name}`,
        value: numberRange,
        path: filterKey,
        status: 'valid'
      })
    }
    setNumericOption({ ...defaultSetParams, option: storedFilter?.draftOption ?? 'all' }, true)
    setNumericFilter({ ...defaultSetParams, value: storedFilter?.draftValue ?? null }, true)
  }

  return {
    rangeValue,
    exactValue,
    selectedOption: isAdvanced ? advancedSelectedOption : selectedOption,
    isRangeOpen,
    isExactValueOpen,
    rangeText,
    exactValueText,
    min,
    max,
    isDisabled,
    isLoading,
    onOptionChange: isAdvanced ? advancedOnOptionChange : onOptionChange,
    onRangeChange,
    onValueChange,
    onApply
  }
}

export default useCustomerRecordsNumericFilter
