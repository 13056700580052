import { OptionsMenuItem } from '@/components/atoms/options-menu'
import useMetricListPayload from '@/hooks/metrics/useMetricListPayload'
import useSourcesQuery from '@/hooks/useSourcesQuery'
import { AllMetricsKey, MetricKey, OverallMetricKeys } from '@/types/metrics'
import { BreakdownOption } from '@/types/time-series/TimeSeries'
import { snakeToTitle } from '@/utils/letterCase'
import { allMetricItems, mapSourceToMetricName } from '@/utils/metrics'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

const specificBreakdowns: BreakdownOption[] = [
  'opportunity',
  'satisfaction',
  'sentiment',
  'intention',
  'kind',
  'customField',
  'feedbackDetail',
  'account',
  'user'
]

interface Props {
  breakdown?: BreakdownOption
  enableShareOverFilters?: boolean
  trackMetric: (value: AllMetricsKey) => void
}

const useTimeSeriesMetricOptions = ({ breakdown, enableShareOverFilters, trackMetric }: Props) => {
  const { data: sourcesData, isLoading } = useSourcesQuery()

  const [selectedMetric, setSelectedMetric] = useState<AllMetricsKey>('count')

  const { t } = useTranslation()

  const { getMetricKeys } = useMetricListPayload()

  const metricOptionsDropdown = useMemo(() => {
    const countMetric = allMetricItems.count
    const Icon = countMetric.icon

    const getMetricKeysOptions = {
      useTimeseriesMetrics: true,
      enableShareOverFilters,
      breakdown,
      specificBreakdowns
    }
    const overallSubMetrics = getMetricKeys('count', getMetricKeysOptions)

    const options: OptionsMenuItem[] = [
      {
        text: t('overall'),
        icon: <Icon />,
        bold: overallSubMetrics?.includes(selectedMetric as OverallMetricKeys),
        options: overallSubMetrics?.map(metricKey => ({
          text: metricKey === 'feedback_share' ? '% over org' : allMetricItems[metricKey].label,
          onClick: () => {
            setSelectedMetric(metricKey)
            trackMetric(metricKey)
          },
          bold: selectedMetric === metricKey
        }))
      }
    ]

    sourcesData?.values?.forEach(source => {
      const metricOption = source as MetricKey
      const timeseriesMetric = allMetricItems[metricOption]

      if (!timeseriesMetric) return
      const Icon = timeseriesMetric.icon

      const metricKeys = getMetricKeys(metricOption, getMetricKeysOptions)
      if (!metricKeys) return

      options.push({
        text: mapSourceToMetricName[metricOption] ?? snakeToTitle(metricOption),
        icon: <Icon />,
        bold: metricKeys?.includes(selectedMetric as AllMetricsKey),
        options: metricKeys?.map(metricKey => ({
          text: allMetricItems[metricKey].label,
          onClick: () => {
            setSelectedMetric(metricKey)
            trackMetric(metricKey)
          },
          bold: selectedMetric === metricKey
        }))
      })
    })

    return options
  }, [
    sourcesData,
    selectedMetric,
    trackMetric,
    t,
    getMetricKeys,
    breakdown,
    enableShareOverFilters
  ])

  const onMetricChange = (value: string) => {
    setSelectedMetric(value as AllMetricsKey)
  }

  return {
    selectedMetric,
    metricOptionsDropdown,
    onMetricChange,
    setSelectedMetric,
    isDisabled: isLoading
  }
}

export default useTimeSeriesMetricOptions
