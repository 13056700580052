import FlexContainer from '@/components/atoms/flex-container'
import useSegment from '@/hooks/useSegment'
import NewOpportunity from '../../../exploration/Opportunities/NewOpportunity'
import { snakeToTitle } from '@/utils/letterCase'
import {
  NormalizedAreaTableData,
  isNormalizedArea,
  isNormalizedOpportunity
} from './AreaOfInterestTable.types'
import { AreaOfInterestData } from '@/types/area/AreaOfInterest'
import { styled, CSS } from '@/theme'
import { CaretDown } from '@phosphor-icons/react'
import useOpportunitiesWithMetricsQuery from '@/hooks/opportunity/useOpportunitiesWithMetricsQuery'
import { useMemo } from 'react'
import shortUUID from 'short-uuid'
import Checkbox, { CheckboxState } from '@/components/atoms/checkbox'
import { LinkFromTo } from '@/components/atoms/links'
import { OpportunityItem } from '@/types/opportunity/Opportunity'

const NameContainer = styled(FlexContainer, {
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  padding: '$xxs',
  pt: 'calc($xxs + 3px)',
  width: '100%',
  position: 'relative',
  color: '$neutralLowPure'
})

const buttonCss: CSS = {
  all: 'unset',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'flex-start',
  gap: '$nano'
}

const Button = styled('button', buttonCss)

const StyledLink = styled(LinkFromTo, {
  ...buttonCss,
  alignSelf: 'center'
})

const StyledNameCell = styled('span', {
  fontWeight: '$regular',
  fontSize: '$micro',
  textAlign: 'left',
  lineHeight: '13.8px',
  my: 'auto'
})

const AccordionCaret = styled(CaretDown, {
  transition: 'transform 300ms cubic-bezier(0.87, 0, 0.13, 1)',
  '[data-state=open] &': { transform: 'rotate(180deg)' },

  variants: {
    open: {
      true: {
        transform: 'rotate(180deg)'
      }
    }
  }
})

interface Props {
  data: NormalizedAreaTableData
  toggleExpand: () => void
  isExpanded: boolean
  isUnmappedArea?: boolean
  onAreaClick: (area: AreaOfInterestData) => void
  onOpportunityClick: (opportunity: OpportunityItem, area: AreaOfInterestData) => void
  showCheckbox?: boolean
  checked: boolean
  checkboxDisabled: boolean
  onCheckedChange: (checked: CheckboxState) => void
}

const NameCell = ({
  data,
  isExpanded,
  onAreaClick,
  onOpportunityClick,
  toggleExpand,
  isUnmappedArea,
  showCheckbox = true,
  checked,
  checkboxDisabled,
  onCheckedChange
}: Props) => {
  const { track } = useSegment()

  const area = isNormalizedArea(data)
    ? data.originalArea
    : isNormalizedOpportunity(data)
      ? data.originalArea
      : undefined

  const opportunity = isNormalizedOpportunity(data) ? data.originalOpportunity : undefined
  const isOpportunity = !!opportunity

  const opportunitiesCount = area?.opportunityCount ?? 0

  const { newOpportunitiesCountByAreaId } = useOpportunitiesWithMetricsQuery({
    areas: area && !opportunity ? [area] : [],
    enabled: false
  })

  const newsOpportunitiesCount = area && !opportunity ? newOpportunitiesCountByAreaId[area.id] : 0

  const showNewBadge = opportunity ? opportunity.new : newsOpportunitiesCount > 0

  const onTriggerClick = () => {
    if (!isExpanded) {
      track('areas_and_opps_expand_area')
    }
    toggleExpand()
  }

  const onClickName = () => {
    if (isOpportunity && area) return onOpportunityClick(opportunity, area)

    if (area) onAreaClick(area)
  }

  const translator = useMemo(() => shortUUID(), [])
  const shortAreaId = useMemo(
    () => (area?.id ? translator.fromUUID(area.id) : ''),
    [area?.id, translator]
  )
  const shortOpportunityId = useMemo(
    () => (opportunity?.id ? translator.fromUUID(opportunity.id) : ''),
    [opportunity?.id, translator]
  )

  const to = isOpportunity ? `/opportunity/${shortOpportunityId}` : `/area/${shortAreaId}`

  return (
    <NameContainer>
      {opportunitiesCount > 0 && !isOpportunity ? (
        <Button
          css={{ br: '$nano', mr: '$nano', '&:hover': { background: 'transparent' } }}
          onClick={onTriggerClick}
        >
          <AccordionCaret open={isExpanded} size={16} />
        </Button>
      ) : (
        <FlexContainer css={{ width: 21 }} />
      )}

      {showCheckbox && (
        <Checkbox
          checked={checked}
          disabled={checkboxDisabled}
          onCheckedChange={onCheckedChange}
          value={'checkbox-' + data.id}
        />
      )}

      <StyledLink onClick={onClickName} to={to}>
        <StyledNameCell>{isUnmappedArea ? snakeToTitle(data.name) : data.name}</StyledNameCell>
      </StyledLink>
      {showNewBadge && (
        <NewOpportunity.Badge
          count={newsOpportunitiesCount}
          css={{ whiteSpace: 'pre', maxHeight: 16, position: 'absolute', top: 1, left: 16 }}
        />
      )}
    </NameContainer>
  )
}

export default NameCell
