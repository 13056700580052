import useAreaOfInterestStore, { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import SaveFiltersButton from '@/components/molecules/saved-filters/SaveFiltersButton'
import UpdateAreaButton from '@/components/molecules/saved-filters/UpdateAreaButton'
import HiddenMetricsModal from '@/components/molecules/home/HiddenMetricsModal'
import useUser from '@/hooks/useUser'
import AreaNotificationModal from '@/components/molecules/notifications/opportunity-plan/AreaNotificationModal'
import { useCallback, useState } from 'react'
import OpportunityOptions from '../../exploration/Opportunities/OpportunityTable/OpportunityOptions'
import Button from '@/components/atoms/button'
import { DotsThreeVertical } from '@phosphor-icons/react'
import { BaseInterestArea } from '@/types/area/AreaOfInterest'
import useToastMessageStore from '@/store/useToastMessageStore'
import useRemovingItemsStore from '@/store/useRemoveItemsStore'
import useOpportunityMutations from '@/hooks/opportunity/useOpportunityMutations'
import { useTranslation } from 'react-i18next'
import useSegment from '@/hooks/useSegment'
import { MoveOppToSuccessMessage } from '../../exploration/Opportunities/OpportunitiesList/OpportunitiesList.styles'
import useAreaOfInterest from '@/hooks/areaOfInterest/useAreaOfInterest'
import RenameOpportunityDialog from '../../exploration/Opportunities/OpportunitiesList/RenameOpportunityDialog'
import useOpportunityStore from '@/store/useOpportunityStore'
import Select from '@/components/atoms/select'
import { OPPORTUNITY_STATUS_OPTIONS } from '@/utils/opportunityUtils'
import { OpportunityStatus } from '@/types/opportunity/Opportunity'

interface Props {
  hideMetricsModal?: boolean
}

function ExplorationOptions({ hideMetricsModal }: Props) {
  const { track } = useSegment()
  const { t } = useTranslation()

  const [open, setOpen] = useState(false)
  const currentInterestArea = useCurrentInterestAreaStore(state => state.currentInterestArea)
  const currentOpportunity = useOpportunityStore(state => state.currentOpportunity)
  const editMode = useAreaOfInterestStore(state => state.editMode)

  const onStatusChange = (newStatus: OpportunityStatus) => {
    if (!currentOpportunity) return
    if (currentOpportunity.status === newStatus || !newStatus.length) return
    updateOpportunity({ ...currentOpportunity, status: newStatus })
  }

  const isDraft = currentOpportunity?.status === OpportunityStatus.Draft

  const { userPermissions } = useUser()
  const isManager = userPermissions.areas.includes('manager')

  const addLoadingToast = useToastMessageStore(state => state.addLoadingToast)
  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)
  const removeToast = useToastMessageStore(state => state.removeToast)
  const { pushRemovingItem } = useRemovingItemsStore()

  const { updateOpportunity, isUpdatingOpportunity, moveOpportunity } = useOpportunityMutations()
  const { setArea } = useAreaOfInterest()

  const onConfirmMoveTo = useCallback(
    (newArea: BaseInterestArea) => {
      const opportunity = currentOpportunity
      if (!opportunity) return

      const removeLoadingToast = addLoadingToast({ text: t('movingOpportunity') })
      pushRemovingItem(opportunity.id)

      moveOpportunity(
        {
          opportunity,
          currentAreaId: opportunity.relations[0] ?? undefined,
          newAreaId: newArea.id
        },
        {
          onSettled: () => removeLoadingToast(),
          onSuccess: () => {
            const toastId = `opp-move-toast-${opportunity.id}`
            track('areas_and_opps_move_opportunity_to', {
              to: newArea.name,
              from: opportunity.relations[0] ?? '',
              name: opportunity.name
            })
            addSuccessToast({
              id: toastId,
              children: (
                <MoveOppToSuccessMessage
                  areaName={newArea.name}
                  onClick={() => {
                    setArea(newArea)
                    removeToast(toastId)
                  }}
                />
              ),
              duration: 4000
            })
          }
        }
      )
    },
    [
      currentOpportunity,
      addLoadingToast,
      pushRemovingItem,
      t,
      addSuccessToast,
      removeToast,
      setArea,
      moveOpportunity,
      track
    ]
  )

  const [isRenaming, setIsRenaming] = useState(false)

  const onConfirmRenameOpportunity = useCallback(
    (newName: string) => {
      if (!currentOpportunity) return

      const trimmedName = newName.trim()
      if (currentOpportunity?.name === trimmedName || !trimmedName.length) return

      updateOpportunity({
        id: currentOpportunity.id,
        name: trimmedName,
        status: currentOpportunity.status
      })
    },
    [currentOpportunity, updateOpportunity]
  )

  if (!isManager && currentInterestArea)
    return <AreaNotificationModal open={open} setOpen={setOpen} />

  if (editMode)
    return (
      <>
        {currentInterestArea && <AreaNotificationModal open={open} setOpen={setOpen} />}
        {!hideMetricsModal && <HiddenMetricsModal />}
        <UpdateAreaButton buttonSize="default" buttonVariant="primary" css={{ mb: 'auto' }} />
      </>
    )

  if (currentOpportunity)
    return (
      <>
        <Select
          defaultValue={currentOpportunity?.status}
          disabled={isUpdatingOpportunity || isDraft || !isManager}
          onValueChange={onStatusChange}
          options={OPPORTUNITY_STATUS_OPTIONS}
          value={currentOpportunity?.status}
          width={180}
        />
        <OpportunityOptions
          customButton={
            <Button
              css={{ size: 40, boxSizing: 'border-box', padding: 0 }}
              variant="white-bordered"
              aria-label="options"
              onClick={e => e.stopPropagation()}
            >
              <DotsThreeVertical weight="bold" />
            </Button>
          }
          data={currentOpportunity}
          onConfirmMoveTo={onConfirmMoveTo}
          onRename={() => setIsRenaming(true)}
        />
        {isRenaming && currentOpportunity && (
          <RenameOpportunityDialog
            onClose={() => setIsRenaming(false)}
            onSave={onConfirmRenameOpportunity}
            opportunityName={currentOpportunity.name}
          />
        )}
      </>
    )

  if (currentInterestArea)
    return (
      <>
        <AreaNotificationModal open={open} setOpen={setOpen} />
        {!hideMetricsModal && <HiddenMetricsModal />}
      </>
    )

  return (
    <>
      <SaveFiltersButton
        buttonSize="default"
        buttonVariant="primary"
        css={{ mb: 'auto' }}
        newFeed
      />
    </>
  )
}

export default ExplorationOptions
