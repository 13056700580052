import Button, { ButtonProps } from '@/components/atoms/button'
import { useEffect, useMemo, useState } from 'react'
import useSavedFilters from '@/hooks/useSavedFilters'
import { shallow } from 'zustand/shallow'
import useSavedFiltersStore from '@/store/useSavedFiltersStore'
import { CSS } from '@/theme'
import SaveFiltersDialog from './SaveFiltersDialog'
import useSegment from '@/hooks/useSegment'
import SaveFiltersDialogNew from './SaveFiltersDialogNew'
import { SavedFilterType } from '@/types/filters/Filters'
import { useFeedFiltersStore } from '@/store/useFiltersStore'
import useAdvancedFilters from '@/hooks/advancedFilters/useAdvancedFilters'

interface SaveFiltersButtonProps {
  css?: CSS
  buttonVariant?: ButtonProps['variant']
  buttonSize?: ButtonProps['size']
  newFeed?: boolean
}

function SaveFiltersButton({
  css,
  newFeed = false,
  buttonVariant = 'flat',
  buttonSize = 'small'
}: SaveFiltersButtonProps) {
  const { track } = useSegment()

  const { hasChanges: hasAdvancedFiltersChange } = useAdvancedFilters()
  const { saveView, saveAreaOfInterest, hasChanges, removeHashFromUrl } = useSavedFilters({
    newFeed
  })

  const setFilterName = useSavedFiltersStore(state => state.setFilterName)

  const filterName = useSavedFiltersStore(state => state.filterName, shallow)

  const currentSavedFilter = useSavedFiltersStore(state => state.currentSavedFilter, shallow)

  const search = useFeedFiltersStore(state => state.search)

  useEffect(() => {
    if (currentSavedFilter && hasChanges) {
      removeHashFromUrl()
    }
  }, [hasChanges, currentSavedFilter, removeHashFromUrl])

  const label = useMemo(() => {
    if (!currentSavedFilter || !filterName) return newFeed ? 'Create new area' : 'Save filters'

    if (hasChanges) return 'Update filters'

    return 'Saved'
  }, [currentSavedFilter, hasChanges, filterName, newFeed])

  const [dialogOpen, setDialogOpen] = useState(false)

  const onClickSave = () => {
    if ((!currentSavedFilter && !filterName) || newFeed) {
      setDialogOpen(true)
      setFilterName(search)
      return
    }

    !newFeed && saveView(false)
  }

  const onSaveFilters = (enableNotifications: boolean) => {
    if (enableNotifications) {
      track('explore_user_enable_summary_notification')
    }
    saveView(enableNotifications)
    setDialogOpen(false)
  }

  const onSaveFiltersNew = (type: SavedFilterType) => {
    setDialogOpen(false)

    if (type === 'basic') {
      saveView(false)
      return
    }

    if (type === 'area_interest') {
      saveAreaOfInterest()
      // return
    }

    // type === 'opportunity'
    // TODO: save opportunity
  }

  const onOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      setFilterName('')
    }
    setDialogOpen(isOpen)
  }

  const isDisabled = !hasAdvancedFiltersChange

  return (
    <>
      {
        <Button
          css={css}
          disabled={isDisabled}
          onClick={onClickSave}
          size={buttonSize}
          variant={buttonVariant}
        >
          {label}
        </Button>
      }
      {newFeed ? (
        <SaveFiltersDialogNew
          onOpenChange={onOpenChange}
          onSaveFilters={onSaveFiltersNew}
          open={dialogOpen}
        />
      ) : (
        <SaveFiltersDialog
          onOpenChange={onOpenChange}
          onSaveFilters={onSaveFilters}
          open={dialogOpen}
        />
      )}
    </>
  )
}

export default SaveFiltersButton
