import { useMemo } from 'react'

import useAdvancedFilters from '@/hooks/advancedFilters/useAdvancedFilters'
import { AreaOfInterestData, BaseInterestArea } from '@/types/area/AreaOfInterest'
import { useQuery } from '@tanstack/react-query'
import {
  advancedFilterToContent,
  getAdvancedFilterFromContent,
  mergeAdvancedFilters
} from '@/utils/advancedFilter'
import { AdvancedFilterContent } from '@/types/filters/AdvancedFilters'
import FiltersService from '@/services/FiltersService'

interface Params {
  areas: (BaseInterestArea | AreaOfInterestData)[]
  enabled?: boolean
}

const useAdvancedAreasFiltersCombined = ({ areas, enabled = true }: Params) => {
  const { filters: advancedFilters } = useAdvancedFilters()

  const queryKey = useMemo(
    () => ['get-areas-contexts', { areas, advancedFilters }],
    [areas, advancedFilters]
  )

  const queryFn = async () => {
    const baseContent: AdvancedFilterContent = advancedFilterToContent(advancedFilters)

    const combinedFilters = areas.map(area => {
      const areaContent = getAdvancedFilterFromContent(area.content)

      const combinedFilters = mergeAdvancedFilters([areaContent, baseContent], '$and')
      return { identifier: area.id, filter: combinedFilters }
    })

    const [error, responseData] = await FiltersService.multipleAdvancedFilterContext({
      filters: combinedFilters
    })

    if (error) throw error

    return responseData
  }

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn,
    enabled: enabled && areas.length > 0
  })

  return { isLoading: enabled ? isLoading : false, data, queryKey }
}

export default useAdvancedAreasFiltersCombined
