import { ReactNode } from 'react'
import { ContentContainer, ExploreContainer, PageContainer } from './styles'

import ToastMessagesProvider from '../ToastMessagesProvider'
import { CSS } from '@/theme'
import AdvancedFiltersHeader from '../filters/advanced/AdvancedFiltersHeader'
import AdvancedFiltersArea from '../filters/advanced/AdvancedFiltersArea'

interface Props {
  children: ReactNode
  searchPlaceholder?: string
  css?: CSS
}

const FeedSearchContent = ({ children, searchPlaceholder, css }: Props) => {
  return (
    <PageContainer css={css} opportunityPlan>
      <AdvancedFiltersHeader searchPlaceholder={searchPlaceholder} />
      <ExploreContainer>
        <AdvancedFiltersArea />
        <ContentContainer>{children}</ContentContainer>
      </ExploreContainer>
      <ToastMessagesProvider />
    </PageContainer>
  )
}

export default FeedSearchContent
