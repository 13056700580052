import { useAuth0 } from '@auth0/auth0-react'

import { useUserStore } from '@/store'
import { updateClient } from '@/services/Request'
import UserService from '@/services/UserService'

import type { AccessModule, Auth0User, UserAccess, UserPermissions, UserPlan } from '@/types/auth'
import { shallow } from 'zustand/shallow'
import { APP_ENVIRONEMNT } from '@/config'
import { useMemo } from 'react'
import jwtDecode from 'jwt-decode'
import useDashboardStore from '@/store/useDashboardStore'
import { useUserPlanStore } from '@/store/useUserStore'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import useLocalStorage from './useLocalStorage'
import {
  TEXT_REPLACEMENT_PARAMS_KEY,
  TextReplacementFormData
} from '@/types/params/TextReplacementParams'
import { defaultTextReplacementFormData } from '@/pages/text-replacement'
import useOpportunityStore from '@/store/useOpportunityStore'

function useUser() {
  const { user, getAccessTokenSilently, logout: auth0Logout } = useAuth0<Auth0User>()

  const setCurrentUser = useUserStore(state => state.setCurrentUser)
  const setCurrentUserPlan = useUserPlanStore(state => state.setCurrentUserPlan)
  const setCurrentInterestArea = useCurrentInterestAreaStore(state => state.setCurrentInterestArea)
  const setLastOrganizationId = useCurrentInterestAreaStore(state => state.setLastOrganizationId)
  const setCurrentOpportunity = useOpportunityStore(state => state.setCurrentOpportunity)

  const { currentUser, organization } = useUserStore(
    state => ({ currentUser: state.currentUser, organization: state.organization }),
    shallow
  )
  const setOrganization = useUserStore(state => state.setOrganization)

  const dashboardOrganizationId = useDashboardStore(state => state.organizationId)
  const setOrganizationId = useDashboardStore(state => state.setOrganizationId)
  const setCharts = useDashboardStore(state => state.setCharts)

  const userPermissions = useMemo(() => {
    const permissions: Record<AccessModule, string[]> = {
      analytics: [],
      discovery: [],
      explore: [],
      feedback: [],
      filters: [],
      source: [],
      topic: [],
      user: [],
      tableau: [],
      tracking: [],
      'new-topic': [],
      drilldown: [],
      summary: [],
      root_causes: [],
      areas: [],
      opportunities: [],
      notifications: [],
      raw_message: []
    }
    if (!currentUser) {
      return permissions as UserPermissions
    }

    currentUser.permissions.forEach(line => {
      const [module, permission] = line.split(':') as [AccessModule, string]
      if (permissions[module]) {
        permissions[module].push(permission)
      }
    })

    return permissions as UserPermissions
  }, [currentUser])

  /**
   * Checks by user email if the user is already registered in the system.
   */
  const checkFirstAccess = async () => {
    if (!user?.email) {
      return true
    }

    const token = await getAccessTokenSilently()
    try {
      const { first_access: firstAccess } = await UserService.verifyByEmail(user.email, token)
      return firstAccess
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    } catch (e: any) {
      return e.message === 'verify_email' || e.message === 'email_not_available'
    }
  }

  /**
   * Verify and get the user from the token
   */
  const getUserFromToken = () => {
    const tokenExpiration = localStorage.getItem('expires_in')
    const token = localStorage.getItem('access_token')
    if (!tokenExpiration || !token) {
      return
    }

    const now = new Date().getTime()
    const isExpired = now > parseInt(tokenExpiration + '000')
    if (isExpired) {
      return
    }

    const decodedToken = jwtDecode(token) as Omit<UserAccess, 'token'>
    if (!decodedToken) {
      return
    }

    const completeUser: UserAccess = {
      ...decodedToken,
      token
    }

    setCurrentUser(completeUser)
    updateClient()

    return completeUser
  }

  /**
   * Fetches and sets the user access token and updates request client.
   */
  const setToken = async (firstAccess: boolean) => {
    if (!user || firstAccess) {
      return
    }

    const auth0Token = await getAccessTokenSilently({
      detailedResponse: true
    })

    const tokenResponse = await UserService.token({
      ...auth0Token,
      token_type: 'Bearer'
    })

    localStorage.setItem('access_token', tokenResponse.token)
    localStorage.setItem('expires_in', tokenResponse.expires.toString())
    updateClient()
    setCurrentUser(tokenResponse)

    if (dashboardOrganizationId !== tokenResponse.organization_id) {
      setCharts([])
    }
    setOrganizationId(tokenResponse.organization_id)

    return tokenResponse
  }

  const logout = () => {
    if (APP_ENVIRONEMNT === 'production') {
      window.Intercom('shutdown')
    }

    setCurrentUser(null)
    setCurrentUserPlan(null)
    setCurrentInterestArea(undefined)
    setLastOrganizationId(undefined)
    setCurrentOpportunity(undefined)

    localStorage.removeItem('access_token')
    localStorage.removeItem('expires_in')
    localStorage.removeItem('favorite-tab-enabled')
    auth0Logout({ returnTo: window.location.origin })
  }

  const userPlan: UserPlan | 'all' = useMemo(() => {
    if (userPermissions.topic.length > 0 && userPermissions.areas.length > 0) return 'all'
    if (userPermissions.areas.length > 0) return 'opportunity'
    return 'topic'
  }, [userPermissions])

  const currentUserPlan = useUserPlanStore(state => state.currentUserPlan)

  const [textReplacementData] = useLocalStorage<TextReplacementFormData>(
    TEXT_REPLACEMENT_PARAMS_KEY,
    defaultTextReplacementFormData
  )

  const replacedCurrentUser: typeof currentUser = useMemo(
    () =>
      currentUser
        ? {
            ...currentUser,
            email: textReplacementData?.email ?? currentUser.email,
            name: textReplacementData?.userName ?? currentUser.name
          }
        : null,
    [textReplacementData, currentUser]
  )

  const replacedOrganization: typeof organization = useMemo(
    () =>
      organization
        ? {
            ...organization,
            name: textReplacementData?.companyName ?? organization?.name
          }
        : null,
    [textReplacementData, organization]
  )

  return {
    checkFirstAccess,
    setToken,
    getUserFromToken,
    currentUser: replacedCurrentUser,
    userPermissions,
    userPlan,
    currentUserPlan,
    setCurrentUserPlan,
    organization: replacedOrganization,
    setOrganization,
    logout
  }
}

export default useUser
