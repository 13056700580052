import { BaseInterestArea } from '@/types/area/AreaOfInterest'
import { OpportunityItem } from '@/types/opportunity/Opportunity'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

interface OpportunityState {
  currentOpportunity: OpportunityItem | undefined
  setCurrentOpportunity: (data: OpportunityItem | undefined) => void

  selectedAreas: BaseInterestArea[]
  setSelectedAreas: (selectedAreas: BaseInterestArea[]) => void
}

const useOpportunityStore = createWithEqualityFn<OpportunityState>(
  set => ({
    currentOpportunity: undefined,
    setCurrentOpportunity: data => set({ currentOpportunity: data }),

    selectedAreas: [],
    setSelectedAreas: selectedAreas => set({ selectedAreas })
  }),
  shallow
)

export default useOpportunityStore
