import useToastMessageStore from '@/store/useToastMessageStore'
import { MetricsRequests } from '@/types/metrics'
import { getAllMetricList, getMetricByColumn } from '@/utils/metrics'
import useLogging from '../useLogging'
import useDateFilterStore from '@/store/useFiltersStore/useDateFilterStore'
import { shallow } from 'zustand/shallow'
import useHiddenMetricsStore from '@/store/useHiddenMetricsStore'
import useSourcesQuery from '../useSourcesQuery'
import useCollectionStore from '@/store/useCollectionStore'
import { useMemo, useState } from 'react'
import useAreasAndOpportunitiesState from '@/store/useHomeStore'
import { useMutation, useQuery } from '@tanstack/react-query'
import { endDateParam, startDateParam, stringToDate } from '@/utils/date'
import { queryClient } from '@/plugins/reactQueryClient'
import FiltersService from '@/services/FiltersService'
import { FeedbackListQueryParams } from '@/types/feedbacks/FeedbackRequests'
import MetricsService from '@/services/MetricsService'
import { delay } from '@/utils/delay'
import useDidUpdateEffect from '../useDidUpdateEffect'
import useMetricListPayload from '../metrics/useMetricListPayload'
import { OpportunityRequests } from '@/types/opportunity'
import OpportunityService from '@/services/OpportunityService'
import { OpportunityItemWithMetrics, OpportunityStatus } from '@/types/opportunity/Opportunity'
import useBasicAreaOfInterestQuery from '../areaOfInterest/useBasicAreaOfInterestQuery'

const PAGE_SIZE = 10
const PROGRESS_STEP_SIZE = 100 / 3

export const OPPORTUNITIES_KEY_PREFIX = 'all-opportunities'

interface Params {
  sortColumn?: string
  sortDirection?: 'asc' | 'desc'
  enabled?: boolean
}

const defaultParams = {
  sortColumn: 'count:count',
  sortDirection: 'desc',
  enabled: true
} satisfies Params

const useAllOpportunitiesQuery = ({
  sortColumn = defaultParams.sortColumn,
  sortDirection = defaultParams.sortDirection,
  enabled = defaultParams.enabled
}: Params = defaultParams) => {
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)

  const { logException } = useLogging({ context: 'all-opportunities-query' })

  const searchText = useAreasAndOpportunitiesState(state => state.searchText)

  const progress = useAreasAndOpportunitiesState(state => state.opportunitiesProgress)
  const setProgress = useAreasAndOpportunitiesState(state => state.setOpportunitiesProgress)
  const loadStep = useAreasAndOpportunitiesState(state => state.opportunitiesLoadStep)
  const setLoadStep = useAreasAndOpportunitiesState(state => state.setOpportunitiesLoadStep)

  const { dateRange, datePeriod } = useDateFilterStore(
    state => ({
      dateRange: state.dateRange,
      datePeriod: state.datePeriod
    }),
    shallow
  )

  const hiddenMetrics = useHiddenMetricsStore(state => state.hiddenMetrics)

  const { data: sourcesData } = useSourcesQuery()

  const currentCollection = useCollectionStore(state => state.currentCollection)
  const currentCollectionId = currentCollection?.collectionId

  const { areas } = useBasicAreaOfInterestQuery()

  const { addShareFiltersToMetrics } = useMetricListPayload()

  const metricList = useMemo(() => {
    return addShareFiltersToMetrics(
      getAllMetricList({
        sourceValues: sourcesData?.values ?? [],
        hiddenMetrics
      })
    )
  }, [sourcesData, hiddenMetrics, addShareFiltersToMetrics])

  const [currentPage, setCurrentPage] = useState(0)

  const queryKey = [
    OPPORTUNITIES_KEY_PREFIX,
    { datePeriod, dateRange, searchText, currentCollectionId }
  ]

  const { mutate: fetchMetrics, isLoading: isMetricsLoading } = useMutation({
    mutationKey: ['fetch-opportunities-page-metrics', { datePeriod, dateRange }],
    mutationFn: async (pageRange: [number, number]) => {
      if (loadStep !== 'done') {
        setLoadStep('calculating')
      }

      const loadedData = queryClient.getQueryData<OpportunityItemWithMetrics[]>(queryKey)
      const opportunitiesToFetch = loadedData?.slice(pageRange[0], pageRange[1]) ?? []

      const [mergedContextError, mergedContextResponse] = await FiltersService.mergedAreas({
        areas: opportunitiesToFetch.map(opp => ({
          identifier: opp.id,
          areas_ids: opp.relations
        }))
      })

      if (mergedContextError) {
        logException(mergedContextError, { message: 'Failed to fetch merged areas context' })
        throw mergedContextError
      }

      const contexts = mergedContextResponse.map(area => area.context)

      let startDate: string | undefined
      let endDate: string | undefined
      if (datePeriod !== 'allTime' && dateRange) {
        startDate = startDateParam(dateRange.start)
        endDate = endDateParam(dateRange.end)
      }

      const metricsPayload: MetricsRequests.MetricsPayload = {
        filter_list: opportunitiesToFetch.map(
          (opp, index): FeedbackListQueryParams => ({
            context: contexts[index] ?? undefined,
            opportunity_id: opp.id
          })
        ),
        metric_list: metricList,
        posted_at_gte: startDate,
        posted_at_lt: endDate
      }

      // simulate loading
      for (let i = 0; i < 6; i++) {
        setTimeout(() => {
          setProgress(2 * PROGRESS_STEP_SIZE + 5 * i)
        }, 1000 * i)
      }

      const [metricsError, metricsResponse] = await MetricsService.metrics(metricsPayload)
      if (metricsError) {
        logException(metricsError, { message: 'Failed to fetch final opportunities metrics' })
        throw metricsError
      }

      return metricsResponse
    },
    onSuccess: async (data, pageRange) => {
      const prevOpps = queryClient.getQueryData<OpportunityItemWithMetrics[]>(queryKey)
      if (!prevOpps) return

      const newOpps = prevOpps.map((item, index) => {
        return {
          ...item,
          metrics:
            index >= pageRange[0] && index < pageRange[1]
              ? data[index - pageRange[0]]
              : item.metrics
        }
      })

      queryClient.setQueryData<OpportunityItemWithMetrics[]>(queryKey, () => newOpps)
      setProgress(100)
      await delay(1000)
      setLoadStep('done')
    },
    onError: () => {
      addErrorToast({ text: 'Failed to fetch opportunities metrics.' })
    }
  })

  const sortingMetric = useMemo(() => getMetricByColumn(sortColumn)?.metric, [sortColumn])

  const { mutate: fetchSortingMetrics, isLoading: isSortingMetricsLoading } = useMutation({
    mutationKey: ['fetch-all-opportunities-sorting-metrics', { datePeriod, dateRange }],
    mutationFn: async (opps: OpportunityItemWithMetrics[]) => {
      setLoadStep('ordering')

      const [mergedContextError, mergedContextResponse] = await FiltersService.mergedAreas({
        areas: opps.map(opp => ({
          identifier: opp.id,
          areas_ids: opp.relations
        }))
      })

      if (mergedContextError) {
        logException(mergedContextError, { message: 'Failed to fetch merged areas context' })
        throw mergedContextError
      }

      const contexts = mergedContextResponse.map(area => area.context)

      const chunkSize = 10
      const chunks = []

      for (let i = 0; i < opps.length; i += chunkSize) {
        chunks.push({
          opps: opps.slice(i, i + chunkSize),
          contexts: contexts?.slice(i, i + chunkSize)
        })
      }

      let startDate: string | undefined
      let endDate: string | undefined
      if (datePeriod !== 'allTime' && dateRange) {
        startDate = startDateParam(dateRange.start)
        endDate = endDateParam(dateRange.end)
      }

      let completed = 0
      const promises = chunks.map(async chunk => {
        const metricsPayload: MetricsRequests.MetricsPayload = {
          filter_list: chunk.opps.map(
            (opp, index): FeedbackListQueryParams => ({
              context: chunk.contexts[index] ?? undefined,
              opportunity_id: opp.id
            })
          ),
          metric_list: [
            {
              name: sortingMetric?.name ?? 'feedback_count',
              label: sortingMetric?.label ?? 'feedback_count',
              args: sortingMetric?.filter,
              share_filter: sortingMetric?.share_filter,
              include_previous_value: false
            }
          ],
          posted_at_gte: startDate,
          posted_at_lt: endDate
        }

        // await delay(1000 * index)
        // setProgress(PROGRESS_STEP_SIZE + (PROGRESS_STEP_SIZE / (chunks.length * 2)) * index)
        return MetricsService.metrics(metricsPayload).then(result => {
          setProgress(PROGRESS_STEP_SIZE + (PROGRESS_STEP_SIZE / (chunks.length * 2)) * completed)
          completed++

          return result
        })
      })

      const responses = await Promise.all(promises)
      const someError = responses.find(response => response[0])
      if (someError) {
        logException(someError, { message: 'Failed to fetch sorting opportunities metrics' })
        throw someError
      }

      const data = responses.flatMap(response => response[1]) as MetricsRequests.MetricsResponse
      return data
    },
    onMutate: () => {
      setCurrentPage(0)
      setProgress(PROGRESS_STEP_SIZE)
      const prevOpps = queryClient.getQueryData<OpportunityItemWithMetrics[]>(queryKey)
      if (!prevOpps) return

      const newOpps = prevOpps.map(item => {
        return {
          ...item,
          metrics: []
        }
      })

      queryClient.setQueryData<OpportunityItemWithMetrics[]>(queryKey, () => newOpps)
    },
    onSuccess: async data => {
      const prevOpps = queryClient.getQueryData<OpportunityItemWithMetrics[]>(queryKey)
      if (!prevOpps) return

      const newOpps = prevOpps.map((item, index) => {
        return {
          ...item,
          metrics: data[index]
        }
      })

      newOpps.sort((a, b) => a.name.localeCompare(b.name))

      newOpps.sort((a, b) =>
        sortDirection === 'desc'
          ? (b.metrics[0]?.current_value ?? 0) - (a.metrics[0]?.current_value ?? 0)
          : (a.metrics[0]?.current_value ?? 0) - (b.metrics[0]?.current_value ?? 0)
      )

      queryClient.setQueryData<OpportunityItemWithMetrics[]>(queryKey, () => newOpps)
      setProgress(PROGRESS_STEP_SIZE * 2)
      fetchMetrics([0, PAGE_SIZE])
    },
    onError: () => {
      const message = 'Failed to fetch sorting metrics.'
      addErrorToast({ text: message })
    }
  })

  const queryFn = async () => {
    setLoadStep('loading')
    setProgress(2)

    const searchParams: OpportunityRequests.SearchOpportunityParams = {
      limit: 1000,
      name: searchText
    }

    if (currentCollectionId) {
      searchParams.collection_id = currentCollectionId
    }

    const [error, response] = await OpportunityService.searchOpportunities(searchParams)

    if (error) {
      logException(error, { message: 'Failed to fetch opportunity list' })
      throw error
    }

    const data = response.opportunities
      .map(
        (filter): OpportunityItemWithMetrics => ({
          name: filter.name,
          id: filter.opportunity_id,
          status: filter.opportunity_status_id,
          createdAt: stringToDate(filter.created_at),
          metrics: [],
          new: filter.new ?? false,
          opportunityCount: 0,
          filterId: filter.filter_id,
          description: filter.description ?? '',
          createdBy: filter.created_by,
          relations: filter.areas,
          area:
            filter.areas.length === 1 ? areas.find(area => area.id === filter.areas[0]) : undefined
        })
      )
      .filter(opp => opp.status !== OpportunityStatus.Dismissed)

    fetchSortingMetrics(data)
    setProgress(PROGRESS_STEP_SIZE)

    return data
  }

  const { data, isLoading: isOpportunitiesLoading } = useQuery({
    queryKey,
    queryFn,
    enabled: areas.length > 0 && enabled
  })

  const loadNextPage = () => {
    setCurrentPage(prevPage => prevPage + 1)
    fetchMetrics([(currentPage + 1) * PAGE_SIZE, (currentPage + 2) * PAGE_SIZE])
  }

  const opportunities = useMemo(() => {
    return data?.slice(0, (currentPage + 1) * PAGE_SIZE) ?? []
  }, [data, currentPage])

  const hasMore = data && data.length > (currentPage + 1) * PAGE_SIZE

  useDidUpdateEffect(() => {
    setCurrentPage(0)
    fetchMetrics([0, PAGE_SIZE])
  }, [hiddenMetrics])

  return {
    opportunities,
    isLoading: isOpportunitiesLoading,
    isMetricsLoading,
    isSortingMetricsLoading,
    loadNextPage,
    hasMore,
    loadStep,
    progress,
    queryKey,
    allOpportunities: data ?? [],
    fetchSortingMetrics
  }
}

export default useAllOpportunitiesQuery
