import Dialog from '@/components/atoms/dialog'
import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import { colors, styled } from '@/theme'
import { BaseInterestArea } from '@/types/area/AreaOfInterest'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Search from '../search'
import { AreaList, AreasSkeleton } from './ModalComponents'
import { CheckCircle } from '@phosphor-icons/react'
import Button from '@/components/atoms/button'

export const AreaItemContainer = styled(FlexContainer, {
  padding: '$micro',
  m: 0,
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: 8,
  color: '$neutralLowPure',
  fontSize: '$xxxs',
  transition: '.2s',
  cursor: 'pointer',
  height: 32,

  '&:hover': {
    background: '$brandPrimaryLight',
    color: '$brandPrimaryPure'
  }
})

interface Props {
  open: boolean
  onOpenChange: (open: boolean) => void
  areas: BaseInterestArea[]
  selectedAreaId: string
  isLoading?: boolean
  onMove: (areaId: string) => void
}

const MoveOpportunityModal = ({
  open,
  onOpenChange,
  areas,
  selectedAreaId,
  isLoading,
  onMove
}: Props) => {
  const { t } = useTranslation()

  const [search, setSearch] = useState('')
  const filteredAreas = useMemo(() => {
    if (!search) return areas
    return areas.filter(area => area.name.toLowerCase().includes(search.toLowerCase()))
  }, [areas, search])

  const [newAreaId, setNewAreaId] = useState<string>(selectedAreaId)
  const isButtonDisabled = newAreaId === selectedAreaId

  const onMoveClick = useCallback(() => {
    onMove(newAreaId)
  }, [newAreaId, onMove])

  return (
    <Dialog align="center" closeIcon modal onOpenChange={onOpenChange} open={open}>
      <FlexContainer direction="column" gap="xs">
        <Text as="h1" typeface="titleBoldXXS">
          {t('moveOpportunityTo')}
        </Text>

        <Search
          css={{ py: '$micro' }}
          onChange={e => setSearch(e.currentTarget.value)}
          onKeyDown={e => {
            e.stopPropagation()
          }}
          size="small"
          small
          value={search}
          placeholder="Search areas"
        />
        <AreaList>
          {isLoading && <AreasSkeleton />}
          {filteredAreas.map(area => (
            <AreaItemContainer key={area.id} onClick={() => setNewAreaId(area.id)}>
              <span>{area.name}</span>
              {newAreaId === area.id && (
                <CheckCircle color={colors.feedbackPositivePure} size={16} />
              )}
            </AreaItemContainer>
          ))}
        </AreaList>
        <Button disabled={isButtonDisabled} onClick={onMoveClick} size="small" fullWidth>
          {t('move')}
        </Button>
      </FlexContainer>
    </Dialog>
  )
}

export default MoveOpportunityModal
