import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import useToastMessageStore from '@/store/useToastMessageStore'
import { AreaOfInterestData, BaseInterestArea } from '@/types/area/AreaOfInterest'
import useLogging from '../useLogging'
import useRemovingItemsStore from '@/store/useRemoveItemsStore'
import { useMutation } from '@tanstack/react-query'
import { queryClient } from '@/plugins/reactQueryClient'
import useOpportunitiesQuery from './useOpportunitiesQuery'
import useOpportunitiesWithMetricsQuery, {
  OpportunityWithMetricsResponse
} from './useOpportunitiesWithMetricsQuery'
import useAllOpportunitiesQuery, { OPPORTUNITIES_KEY_PREFIX } from './useAllOpportunitiesQuery'
import useOpportunityStore from '@/store/useOpportunityStore'
import OpportunityService from '@/services/OpportunityService'
import { OpportunityRequests } from '@/types/opportunity'
import {
  OpportunityItem,
  OpportunityItemWithMetrics,
  OpportunityStatus
} from '@/types/opportunity/Opportunity'
import { AREAS_KEY_PREFIX } from '../areaOfInterest/useAllAreasQuery'

interface UpdatingOpportunityData {
  name: string
  id: string
  status: OpportunityStatus
  description?: string
  parentId?: string
  isMoving?: boolean
}

interface MoveOpportunityParams {
  opportunity: OpportunityItem | OpportunityItemWithMetrics
  currentAreaId?: string
  newAreaId: string
}

interface Params {
  area?: BaseInterestArea | AreaOfInterestData
  metricAreas?: AreaOfInterestData[]
}

const useOpportunityMutations = ({ area, metricAreas }: Params = {}) => {
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)
  // const { logException } = useLogging({ context: 'use-opportunities-query' })
  const { logException: logMutationException } = useLogging({ context: 'opportunity-operation' })

  const currentOpportunity = useOpportunityStore(state => state.currentOpportunity)
  const setCurrentOpportunity = useOpportunityStore(state => state.setCurrentOpportunity)

  const currentAreaOfInterest = useCurrentInterestAreaStore(state => state.currentInterestArea)
  const setCurrentAreaOfInterest = useCurrentInterestAreaStore(
    state => state.setCurrentInterestArea
  )

  const setRemovingItems = useRemovingItemsStore(state => state.setRemovingItems)

  const { queryKey: opportunitiesQueryKey } = useOpportunitiesQuery({ enabled: false, area })
  const { queryKey: opportunitiesWithMetricsQueryKey } = useOpportunitiesWithMetricsQuery({
    enabled: false,
    areas: metricAreas || [],
    fetchOppsMetricsOnMount: true,
    excludeUnmapped: true
  })
  const { queryKey: allOpportunitiesQueryKey } = useAllOpportunitiesQuery({ enabled: false })

  const { mutate: moveOpportunity, isLoading: isMovingOpportunity } = useMutation({
    mutationKey: ['move-opportunity'],
    mutationFn: async ({ opportunity, currentAreaId, newAreaId }: MoveOpportunityParams) => {
      const filteredRelations = opportunity.relations.filter(r => r !== currentAreaId)
      const newAreaIds = [newAreaId, ...filteredRelations]

      const [error] = await OpportunityService.linkOpportunityToAreas({
        opportunityId: opportunity.id,
        areaIds: newAreaIds
      })

      if (error) throw error
    },
    onMutate: params => {
      queryClient.cancelQueries({ queryKey: opportunitiesQueryKey, exact: false })
      queryClient.cancelQueries({ queryKey: opportunitiesWithMetricsQueryKey, exact: false })
      queryClient.cancelQueries({ queryKey: allOpportunitiesQueryKey, exact: false })

      const previousOpportunities =
        queryClient.getQueryData<OpportunityRequests.SearchOpportunityResponse>(
          opportunitiesQueryKey
        )

      queryClient.setQueryData<OpportunityRequests.SearchOpportunityResponse>(
        opportunitiesQueryKey,
        old => {
          if (!old) return
          return {
            ...old,
            data: old.opportunities.filter(
              filter => filter.opportunity_id !== params.opportunity.id
            )
          }
        }
      )

      const previousOpportunitiesWithMetrics =
        queryClient.getQueriesData<OpportunityWithMetricsResponse>([
          ...opportunitiesWithMetricsQueryKey,
          params.currentAreaId
        ])

      queryClient.setQueriesData<OpportunityWithMetricsResponse>(
        [...opportunitiesWithMetricsQueryKey, params.currentAreaId],
        old => {
          if (!old) return

          return {
            ...old,
            data: old.data.filter(opportunity => opportunity.id !== params.opportunity.id)
          }
        }
      )

      const previousAllOpportunities =
        queryClient.getQueriesData<OpportunityRequests.SearchOpportunityResponse>(
          allOpportunitiesQueryKey
        )

      return {
        previousOpportunities,
        previousOpportunitiesWithMetrics,
        previousAllOpportunities
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: opportunitiesQueryKey })
      queryClient.invalidateQueries({ queryKey: opportunitiesWithMetricsQueryKey, exact: false })
      queryClient.invalidateQueries({ queryKey: allOpportunitiesQueryKey, exact: false })
    },
    onError: (error, params, context) => {
      queryClient.setQueryData(opportunitiesQueryKey, context?.previousOpportunities)
      queryClient.setQueriesData(
        [...opportunitiesWithMetricsQueryKey, params.currentAreaId],
        context?.previousOpportunitiesWithMetrics
      )
      queryClient.setQueryData(allOpportunitiesQueryKey, context?.previousAllOpportunities)

      const message = 'Failed to move opportunity.'
      logMutationException(error, { message })
      addErrorToast({ text: message })
    }
  })

  const { mutate: updateOpportunity, isLoading: isUpdatingOpportunity } = useMutation({
    mutationKey: ['update-opportunity'],
    mutationFn: async (opportunity: UpdatingOpportunityData) => {
      const payload: OpportunityRequests.UpdateOpportunityPayload = {
        name: opportunity.name,
        opportunity_status_id: opportunity.status,
        description: opportunity.description
      }

      const [error] = await OpportunityService.updateOpportunity(opportunity.id, payload)

      if (error) throw error
      return opportunity
    },
    onMutate: () => {
      queryClient.cancelQueries({ queryKey: opportunitiesQueryKey })
      queryClient.cancelQueries({ queryKey: opportunitiesWithMetricsQueryKey, exact: false })
      queryClient.cancelQueries({ queryKey: allOpportunitiesQueryKey, exact: false })

      const previousOpportunities =
        queryClient.getQueryData<OpportunityRequests.SearchOpportunityResponse>(
          opportunitiesQueryKey
        )

      const previousOpportunitiesWithMetrics =
        queryClient.getQueriesData<OpportunityRequests.SearchOpportunityResponse>(
          opportunitiesWithMetricsQueryKey
        )

      const previousAllOpportunities =
        queryClient.getQueriesData<OpportunityRequests.SearchOpportunityResponse>(
          allOpportunitiesQueryKey
        )

      return { previousOpportunities, previousOpportunitiesWithMetrics, previousAllOpportunities }
    },
    onSuccess: opporturnity => {
      metricAreas?.forEach(metricArea => {
        const prevOpps = queryClient.getQueryData<{ data: OpportunityItemWithMetrics[] }>([
          ...opportunitiesWithMetricsQueryKey,
          metricArea.id
        ])

        if (!prevOpps) return

        const newOpps = {
          ...prevOpps
        }

        if (opporturnity.parentId && opporturnity.isMoving) {
          newOpps.data = prevOpps.data.filter(
            oldOpportunity => oldOpportunity.id !== opporturnity.id
          )
          queryClient.invalidateQueries({
            queryKey: [...opportunitiesWithMetricsQueryKey, opporturnity.parentId]
          })
        } else {
          newOpps.data = prevOpps.data.map(oldOpp => {
            if (oldOpp.id !== opporturnity.id) return oldOpp

            return {
              ...oldOpp,
              description: opporturnity.description ?? oldOpp.description,
              name: opporturnity.name,
              status: opporturnity.status
            }
          })
        }

        queryClient.setQueryData<{ data: OpportunityItemWithMetrics[] }>(
          [...opportunitiesWithMetricsQueryKey, metricArea.id],
          old => {
            if (!old) return

            return newOpps
          }
        )
      })

      queryClient.setQueryData<OpportunityRequests.SearchOpportunityResponse>(
        opportunitiesQueryKey,
        old => {
          if (!old) return

          if (opporturnity.isMoving) {
            return {
              ...old,
              data: old.opportunities.filter(
                oldOpportunity => oldOpportunity.opportunity_id !== opporturnity.id
              )
            }
          }

          return {
            ...old,
            opportunities: old.opportunities.map(oldOpportunity =>
              oldOpportunity.opportunity_id === opporturnity.id
                ? ({
                    ...oldOpportunity,
                    description: opporturnity.description ?? oldOpportunity.description,
                    name: opporturnity.name,
                    opportunity_status_id: opporturnity.status
                  } as OpportunityRequests.SearchOpportunityResponse['opportunities'][number])
                : oldOpportunity
            )
          }
        }
      )

      queryClient.setQueryData<OpportunityItemWithMetrics[]>(allOpportunitiesQueryKey, old => {
        if (!old) return

        if (opporturnity.parentId && opporturnity.isMoving)
          return old.filter(oldOpportunity => oldOpportunity.id !== opporturnity.id)

        return old.map(oldOpportunity =>
          oldOpportunity.id === opporturnity.id
            ? ({
                ...oldOpportunity,
                description: opporturnity.description ?? oldOpportunity.description,
                name: opporturnity.name,
                status: opporturnity.status
              } as OpportunityItemWithMetrics)
            : oldOpportunity
        )
      })

      if (currentOpportunity?.id === opporturnity.id) {
        setCurrentOpportunity({ ...currentOpportunity, ...opporturnity })
      }
    },
    onSettled: opportunity => {
      queryClient.invalidateQueries({ queryKey: ['opportunities'], exact: false })
      if (opportunity?.parentId && opportunity.isMoving) {
        queryClient.invalidateQueries({
          queryKey: [AREAS_KEY_PREFIX],
          exact: false
        })

        queryClient.invalidateQueries({
          queryKey: ['opportunities', { id: opportunity.parentId }],
          exact: false
        })

        queryClient.invalidateQueries({
          queryKey: [...opportunitiesWithMetricsQueryKey, { id: opportunity.parentId }],
          exact: false
        })

        queryClient.invalidateQueries({ queryKey: ['all-opportunities'], exact: false })
      }
    },
    onError: (error, data, context) => {
      const message = 'Failed to update opportunity.'
      logMutationException(error, { message })
      addErrorToast({ text: message })

      if (data.isMoving) {
        setRemovingItems([])
      } else {
        queryClient.setQueryData(opportunitiesQueryKey, context?.previousOpportunities)
        queryClient.setQueriesData(
          opportunitiesWithMetricsQueryKey,
          context?.previousOpportunitiesWithMetrics
        )
        queryClient.setQueryData(allOpportunitiesQueryKey, context?.previousAllOpportunities)
      }
    }
  })

  const { mutate: createOpportunity, isLoading: isCreatingOpportunity } = useMutation({
    mutationKey: ['create-opportunity'],
    mutationFn: async (params: { areaId: string; name: string; description: string }) => {
      const [error, response] = await OpportunityService.createOpportunity({
        name: params.name,
        description: params.description,
        area_id: [params.areaId],
        opportunity_status_id: OpportunityStatus.Processing
      })

      if (error) throw error

      return response
    },
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: opportunitiesQueryKey })

      const previousOpportunities = queryClient.getQueryData(opportunitiesQueryKey)

      return { previousOpportunities }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [opportunitiesQueryKey[0]], exact: false })
      queryClient.invalidateQueries({
        queryKey: [opportunitiesWithMetricsQueryKey[0]],
        exact: false
      })
      queryClient.invalidateQueries({ queryKey: [allOpportunitiesQueryKey[0]], exact: false })
    },
    onError: (error, __, context) => {
      const message = 'Failed to create opportunity.'
      logMutationException(error, { message })
      addErrorToast({ text: message })
      queryClient.setQueryData(opportunitiesQueryKey, context?.previousOpportunities)
    },
    onSuccess: () => {
      if (currentAreaOfInterest) {
        setCurrentAreaOfInterest({
          ...currentAreaOfInterest,
          opportunityCount: currentAreaOfInterest?.opportunityCount + 1
        })
      }

      addSuccessToast({
        text: 'Opportunity added. Birdie validates and quantifies your idea daily, expect results in hours.',
        duration: 4000
      })
    }
  })

  const { mutate: linkOpportunityToAreas, isLoading: isLinkOpportunityToAreasLoading } =
    useMutation({
      mutationKey: ['link-opportunity-to-areas'],
      mutationFn: async (params: { opportunityId: string; areaIds: string[] }) => {
        const [error] = await OpportunityService.linkOpportunityToAreas(params)
        if (error) throw error
        return params
      },
      onMutate: async ({ opportunityId, areaIds }) => {
        await queryClient.cancelQueries({ queryKey: ['related-areas'] })
        await queryClient.cancelQueries({ queryKey: ['opportunities'] })
        await queryClient.cancelQueries({ queryKey: ['opportunities-with-metrics'] })
        await queryClient.cancelQueries({ queryKey: [OPPORTUNITIES_KEY_PREFIX] })

        const queryKey = [...opportunitiesWithMetricsQueryKey, currentAreaOfInterest?.id]

        const previousOpportunities =
          queryClient.getQueryData<OpportunityWithMetricsResponse>(queryKey)

        queryClient.setQueryData<OpportunityWithMetricsResponse>(queryKey, old => {
          if (!old) return
          return {
            ...old,
            data: old.data.map(opp => ({
              ...opp,
              relations: opp.id === opportunityId ? areaIds : opp.relations
            }))
          }
        })

        const oldRelations = currentOpportunity?.relations

        if (currentOpportunity?.id === opportunityId) {
          setCurrentOpportunity({
            ...currentOpportunity,
            relations: areaIds
          })
        }

        return { previousOpportunities, queryKey, oldRelations }
      },
      onSuccess: () => {
        addSuccessToast({
          text: 'Associated areas updated successfully.'
        })
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: ['opportunities'] })
        queryClient.invalidateQueries({ queryKey: ['opportunities-with-metrics'] })
        queryClient.invalidateQueries({ queryKey: [OPPORTUNITIES_KEY_PREFIX] })
        queryClient.invalidateQueries({ queryKey: ['related-areas'] })
      },
      onError: (error, { opportunityId }, context) => {
        const message = 'Failed to link opportunity to areas.'
        logMutationException(error, { message })
        addErrorToast({ text: message })

        if (context) {
          if (currentOpportunity?.id === opportunityId && context.oldRelations) {
            setCurrentOpportunity({ ...currentOpportunity, relations: context.oldRelations })
          }

          queryClient.setQueryData<OpportunityWithMetricsResponse>(
            context.queryKey,
            context.previousOpportunities
          )
        }
      }
    })

  const { mutate: unlinkAreaFromOpportunity, isLoading: isUnlinkAreaFromOpportunityLoading } =
    useMutation({
      mutationKey: ['unlink-area-from-opportunity'],
      mutationFn: async (params: { opportunityId: string; areaId: string }) => {
        const [error] = await OpportunityService.unlinkAreaFromOpportunity(params)
        if (error) throw error
        return params
      },
      onMutate: async ({ opportunityId, areaId }) => {
        await queryClient.cancelQueries({ queryKey: ['opportunities'] })
        await queryClient.cancelQueries({ queryKey: ['opportunities-with-metrics'] })
        await queryClient.cancelQueries({ queryKey: [OPPORTUNITIES_KEY_PREFIX] })

        const queryKey = [...opportunitiesWithMetricsQueryKey, currentAreaOfInterest?.id]

        const previousOpportunities =
          queryClient.getQueryData<OpportunityWithMetricsResponse>(queryKey)

        queryClient.setQueryData<OpportunityWithMetricsResponse>(queryKey, old => {
          if (!old) return
          return {
            ...old,
            data: old.data.map(opp => ({
              ...opp,
              relations:
                opp.id === opportunityId ? opp.relations.filter(id => id !== areaId) : opp.relations
            }))
          }
        })

        return { previousOpportunities, queryKey }
      },
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: ['opportunities'] })
        queryClient.invalidateQueries({ queryKey: ['opportunities-with-metrics'] })
        queryClient.invalidateQueries({ queryKey: [OPPORTUNITIES_KEY_PREFIX] })
      },
      onError: (error, __, context) => {
        const message = 'Failed to unlink area from opportunity.'
        logMutationException(error, { message })
        addErrorToast({ text: message })

        if (context) {
          queryClient.setQueryData<OpportunityWithMetricsResponse>(
            context.queryKey,
            context.previousOpportunities
          )
        }
      }
    })

  return {
    updateOpportunity,
    isUpdatingOpportunity,
    createOpportunity,
    isCreatingOpportunity,
    linkOpportunityToAreas,
    unlinkAreaFromOpportunity,
    isLinkOpportunityToAreasLoading,
    isUnlinkAreaFromOpportunityLoading,
    moveOpportunity,
    isMovingOpportunity
  }
}

export default useOpportunityMutations
