import useSavedFilters from '@/hooks/useSavedFilters'
import useSegment from '@/hooks/useSegment'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import { useEffect, useState } from 'react'
import useUser from '@/hooks/useUser'
import useDidUpdateEffect from '@/hooks/useDidUpdateEffect'
import FlexContainer from '@/components/atoms/flex-container'
import AreaOfInterestDropdown from '../../area-of-interest/AreaOfInterestDropdown/AreaOfInterestDropdown'

const FREE_EXPLORATION_TITLE = 'Exploration'

const AreaSelect = () => {
  const { track } = useSegment()
  const { currentUser, currentUserPlan } = useUser()
  const [areaName, setAreaName] = useState('')

  const { selectedInterestArea, lastOrganizationId } = useCurrentInterestAreaStore(state => ({
    selectedInterestArea: state.currentInterestArea,
    lastOrganizationId: state.lastOrganizationId
  }))

  const setCurrentInterestArea = useCurrentInterestAreaStore(state => state.setCurrentInterestArea)
  const setLastOrganizationId = useCurrentInterestAreaStore(state => state.setLastOrganizationId)

  const { applyFilterContent } = useSavedFilters({ newFeed: true })

  // biome-ignore lint/correctness/useExhaustiveDependencies: should happens once
  useEffect(() => {
    // logged with a different organization, remove the last area (prevent title blinking)
    if (
      lastOrganizationId &&
      lastOrganizationId !== currentUser?.organization_id &&
      selectedInterestArea
    ) {
      setAreaName(FREE_EXPLORATION_TITLE)
      setCurrentInterestArea(undefined)
      setLastOrganizationId(undefined)
    } else {
      setAreaName(selectedInterestArea?.name || FREE_EXPLORATION_TITLE)
      if (selectedInterestArea?.content) {
        applyFilterContent(selectedInterestArea.content, true)
      }
    }
  }, [])

  useDidUpdateEffect(() => {
    setAreaName(selectedInterestArea?.name || FREE_EXPLORATION_TITLE)
  }, [selectedInterestArea])

  const onDropdownOpenChange = (open: boolean) => {
    if (!open) return
    if (selectedInterestArea) {
      track('area_acess_menu_icon')
    } else {
      track('exploration_access_menu_icon')
    }
  }

  if (currentUserPlan !== 'opportunity') return <></>

  return (
    <FlexContainer gap="micro">
      <AreaOfInterestDropdown
        areaName={areaName}
        isUnmappedArea={selectedInterestArea?.isUnmappedArea}
        onOpenChange={onDropdownOpenChange}
      />
    </FlexContainer>
  )
}

export default AreaSelect
