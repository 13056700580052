import FeedbackListExploration from '@/components/molecules/exploration/feedback/FeedbackListExploration'
import FeedSummary from '@/components/molecules/exploration/summary'
import OpportunitiesListV2 from '@/components/molecules/exploration/Opportunities/OpportunitiesList/OpportunitiesListV2'
import ExplorationIndicators from '@/components/molecules/exploration/indicators/ExplorationIndicators'
import AreaMetricsTrendline from '@/components/molecules/metrics-trendline/AreaMetricsTrendline'
import useAdvancedFilters from '@/hooks/advancedFilters/useAdvancedFilters'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import { useEffect } from 'react'
import useSegment from '@/hooks/useSegment'
import usePageLink from '@/hooks/usePageLink'

const AreaPage = () => {
  const currentAreaOfInterest = useCurrentInterestAreaStore(state => state.currentInterestArea)
  const { applyFilterFromArea, context } = useAdvancedFilters()

  const { track } = useSegment()

  usePageLink()

  // biome-ignore lint/correctness/useExhaustiveDependencies: should happens once
  useEffect(() => {
    if (currentAreaOfInterest && !context) {
      applyFilterFromArea(currentAreaOfInterest)
    }
  }, [])

  const trackSourceChange = (sourceKey: string | undefined) => {
    track(
      currentAreaOfInterest
        ? 'area_source_filter_on_feedback'
        : 'exploration_source_filter_on_feedback',
      { source_key: sourceKey }
    )
  }

  const trackExportFeedback = () => {
    track(currentAreaOfInterest ? 'area_export_feedback' : 'exploration_export_feedback')
  }

  return (
    <>
      <ExplorationIndicators />
      <FeedSummary />
      <OpportunitiesListV2 />
      <AreaMetricsTrendline />
      <FeedbackListExploration
        trackExportFeedback={trackExportFeedback}
        trackSourceChange={trackSourceChange}
      />
    </>
  )
}

export default AreaPage
