import { MetricCell } from '@/components/molecules/area-of-interest/AreaOfInterestList/AreaOfInterestTable/MetricCell'
import useSourcesQuery from '@/hooks/useSourcesQuery'
import useHiddenMetricsStore from '@/store/useHiddenMetricsStore'
import { FeedbackListQueryParams } from '@/types/feedbacks/FeedbackRequests'
import { MetricKey, RawMetric } from '@/types/metrics'
import { snakeToTitle } from '@/utils/letterCase'
import { allMetricItems, mapSourceToMetricName, parseRawMetric } from '@/utils/metrics'
import { ColumnDef, VisibilityState } from '@tanstack/react-table'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useMetricListPayload from './useMetricListPayload'

interface Params<T> {
  filterFn: (item: T) => FeedbackListQueryParams
}

const useMetricsTableColumns = <T extends { metrics?: RawMetric[] }>({ filterFn }: Params<T>) => {
  const { data: sourcesData } = useSourcesQuery()

  const hiddenMetrics = useHiddenMetricsStore(state => state.hiddenMetrics)

  const { t } = useTranslation()

  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(() => {
    const columns: VisibilityState = {}

    hiddenMetrics.forEach(metric => {
      columns[metric] = false
    })

    return columns
  })

  const { getMetricKeys } = useMetricListPayload()

  useEffect(() => {
    setColumnVisibility(prev => {
      const newColumnVisibility = { ...prev }
      if (!hiddenMetrics.length) {
        Object.keys(prev).forEach(key => {
          newColumnVisibility[key] = true
        })
      }

      hiddenMetrics.forEach(metric => {
        newColumnVisibility[metric] = false
      })

      Object.keys(prev).forEach(key => {
        if (!hiddenMetrics.includes(key)) {
          newColumnVisibility[key] = true
        }
      })

      return newColumnVisibility
    })
  }, [hiddenMetrics])

  const metricColumns = useMemo(() => {
    const overallKey = 'count' as MetricKey
    const overallKeys = getMetricKeys(overallKey)

    const columnMinSize = 100
    const columnMaxSize = 120

    const overallSubColumns: ColumnDef<T>[] = (overallKeys ?? []).map(key => {
      const overallMetricItem = allMetricItems[key]
      return {
        id: `${overallKey}:${key}`,
        header: overallMetricItem.abbrLabel ?? overallMetricItem.label,
        maxSize: columnMaxSize,
        footer: props => props.column.id,
        accessorFn: row =>
          parseRawMetric({
            groupKey: overallKey,
            columKey: key,
            rawMetrics: row.metrics
          })?.currentValue,
        cell: props => {
          const metricData = parseRawMetric({
            groupKey: overallKey,
            columKey: key,
            rawMetrics: props.row.original.metrics
          })

          const transformedValue =
            metricData.currentValue && overallMetricItem.numberTransformer(metricData.currentValue)

          const formattedValue = transformedValue && overallMetricItem.formatter(transformedValue)

          const filter = filterFn(props.row.original)

          return (
            <MetricCell
              columnName={overallMetricItem.abbrLabel ?? overallMetricItem.label}
              filter={filter}
              formatter={overallMetricItem.formatter}
              groupKey={overallKey}
              metricKey={key}
              numberTransformer={overallMetricItem.numberTransformer}
              rawMetric={metricData.metric}
              tooltipName="Overall"
              tooltipType="count"
              value={formattedValue}
            />
          )
        }
      }
    })

    const columns: ColumnDef<T>[] = [
      {
        id: overallKey,
        header: t('overall'),
        columns: overallSubColumns,
        minSize: columnMinSize,
        maxSize: columnMaxSize,
        footer: props => props.column.id
      }
    ]

    const sources = sourcesData?.values ?? []

    const CONVERSATION_TYPES = ['issue', 'social_media_post', 'transcript']
    const SATISFACTION_TYPES = ['csat', 'nps', 'review']

    const orderedSources = [
      ...sources.filter(source => source === 'support_ticket'),
      ...sources.filter(source => SATISFACTION_TYPES.includes(source)),
      ...sources.filter(source => CONVERSATION_TYPES.includes(source))
    ]

    orderedSources.forEach(source => {
      const metricOption = source as MetricKey

      const metricKeys = getMetricKeys(metricOption)

      if (!metricKeys) {
        console.warn(`Source/Metric ${metricOption} not implemented`)
        return
      }

      const subColumns: ColumnDef<T>[] = metricKeys.map(key => {
        const metricItem = allMetricItems[key]
        return {
          id: `${metricOption}:${key}`,
          header: metricItem.abbrLabel ?? metricItem.label,
          maxSize: columnMaxSize,
          footer: props => props.column.id,
          accessorFn: row =>
            parseRawMetric({
              groupKey: metricOption,
              columKey: key,
              rawMetrics: row.metrics
            })?.currentValue,
          cell: props => {
            const metricData = parseRawMetric({
              groupKey: metricOption,
              columKey: key,
              rawMetrics: props.row.original.metrics
            })

            let tooltipType: 'score' | 'count' = 'count'
            let scoreType: 'score' | 'nps' | 'csat' = 'score'
            if (metricOption === 'nps') {
              scoreType = 'nps'
              tooltipType = 'score'
            }
            if (metricOption === 'csat') {
              scoreType = 'csat'
              tooltipType = 'score'
            }

            if (metricOption === 'review') {
              tooltipType = 'score'
            }

            if (metricData.metric?.unit === 'currency') {
              tooltipType = 'count'
            }

            const transformedValue =
              metricData.currentValue && metricItem.numberTransformer(metricData.currentValue)

            const formattedValue =
              transformedValue &&
              metricItem.formatter(transformedValue, metricData.metric?.currency)

            const filter = filterFn(props.row.original)

            return (
              <MetricCell
                columnName={metricItem.abbrLabel ?? metricItem.label}
                filter={filter}
                formatter={metricItem.formatter}
                groupKey={metricOption}
                metricKey={key}
                numberTransformer={metricItem.numberTransformer}
                rawMetric={metricData.metric}
                scoreType={scoreType}
                tooltipName={mapSourceToMetricName[metricOption] ?? snakeToTitle(metricOption)}
                tooltipType={tooltipType}
                value={formattedValue}
              />
            )
          }
        }
      })

      columns.push({
        id: metricOption,
        header: mapSourceToMetricName[metricOption] ?? snakeToTitle(metricOption),
        columns: subColumns,
        minSize: columnMinSize,
        maxSize: columnMaxSize,
        footer: props => props.column.id
      })
    })

    return columns
  }, [sourcesData, filterFn, t, getMetricKeys])

  return {
    metricColumns,
    columnVisibility,
    setColumnVisibility
  }
}

export default useMetricsTableColumns
