import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import { OpportunityStatus } from '@/types/opportunity/Opportunity'
import { opportunityStatusLabel } from '@/utils/opportunityUtils'

interface Props {
  status: OpportunityStatus
}

export const OpportunityStatusCell = ({ status }: Props) => {
  return (
    <FlexContainer alignItems="center" justifyContent="center">
      <Text as="span" css={{ textTransform: 'capitalize' }} fontSize="xxxs">
        {opportunityStatusLabel[status]}
      </Text>
    </FlexContainer>
  )
}

export default OpportunityStatusCell
