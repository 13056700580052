import {
  Buildings,
  ClipboardText,
  ClockClockwise,
  FolderOpen,
  HandPalm,
  LightbulbFilament,
  Smiley,
  Stack,
  ThumbsUp,
  UserList,
  UsersThree
} from '@phosphor-icons/react'
import { SelectOption } from '@/components/atoms/select'
import { useCallback, useMemo, useState } from 'react'
import { OptionsMenuItem } from '@/components/atoms/options-menu'
import useFeedFilters from '@/hooks/filters/useFeedFilters'
import { FilterItem } from '@/types/filters/Filters'
import {
  BreakdownOption,
  satisfactionByKindList,
  satisfactionByKindMap,
  TimeSeriesIntervalOption
} from '@/types/time-series/TimeSeries'
import useAccountsFilters from '@/hooks/filters/useAccountsFilters'
import useCustomerUsersFilters from '@/hooks/filters/useCustomerUsersFilters'
import useUser from '@/hooks/useUser'
import useAccountsStore from '@/store/useAccountsStore'
import useCustomerUsersStore from '@/store/useCustomerUsersStore'
import { useQuery } from '@tanstack/react-query'
import { AllMetricsKey } from '@/types/metrics'
import { ChartType } from '@/types/dashboard'
import useSourcesQuery from '@/hooks/useSourcesQuery'
import useOpportunityStore from '@/store/useOpportunityStore'

/**
 * These breakdowns should be hidden if the rowBreakdown or columnBreakdown is one of the items
 */
const hiddenBreakdownOptions: BreakdownOption[] = [
  'account',
  'customField',
  'feedbackDetail',
  'user'
]

type BreakdownOptionMap = Partial<Record<BreakdownOption, OptionsMenuItem>>

interface Props {
  breakdownList: BreakdownOption[]
  selectedMetric: AllMetricsKey
  chartType: ChartType
  intervalOptions: SelectOption<TimeSeriesIntervalOption>[]
  interval: TimeSeriesIntervalOption
  breakdown: BreakdownOption
  setBreakdown: React.Dispatch<React.SetStateAction<BreakdownOption>>
  setSelectedMetric: React.Dispatch<React.SetStateAction<AllMetricsKey>>
  onIntervalChange: (value: string) => void
}

const useMetricsTrendlineBreakdowns = ({
  breakdownList,
  selectedMetric,
  chartType,
  interval,
  intervalOptions,
  setSelectedMetric,
  breakdown,
  setBreakdown,
  onIntervalChange
}: Props) => {
  const currentOpportunity = useOpportunityStore(state => state.currentOpportunity)

  const { customFieldsFilters, feedbackDetailsFilters } = useFeedFilters()
  const [columnBreakdown, setColumnBreakdown] = useState<BreakdownOption>('date')
  const [field, setField] = useState<FilterItem | null>(null)

  const { data: sourcesData } = useSourcesQuery({ enabled: true })

  const sources = useMemo(() => sourcesData?.values ?? [], [sourcesData])

  const generateOptions = useCallback(
    (
      breakdownOption: BreakdownOption,
      fieldList: FilterItem[],
      onSelectCb: (value: React.SetStateAction<BreakdownOption>) => void,
      allowedTypes = ['enum', 'id', 'string', 'number', 'boolean']
    ) => {
      return fieldList
        .filter(filter => allowedTypes.includes(filter.type))
        .map(filter => ({
          text: filter.displayName,
          onClick: () => {
            onSelectCb(breakdownOption)
            setField(filter)
          }
        }))
    },
    []
  )

  const { userPermissions, currentUser } = useUser()

  const checkHasAccounts = useAccountsStore(state => state.checkHasAccounts)
  const checkHasCustomerUsers = useCustomerUsersStore(state => state.checkHasCustomerUsers)

  const hasCustomerRecordsPermission = userPermissions.source.includes('customer_records')

  useQuery({
    queryKey: ['customer-records-check', { hasCustomerRecordsPermission }],
    queryFn: async () => {
      if (!hasCustomerRecordsPermission) return

      return Promise.all([
        checkHasAccounts(currentUser?.organization_id),
        checkHasCustomerUsers(currentUser?.organization_id)
      ])
    },
    retry: false,
    refetchOnMount: true,
    enabled: hasCustomerRecordsPermission
  })

  const getFeedbackDetailsOptions = useCallback(
    (onSelectCb: (value: React.SetStateAction<BreakdownOption>) => void) =>
      generateOptions('feedbackDetail', feedbackDetailsFilters, onSelectCb),
    [feedbackDetailsFilters, generateOptions]
  )

  const getCustomFieldsOptions = useCallback(
    (onSelectCb: (value: React.SetStateAction<BreakdownOption>) => void) =>
      generateOptions('customField', customFieldsFilters, onSelectCb),
    [customFieldsFilters, generateOptions]
  )

  const { fields: accountFields, mapAccountsTypes } = useAccountsFilters()
  const getAccountOptions = useCallback(
    (onSelectCb: (value: React.SetStateAction<BreakdownOption>) => void) => {
      const allowedAccountTypes = [
        mapAccountsTypes.list,
        mapAccountsTypes.id,
        mapAccountsTypes.number,
        mapAccountsTypes.boolean
      ]
      return generateOptions('account', accountFields, onSelectCb, allowedAccountTypes)
    },
    [accountFields, mapAccountsTypes, generateOptions]
  )

  const { fields: userFields, mapCustomerRecordsTypes } = useCustomerUsersFilters()
  const getUserOptions = useCallback(
    (onSelectCb: (value: React.SetStateAction<BreakdownOption>) => void) => {
      const allowedUsersTypes = [
        mapCustomerRecordsTypes.list,
        mapCustomerRecordsTypes.id,
        mapCustomerRecordsTypes.number,
        mapCustomerRecordsTypes.boolean
      ]
      return generateOptions('user', userFields, onSelectCb, allowedUsersTypes)
    },
    [userFields, mapCustomerRecordsTypes, generateOptions]
  )

  const getBreakdownOptionsMap = useCallback(
    (onSelectCb: (value: React.SetStateAction<BreakdownOption>) => void) => {
      const map: BreakdownOptionMap = {
        org: {
          text: 'Org',
          onClick: () => {
            onSelectCb('org')
            if (selectedMetric === 'feedback_share_group') {
              setSelectedMetric('feedback_share')
            }
          },
          icon: <Buildings />
        },
        all: {
          text: currentOpportunity ? 'This opportunity' : 'This area',
          onClick: () => {
            onSelectCb('all')
            if (selectedMetric === 'feedback_share_group') {
              setSelectedMetric('feedback_share')
            }
          },
          icon: currentOpportunity ? <LightbulbFilament /> : <FolderOpen />
        },
        areas: {
          text: 'Areas',
          onClick: () => {
            onSelectCb('areas')
            if (selectedMetric === 'feedback_share_group') {
              setSelectedMetric('feedback_share')
            }
          },
          icon: <FolderOpen />
        },
        opportunity: {
          text: 'Opportunity',
          onClick: () => onSelectCb('opportunity'),
          icon: <LightbulbFilament />
        },
        sentiment: { text: 'Sentiment', onClick: () => onSelectCb('sentiment'), icon: <Smiley /> },
        intention: {
          text: 'Intention',
          onClick: () => onSelectCb('intention'),
          icon: <HandPalm />
        },
        kind: { text: 'Source', onClick: () => onSelectCb('kind'), icon: <Stack /> }
      }

      const overallAllowedMetrics: AllMetricsKey[] = ['count', 'feedback_share']
      const allowedSourceMetrics: Record<string, AllMetricsKey[]> = {
        nps: ['nps_count', 'nps_share'],
        csat: ['csat_count', 'csat_share'],
        review: ['review_count', 'review_share']
      }

      // satisfaction by kind breakdown is only allowed for some metrics (count and share basically)
      const satisfactionSources = sources.filter(source => {
        if (!['nps', 'csat', 'review'].includes(source)) return false

        if (overallAllowedMetrics.includes(selectedMetric)) return true

        return allowedSourceMetrics[source].includes(selectedMetric)
      })

      if (satisfactionSources.length) {
        const mapSourceToText: Record<string, string> = {
          nps: 'NPS',
          csat: 'CSAT',
          review: 'Review'
        }

        if (satisfactionSources.length === 1) {
          map.satisfaction = {
            text: `${mapSourceToText[satisfactionSources[0]]}`,
            onClick: () => onSelectCb(satisfactionByKindMap[satisfactionSources[0]]),
            icon: <ThumbsUp />
          }
        } else {
          map.satisfaction = {
            text: 'Satisfaction',
            onClick: () => onSelectCb(satisfactionByKindMap[satisfactionSources[0]]),
            icon: <ThumbsUp />,
            options: satisfactionSources.map(source => ({
              text: `${mapSourceToText[source]}`,
              onClick: () => onSelectCb(satisfactionByKindMap[source])
            }))
          }
        }
      }

      const feedbackDetailsOptions = getFeedbackDetailsOptions(onSelectCb)
      const customFieldsOptions = getCustomFieldsOptions(onSelectCb)
      const accountOptions = getAccountOptions(onSelectCb)
      const userOptions = getUserOptions(onSelectCb)

      if (feedbackDetailsOptions.length > 0) {
        map.feedbackDetail = {
          text: 'Feedback details',
          icon: <ClipboardText />,
          options: feedbackDetailsOptions
        }
      }

      if (customFieldsOptions.length > 0) {
        map.customField = {
          text: 'Custom fields',
          icon: <UserList />,
          options: customFieldsOptions
        }
      }

      if (accountOptions.length > 0) {
        map.account = { text: 'Accounts', icon: <Buildings />, options: accountOptions }
      }

      if (userOptions.length > 0) {
        map.user = { text: 'Users', icon: <UsersThree />, options: userOptions }
      }

      if (chartType === 'table') {
        map.date = {
          text: 'Date',
          icon: <ClockClockwise />,
          options: intervalOptions.map(option => ({
            text: option.text as string,
            bold: option.value === interval,
            onClick: () => {
              onSelectCb('date')
              onIntervalChange(option.value)
            }
          }))
        }
      }

      return map
    },
    [
      getFeedbackDetailsOptions,
      getCustomFieldsOptions,
      getAccountOptions,
      getUserOptions,
      setSelectedMetric,
      onIntervalChange,
      currentOpportunity,
      selectedMetric,
      chartType,
      intervalOptions,
      interval,
      sources
    ]
  )

  const defaultBreakdownOptionMap = useMemo(
    () => getBreakdownOptionsMap(setBreakdown),
    [getBreakdownOptionsMap, setBreakdown]
  )

  const breakdownOptions = useMemo(() => {
    return Object.entries(defaultBreakdownOptionMap)
      .filter(([key]) => {
        const breakdownKey = key as BreakdownOption
        const columnBreakdownKey: BreakdownOption = satisfactionByKindList.includes(columnBreakdown)
          ? 'satisfaction'
          : columnBreakdown

        return (
          breakdownList.includes(breakdownKey) &&
          (hiddenBreakdownOptions.includes(columnBreakdown)
            ? !hiddenBreakdownOptions.includes(breakdownKey)
            : columnBreakdownKey !== breakdownKey)
        )
      })
      .map(([key, option]) => ({
        ...option,
        bold: satisfactionByKindList.includes(breakdown)
          ? key === 'satisfaction'
          : breakdown === key
      }))
  }, [breakdown, defaultBreakdownOptionMap, breakdownList, columnBreakdown])

  const tableColumnOptions = useMemo(() => {
    const options = getBreakdownOptionsMap(setColumnBreakdown)
    return Object.entries(options)
      .filter(([key]) => {
        const breakdownKey = key as BreakdownOption
        const rowBreakdownKey: BreakdownOption = satisfactionByKindList.includes(breakdown)
          ? 'satisfaction'
          : columnBreakdown

        return (
          breakdownList.includes(breakdownKey) &&
          (hiddenBreakdownOptions.includes(breakdown)
            ? !hiddenBreakdownOptions.includes(breakdownKey)
            : rowBreakdownKey !== breakdownKey)
        )
      })
      .map(([key, option]) => ({
        ...option,
        bold: satisfactionByKindList.includes(columnBreakdown)
          ? key === 'satisfaction'
          : columnBreakdown === key
      }))
  }, [breakdown, getBreakdownOptionsMap, breakdownList, columnBreakdown])

  return {
    field,
    setField,
    tableColumnOptions,
    breakdownOptions,
    defaultBreakdownOptionMap,
    generateOptions,
    getBreakdownOptionsMap,
    columnBreakdown,
    setColumnBreakdown
  }
}
export default useMetricsTrendlineBreakdowns
