import FlexContainer from '@/components/atoms/flex-container'
import IconButton from '@/components/atoms/icon-button'
import Text from '@/components/atoms/text'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import { dateShortMonthFormat } from '@/utils/date'
import { PencilSimple } from '@phosphor-icons/react'
import { useMemo, useState } from 'react'
import RenameOpportunityDialog from '@/components/molecules/exploration/Opportunities/OpportunitiesList/RenameOpportunityDialog'
import { styled } from '@/theme'
import useOpportunitiesQuery from '@/hooks/opportunity/useOpportunitiesQuery'
import Button from '@/components/atoms/button'
import { useUIStore } from '@/store'
import { shallow } from 'zustand/shallow'
import useUser from '@/hooks/useUser'
import useSegment from '@/hooks/useSegment'
import { useTranslation } from 'react-i18next'
import { useUserStore } from '@/store'
import useOpportunityStore from '@/store/useOpportunityStore'

const EDIT_OPP_NAME_CLASSNAME = 'edit-opportunity-name-icon'
const OPP_TITLE_CLASSNAME = 'opp-title'

const OpportunityTitleContainer = styled(FlexContainer, {
  mr: '$xxs',
  a: { textDecoration: 'none', color: 'inherit' },

  [`& .${EDIT_OPP_NAME_CLASSNAME}`]: {
    opacity: 0,
    bAll: '$neutralHighPure'
  },

  '&:hover': {
    [`& .${OPP_TITLE_CLASSNAME}`]: {
      color: '$brandPrimaryPure'
    },

    [`& .${EDIT_OPP_NAME_CLASSNAME}`]: {
      opacity: 1,
      color: '$brandPrimaryPure'
    }
  }
})

interface Props {
  resetScroll?: () => void
}

const OpportunityHeader = ({ resetScroll }: Props) => {
  const currentInterestArea = useCurrentInterestAreaStore(state => state.currentInterestArea)

  const opportunity = useOpportunityStore(state => state.currentOpportunity)

  const [isRenameOpen, setIsRenameOpen] = useState(false)

  const { t } = useTranslation()

  const descriptionEditOpen = useUIStore(state => state.descriptionEditOpen, shallow)
  const setDescriptionEditOpen = useUIStore(state => state.setDescriptionEditOpen)

  const { userPermissions } = useUser()
  const isManager = userPermissions.opportunities.includes('manager')
  const users = useUserStore(state => state.users, shallow)

  const { track } = useSegment()

  const opportunityCreatedAt = useMemo(() => {
    if (!opportunity) return
    const createdAt = opportunity.createdAt

    if (!createdAt || !(createdAt instanceof Date)) return
    const createdAtDate = `${t('createdAt')} ${dateShortMonthFormat(createdAt)} `

    return createdAtDate
  }, [opportunity, t])

  const opportunityCreatedBy = useMemo(() => {
    if (!opportunity) return
    const createdByID = opportunity.createdBy
    const createdByUser = users.find(user => user.user_id === createdByID)

    if (!createdByUser) return

    if (createdByUser.email.includes('@birdie')) {
      return `| ${t('suggestedByBirdie')}`
    }

    const creatorName = createdByUser?.name
    const createdByName = `| ${t('createdBy')} ${creatorName} `

    return createdByName
  }, [opportunity, users, t])

  const { updateOpportunity } = useOpportunitiesQuery({
    area: currentInterestArea,
    enabled: false
  })

  const onConfirmRename = (newName: string) => {
    if (!opportunity) return
    if (opportunity.name === newName || !newName.length) return

    updateOpportunity(
      {
        id: opportunity.id,
        name: newName.trim(),
        status: opportunity.status
      },
      {
        onSuccess: () => {
          track('opportunity_rename')
        }
      }
    )
  }

  const editDescriptionLabel = useMemo(() => {
    if (!opportunity) return
    if (!opportunity.description || !opportunity.description.length) return t('addDescription')
    if (descriptionEditOpen) return t('editing')

    return t('editDescription')
  }, [opportunity, descriptionEditOpen, t])

  const onEditDescriptionClick = () => {
    track('opportunity_edit_description', { name: opportunity?.name, id: opportunity?.id })
    setDescriptionEditOpen(true)
    resetScroll?.()
  }

  if (!opportunity) {
    return <></>
  }

  return (
    <>
      <FlexContainer css={{ px: '$xs', mt: '$xs' }} direction="column" gap="nano">
        <FlexContainer alignItems="start" gap="micro">
          <OpportunityTitleContainer direction="column" fullWidth gap="micro">
            <FlexContainer alignItems="start" fullWidth gap="micro">
              <FlexContainer direction="column" gap="micro">
                <FlexContainer alignItems="center" gap="micro">
                  <Text
                    className={OPP_TITLE_CLASSNAME}
                    color="neutralLowPure"
                    fontSize="xs"
                    fontWeight="bold"
                    lineHeight="sm"
                  >
                    {opportunity.name}
                  </Text>
                  {isManager && (
                    <IconButton
                      className={EDIT_OPP_NAME_CLASSNAME}
                      onClick={() => setIsRenameOpen(true)}
                    >
                      <PencilSimple size={16} />
                    </IconButton>
                  )}
                </FlexContainer>

                <Text color="neutralLowLight" fontSize="xxxs">
                  {opportunityCreatedAt} {opportunityCreatedBy}
                </Text>
              </FlexContainer>
            </FlexContainer>
          </OpportunityTitleContainer>
          <FlexContainer alignItems="center" css={{ ml: 'auto' }} gap="xxs">
            {isManager && (
              <Button
                data-state={descriptionEditOpen ? 'open' : 'closed'}
                onClick={onEditDescriptionClick}
                size="small"
                variant="white-bordered"
              >
                <PencilSimple size={16} />
                <Text as="span" fontSize="xxxs" fontWeight="regular">
                  {editDescriptionLabel}
                </Text>
              </Button>
            )}
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>

      {!!isRenameOpen && (
        <RenameOpportunityDialog
          onClose={() => setIsRenameOpen(false)}
          onSave={onConfirmRename}
          opportunityName={opportunity.name}
        />
      )}
    </>
  )
}

export default OpportunityHeader
