import { AnnotationRequests } from '@/types/annotations'
import useFeedQueryParams from './feedback/new/useFeedQueryParams'
import { useMemo } from 'react'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import { shallow } from 'zustand/shallow'
import { TimeSeriesIntervalOption } from '@/types/time-series/TimeSeries'
import AnnotationsService from '@/services/AnnotationsService'
import { useQuery } from '@tanstack/react-query'
import { AnnotationsQueryKey } from './analytics/timeSeriesQueryKeys'
import { AnnotationType } from '@/types/annotations/Annotation'
import useUser from './useUser'
import useOpportunityStore from '@/store/useOpportunityStore'

interface Params {
  enabled?: boolean
  interval: TimeSeriesIntervalOption
  type?: AnnotationType
}

const defaultParams: Params = {
  enabled: true,
  interval: TimeSeriesIntervalOption.Week,
  type: 'opportunity'
}

const useAnnotationsQuery = ({
  type = 'opportunity',
  enabled = true,
  interval
}: Params = defaultParams) => {
  const { currentUser } = useUser()

  const currentInterestArea = useCurrentInterestAreaStore(
    state => state.currentInterestArea,
    shallow
  )
  const currentOpportunity = useOpportunityStore(state => state.currentOpportunity, shallow)

  const { queryParams } = useFeedQueryParams()

  const postedAt = useMemo(
    () => ({
      gte: queryParams['posted_at.gte'],
      lt: queryParams['posted_at.lt']
    }),
    [queryParams['posted_at.gte'], queryParams['posted_at.lt']]
  )

  const annotationsQueryFn = async () => {
    const typeList: AnnotationType[] = ['organization']
    const parentIdList: string[] = [currentUser?.organization_id ?? '']
    if (type === 'area' && currentInterestArea) {
      typeList.push('area')
      parentIdList.push(currentInterestArea.id)
    } else if (type === 'opportunity' && currentOpportunity) {
      typeList.push('opportunity')
      parentIdList.push(currentOpportunity.id)
    }

    const params: AnnotationRequests.SearchAnnotationsParams = {
      posted_at_gte: postedAt.gte,
      posted_at_lt: postedAt.lt,
      parent_id: parentIdList,
      type: typeList,
      interval
    }

    const [error, data] = await AnnotationsService.search(params)

    if (error) throw error
    return data
  }

  const annotationsQueryKey = useMemo<AnnotationsQueryKey>(
    () => ['annotations', postedAt, interval, currentInterestArea, currentOpportunity],
    [postedAt, interval, currentOpportunity, currentInterestArea]
  )

  const query = useQuery({
    queryKey: annotationsQueryKey,
    queryFn: annotationsQueryFn,
    enabled
  })

  return {
    ...query,
    annotationsQueryKey
  }
}

export default useAnnotationsQuery
