import { AreaOfInterestData } from '@/types/area/AreaOfInterest'
import { RawMetric } from '@/types/metrics/Metrics'
import { OpportunityItemWithMetrics, OpportunityStatus } from '@/types/opportunity/Opportunity'

export type NormalizedAreaTypes = 'area-interest' | 'opportunity'
export type NormalizedAreaTableData = {
  id: string
  name: string
  opportunityCount: number
  type: NormalizedAreaTypes
  metrics: RawMetric[]
  new?: boolean
  status?: OpportunityStatus
  createdAt?: Date
  opportunities?: NormalizedAreaTableData[]
  originalArea?: AreaOfInterestData
  originalOpportunity?: OpportunityItemWithMetrics
}

export type NormalizedArea = Omit<NormalizedAreaTableData, 'originalArea'> & {
  originalArea: AreaOfInterestData
}

export type NormalizedOpportunity = Omit<
  NormalizedAreaTableData,
  'originalOpportunity' | 'originalArea'
> & {
  originalOpportunity: OpportunityItemWithMetrics
  originalArea: AreaOfInterestData
}

export const isNormalizedTableData = (data: unknown): data is NormalizedAreaTableData => {
  if (!data) return false
  const { type, opportunities } = data as NormalizedAreaTableData
  return type && ['area-interest', 'opportunity'].includes(type) && Array.isArray(opportunities)
}

export const isNormalizedArea = (data: unknown): data is NormalizedArea => {
  if (!data) return false
  const { type, originalArea } = data as NormalizedAreaTableData
  return type && type === 'area-interest' && !!originalArea
}

export const isNormalizedOpportunity = (data: unknown): data is NormalizedOpportunity => {
  if (!data) return false
  const { type, originalOpportunity } = data as NormalizedAreaTableData
  return type && type === 'opportunity' && !!originalOpportunity
}
