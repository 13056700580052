import useAdvancedFilters, { getFilterMap } from '@/hooks/advancedFilters/useAdvancedFilters'
import useBasicAreaOfInterestQuery from '@/hooks/areaOfInterest/useBasicAreaOfInterestQuery'
import useCollections from '@/hooks/collections/useCollections'
import useFieldsQuery from '@/hooks/useFieldsQuery'
import FiltersService from '@/services/FiltersService'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import useCompetitorsModeStore from '@/store/useCompetitorsModeStore'
import useOpportunityStore from '@/store/useOpportunityStore'
import { FilterNode, GenericFilter } from '@/types/filters/AdvancedFilters'
import {
  advancedFilterToContent,
  contentToAdvancedFilter,
  getAdvancedFilterFromContent,
  isFilterNode
} from '@/utils/advancedFilter'
import { useQuery } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'

interface FindFilterInContentParams {
  filterName: string
  values: (FilterNode | GenericFilter)[]
}
const removeFilterInContent = ({ filterName, values }: FindFilterInContentParams) => {
  const newValue = values.reduce(
    (acc, value) => {
      if (isFilterNode(value)) {
        value.value = removeFilterInContent({
          filterName,
          values: Array.isArray(value.value) ? value.value : [value.value]
        })
        return [...acc, value]
      } else if (value.name === filterName) {
        return acc
      } else {
        return [...acc, value]
      }
    },
    [] as (FilterNode | GenericFilter)[]
  )

  return newValue
}

interface Params {
  useAppliedFilters?: boolean
  useReviewCompetitors?: boolean
}

const useBaseMetricFilters = (
  { useAppliedFilters, useReviewCompetitors }: Params = {
    useAppliedFilters: false,
    useReviewCompetitors: false
  }
) => {
  const currentAreaOfInterest = useCurrentInterestAreaStore(state => state.currentInterestArea)
  const currentOpportunity = useOpportunityStore(state => state.currentOpportunity)
  const { contentData, currentCollection } = useCollections()
  const { context: filterContext, filters, isFetchingContext } = useAdvancedFilters()

  const { areas: advancedAreas } = useBasicAreaOfInterestQuery({
    enabled: true,
    collectionId: currentCollection?.collectionId
  })

  const { competitorsFilterValues } = useCompetitorsModeStore(state => ({
    competitorsFilterValues: state.competitorsFilterValues
  }))

  const { data: fieldsData } = useFieldsQuery()
  const filterTypeMap = useMemo(() => getFilterMap(fieldsData), [fieldsData])

  const queryKey = useMemo(
    () => [
      'get-base-metrics-context',
      {
        currentAreaOfInterestId: currentAreaOfInterest?.id,
        currentOpportunityId: currentOpportunity?.id,
        collectionContext: contentData?.context,
        collectionContent: contentData?.content,
        filterContext: useAppliedFilters ? filterContext : null,
        filters: useAppliedFilters ? filters : null
      },
      useAppliedFilters,
      useReviewCompetitors
    ],
    [
      currentAreaOfInterest,
      currentOpportunity,
      contentData,
      filterContext,
      filters,
      useAppliedFilters,
      useReviewCompetitors
    ]
  )

  const addReviewOwnerFilter = useCallback(
    (filterToChange: FilterNode): FilterNode => {
      const reviewOwnerFilter: GenericFilter = {
        name: 'review.owner',
        type: 'enum',
        value: competitorsFilterValues,
        path: 'kind.fields.owner',
        status: 'valid',
        isFromArea: false
      }

      const value = Array.isArray(filterToChange.value)
        ? [...filterToChange.value]
        : [filterToChange.value]

      const filtered = removeFilterInContent({ filterName: reviewOwnerFilter.name, values: value })

      if (filterToChange.operator !== '$and') {
        return {
          isFromArea: false,
          operator: '$and',
          value: [...filtered, reviewOwnerFilter]
        }
      }

      return { ...filterToChange, value: [...filtered, reviewOwnerFilter] }
    },
    [competitorsFilterValues]
  )

  const queryFn = async () => {
    if (useAppliedFilters) {
      if (useReviewCompetitors) {
        const newFilterNode = addReviewOwnerFilter(filters)
        const appliedContent = advancedFilterToContent(newFilterNode)

        const [contextError, contextData] = await FiltersService.advancedFilterContext({
          filter: appliedContent
        })

        if (contextError) throw contextError

        return {
          context: contextData.context,
          opportunity_id: currentAreaOfInterest ? currentOpportunity?.id : undefined
        }
      }

      return {
        context: filterContext ?? undefined,
        opportunity_id: currentAreaOfInterest ? currentOpportunity?.id : undefined
      }
    }

    if (currentAreaOfInterest) {
      const currentAdvancedArea = currentAreaOfInterest?.isUnmappedArea
        ? currentAreaOfInterest
        : advancedAreas.find(area => area.id === currentAreaOfInterest?.id)

      if (useReviewCompetitors) {
        const areaContent = getAdvancedFilterFromContent(currentAdvancedArea?.content ?? [])
        const advancedFiltersNode = contentToAdvancedFilter({
          content: areaContent,
          filterMap: filterTypeMap
        })

        const newFiltersNode = addReviewOwnerFilter(advancedFiltersNode as FilterNode)
        const appliedContent = advancedFilterToContent(newFiltersNode)

        const [contextError, contextData] = await FiltersService.advancedFilterContext({
          filter: appliedContent
        })

        if (contextError) throw contextError
        return {
          context: contextData.context,
          opportunity_id: currentAreaOfInterest ? currentOpportunity?.id : undefined
        }
      }

      return {
        context: currentAdvancedArea?.context,
        opportunity_id: currentAreaOfInterest ? currentOpportunity?.id : undefined
      }
    }

    if (contentData !== undefined) {
      if (useReviewCompetitors) {
        const collectionContent = getAdvancedFilterFromContent(contentData.content ?? [])
        const advancedFiltersNode = contentToAdvancedFilter({
          content: collectionContent,
          filterMap: filterTypeMap
        })

        const newFiltersNode = addReviewOwnerFilter(advancedFiltersNode as FilterNode)
        const appliedContent = advancedFilterToContent(newFiltersNode)

        const [contextError, contextData] = await FiltersService.advancedFilterContext({
          filter: appliedContent
        })

        if (contextError) throw contextError

        return {
          context: contextData.context,
          opportunity_id: currentAreaOfInterest ? currentOpportunity?.id : undefined
        }
      }

      return {
        context: contentData.context,
        opportunity_id: currentAreaOfInterest ? currentOpportunity?.id : undefined
      }
    }

    if (useReviewCompetitors) {
      const [contextError, contextData] = await FiltersService.advancedFilterContext({
        filter: {
          $and: [
            {
              'review.owner': competitorsFilterValues
            }
          ]
        }
      })

      if (contextError) throw contextError

      return { context: contextData.context }
    }

    return {}
  }

  const { data, isLoading } = useQuery({
    queryKey: [...queryKey],
    queryFn,
    enabled: !isFetchingContext,
    keepPreviousData: false,
    retry: false
  })

  const baseFiltersData = useMemo(() => {
    if (!data) {
      return {}
    }

    return data
  }, [data])

  return { data, baseFiltersData, isLoading, queryKey }
}

export default useBaseMetricFilters
