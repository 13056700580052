import GoBackButton from '@/components/atoms/button/GoBackButton'
import { ArrowLeft } from '@phosphor-icons/react'
import { forwardRef, useMemo } from 'react'
import { Link, LinkProps, To, useLocation } from 'react-router-dom'
import type { CSS } from '@/theme'

export interface ExplorationBackButtonProps extends Omit<LinkProps, 'to'> {
  buttonCss?: CSS
  to?: To
  preventDefault?: boolean
}

const ExplorationBackButton = forwardRef<HTMLAnchorElement, ExplorationBackButtonProps>(
  ({ buttonCss, preventDefault, to, ...props }, ref) => {
    const { state } = useLocation()

    const previousPage = useMemo(() => {
      if (state && typeof state.from === 'string' && !preventDefault) return state.from

      return to ?? '/areas-and-opportunities'
    }, [state, to, preventDefault])

    return (
      <Link {...props} to={previousPage} replace={props.replace ?? !preventDefault} ref={ref}>
        <GoBackButton>
          <ArrowLeft size={16} />
        </GoBackButton>
      </Link>
    )
  }
)

export default ExplorationBackButton
