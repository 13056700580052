import { useMemo } from 'react'
import useMetricsQuery from '@/hooks/metrics/useMetricsQuery'
import { allMetricItems, getMetricName, mapSourceToMetricName } from '@/utils/metrics'
import {
  AllMetricsKey,
  MetricItem,
  MetricKey,
  MetricsRequests,
  RawMetricUnit
} from '@/types/metrics'
import useHiddenMetricsStore from '@/store/useHiddenMetricsStore'
import useSourcesQuery from '@/hooks/useSourcesQuery'
import { snakeToTitle } from '@/utils/letterCase'
import { percentageScoreTransformer } from '@/utils/metrics/formatters'
import { FeedbackListQueryParams } from '@/types/feedbacks/FeedbackRequests'
import { useTranslation } from 'react-i18next'

const baseExplorationMetrics: Record<MetricKey, MetricItem> = {
  count: allMetricItems.count,
  support_ticket: allMetricItems.support_ticket,
  transcript: allMetricItems.transcript,
  nps: allMetricItems.nps,
  csat: allMetricItems.csat,
  review: allMetricItems.review_avg_rating,
  issue: allMetricItems.issue,
  social_media_post: allMetricItems.social_media_post,
  feedback_share: allMetricItems.feedback_share
}

const baseExplorationMetricsShare: Record<MetricKey, MetricItem> = {
  count: allMetricItems.count,
  support_ticket: allMetricItems.support_ticket_share,
  transcript: allMetricItems.transcript_share,
  nps: allMetricItems.nps,
  csat: allMetricItems.csat,
  review: allMetricItems.review_avg_rating,
  issue: allMetricItems.issue_share,
  social_media_post: allMetricItems.social_media_post_share,
  feedback_share: allMetricItems.feedback_share
}

const baseExplorationMetricsCountKeys: Record<MetricKey, AllMetricsKey> = {
  count: 'count',
  support_ticket: 'support_ticket',
  transcript: 'transcript',
  nps: 'nps',
  csat: 'csat',
  review: 'review_avg_rating',
  issue: 'issue',
  social_media_post: 'social_media_post',
  feedback_share: 'feedback_share'
}

const baseExplorationMetricsShareKeys: Record<MetricKey, AllMetricsKey> = {
  count: 'count',
  support_ticket: 'support_ticket_share',
  transcript: 'transcript_share',
  nps: 'nps',
  csat: 'csat',
  review: 'review_avg_rating',
  issue: 'issue_share',
  social_media_post: 'social_media_post_share',
  feedback_share: 'feedback_share'
}

const satisfactionCountMetrics: Partial<Record<MetricKey, MetricItem>> = {
  nps: allMetricItems.nps_count,
  review: allMetricItems.review_count,
  csat: allMetricItems.csat_count
}

const baseExplorationMetricKeys: MetricKey[] = [
  'count',
  'support_ticket',
  'transcript',
  'nps',
  'csat',
  'review',
  'issue',
  'social_media_post'
]

type VariationType = 'percentage' | 'points' | 'count'
type MetricResult = {
  metricKey: MetricKey
  title: string
  value: number
  currentValue: string
  previousValue: string
  variation: number
  variationType: VariationType
} & MetricItem

interface Props {
  filters: FeedbackListQueryParams[]
  useShare?: boolean
}

const useExplorationMetrics = ({ filters = [{}], useShare }: Props) => {
  const hiddenMetrics = useHiddenMetricsStore(state => state.hiddenMetrics)

  const { data: sourcesData, isLoading: isLoadingSources } = useSourcesQuery({ enabled: true })

  const { t } = useTranslation()
  const baseItems = useMemo(() => {
    return useShare ? baseExplorationMetricsShare : baseExplorationMetrics
  }, [useShare])

  const baseItemsKeys = useMemo(() => {
    return useShare ? baseExplorationMetricsShareKeys : baseExplorationMetricsCountKeys
  }, [useShare])

  const homeMetricList = useMemo(() => {
    const list: MetricsRequests.MetricsPayload['metric_list'] = [
      { ...baseExplorationMetrics.count.metric, args: baseExplorationMetrics.count.metric.filter }
    ]

    const metricsToLoad = sourcesData?.values ?? []
    const SATISFACTION_KINDS: MetricKey[] = ['csat', 'nps', 'review']

    metricsToLoad?.forEach(source => {
      const metricOption = source as MetricKey

      const metricItem = baseItems[metricOption]
      const metricKeyItem = baseItemsKeys[metricOption]

      if (metricItem && !hiddenMetrics.includes(metricOption)) {
        const countMetric = satisfactionCountMetrics[metricOption]
        if (SATISFACTION_KINDS.includes(metricOption) && countMetric) {
          list.push(
            {
              ...metricItem.metric,
              args: metricItem.metric.filter,
              name: getMetricName(metricItem.metric.name, metricKeyItem)
            },
            {
              ...countMetric.metric,
              args: countMetric.metric.filter,
              name: getMetricName(countMetric.metric.name, metricKeyItem)
            }
          )
        } else {
          list.push({
            ...metricItem.metric,
            args: metricItem.metric.filter,
            name: getMetricName(metricItem.metric.name, metricKeyItem)
          })
        }
      }
    })

    return list
  }, [hiddenMetrics, sourcesData, baseItems, baseItemsKeys])

  const { data, isLoading } = useMetricsQuery({
    filters,
    customMetricList: homeMetricList,
    enabled: filters.length > 0
  })

  const metrics = useMemo(() => {
    const homeMetricsData = data?.[0] ?? []

    const metrics = baseExplorationMetricKeys.map(baseMetricKey => {
      const baseMetric = baseItems[baseMetricKey]
      const rawMetric = homeMetricsData.find(
        rawMetric => rawMetric.label === baseMetric.metric.label
      )

      if (!rawMetric) return null

      const getValue = (value: number) => {
        const transformed = baseMetric.numberTransformer(value)
        return baseMetric.formatter(transformed)
      }

      const title =
        baseMetricKey === 'count'
          ? t('overall')
          : mapSourceToMetricName[baseMetricKey] ?? snakeToTitle(baseMetricKey)

      let variationType: VariationType = 'count'

      const percentageUnits: RawMetricUnit[] = ['count', 'percentage']
      const absUnits: RawMetricUnit[] = ['rating']
      const pointsUnits: RawMetricUnit[] = ['score']

      if (percentageUnits.includes(rawMetric.unit)) variationType = 'percentage'
      if (absUnits.includes(rawMetric.unit)) variationType = 'count'
      if (pointsUnits.includes(rawMetric.unit)) variationType = 'points'

      const getVariation = () => {
        if (variationType === 'percentage')
          return percentageScoreTransformer(rawMetric.percentage_variation)

        if (variationType === 'points')
          return percentageScoreTransformer(rawMetric.absolute_variation)

        return baseMetric.numberTransformer(rawMetric.absolute_variation)
      }

      return {
        ...baseMetric,
        title,
        metricKey: baseMetricKey,
        currentValue: getValue(rawMetric.current_value),
        previousValue: getValue(rawMetric.previous_value),
        variation: getVariation(),
        value: baseMetric.numberTransformer(rawMetric.current_value),
        variationType
      } as MetricResult
    })

    return metrics.filter(Boolean) as MetricResult[]
  }, [data, baseItems, t])

  const satisfactionCountItems = useMemo(() => {
    const homeMetricsData = data?.[0] ?? []

    const metrics = baseExplorationMetricKeys.map(baseMetricKey => {
      const baseMetric = satisfactionCountMetrics[baseMetricKey]

      if (!baseMetric) return null

      const rawMetric = homeMetricsData.find(
        rawMetric => rawMetric.label === baseMetric.metric.label
      )

      if (!rawMetric) return null

      const getValue = (value: number) => {
        const transformed = baseMetric.numberTransformer(value)
        return baseMetric.formatter(transformed)
      }

      return {
        ...baseMetric,
        metricKey: baseMetricKey,
        currentValue: getValue(rawMetric.current_value),
        value: baseMetric.numberTransformer(rawMetric.current_value)
      } as MetricResult
    })

    return metrics.filter(Boolean) as MetricResult[]
  }, [data])

  return { isLoading: isLoading || isLoadingSources, metrics, satisfactionCountItems }
}

export default useExplorationMetrics
