import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import { shallow } from 'zustand/shallow'
import useFeedQueryParams from './useFeedQueryParams'
import { FeedbackListParams } from '@/types/feedbacks/Feedback'
import ExportService from '@/services/ExportService'
import useToastMessageStore from '@/store/useToastMessageStore'
import useLogging from '@/hooks/useLogging'
import useAdvancedFilters from '@/hooks/advancedFilters/useAdvancedFilters'
import { ExportWithAreaPayload } from '@/types/export/ExportRequests'
import { advancedFilterToContent } from '@/utils/advancedFilter'
import useDateFilterStore from '@/store/useFiltersStore/useDateFilterStore'
import { endDateParam, startDateParam } from '@/utils/date'
import { AdvancedFilterContent } from '@/types/filters/AdvancedFilters'
import useOpportunityStore from '@/store/useOpportunityStore'

const useFeedbackExport = () => {
  const currentAreaOfInterest = useCurrentInterestAreaStore(
    state => state.currentInterestArea,
    shallow
  )
  const currentOpportunity = useOpportunityStore(state => state.currentOpportunity, shallow)

  const { filters: advancedFilters } = useAdvancedFilters()

  const { queryParams } = useFeedQueryParams()
  const { dateRange } = useDateFilterStore()

  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const addWarningToast = useToastMessageStore(state => state.addWarningToast)

  const { logException } = useLogging({ context: 'export-feedback' })

  const exportFeedback = async () => {
    const filterParams: FeedbackListParams = { ...queryParams }

    let name = ''
    if (currentOpportunity) {
      name = currentOpportunity.name
      filterParams.label = [
        `opportunity:${currentOpportunity.id}:true`,
        `opportunity:${currentOpportunity.id}:user:true`
      ]
    } else if (currentAreaOfInterest) {
      name = currentAreaOfInterest.name
    }

    const [error] = await ExportService.export({
      filter: filterParams,
      name
    })

    if (error) {
      const message = 'Failed to export feedback.'
      logException(error, { message })
      addErrorToast({ text: message })
      return
    }

    addWarningToast({
      text: 'The export is in progress. The file will be emailed to you in a few minutes'
    })
  }

  const exportFeedbackWithArea = async () => {
    const filterParams: FeedbackListParams = { ...queryParams }

    let name = ''
    let areaId
    if (currentOpportunity) {
      name = currentOpportunity.name
      areaId = currentOpportunity.relations.join(',')
      filterParams.label = [
        `opportunity:${currentOpportunity.id}:true`,
        `opportunity:${currentOpportunity.id}:user:true`
      ]
    } else if (currentAreaOfInterest) {
      name = currentAreaOfInterest.name
      areaId = currentAreaOfInterest.id
    }

    if (filterParams.context) {
      filterParams.context = undefined
    }

    areaId = undefined
    const advancedFilterContent: AdvancedFilterContent = advancedFilterToContent({
      ...advancedFilters
    })
    if (dateRange && advancedFilterContent.$and) {
      const startDate = startDateParam(dateRange.start)
      const endDate = endDateParam(dateRange.end)

      advancedFilterContent.$and = [
        ...advancedFilterContent.$and,
        { posted_at: { $gte: startDate, $lt: endDate } }
      ]
    }

    const payload: ExportWithAreaPayload = {
      filter: advancedFilterContent ? advancedFilterContent : filterParams,
      name,
      area_id: areaId,
      advanced: true
    }
    const [error] = await ExportService.exportWithArea(payload)

    if (error) {
      const message = 'Failed to export feedback.'
      logException(error, { message })
      addErrorToast({ text: message })
      return
    }

    addWarningToast({
      text: 'The export is in progress. The file will be emailed to you in a few minutes'
    })
  }

  return { exportFeedback, exportFeedbackWithArea }
}

export default useFeedbackExport
