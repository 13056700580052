import { ArrowRight, DotsThreeVertical, ListChecks, PencilSimple } from '@phosphor-icons/react'
import { useState, MouseEvent, ReactNode } from 'react'
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import { DropdownMenuContent, DropdownMenuItem } from '@/components/atoms/options-menu'
import { BaseInterestArea } from '@/types/area/AreaOfInterest'
import IconButton from '@/components/atoms/icon-button'
import { useTranslation } from 'react-i18next'
import useBasicAreaOfInterestQuery from '@/hooks/areaOfInterest/useBasicAreaOfInterestQuery'
import useOpportunityMutations from '@/hooks/opportunity/useOpportunityMutations'
import MoveOpportunityModal from '@/components/molecules/opportunities/MoveOpportunityModal'
import { AssociateWithAreasModal } from '@/components/molecules/opportunities/AssociateWithAreasModal'
import { OpportunityItem } from '@/types/opportunity/Opportunity'

interface Props {
  data: OpportunityItem
  onRename: () => void
  onConfirmMoveTo: (area: BaseInterestArea) => void
  customButton?: ReactNode
}
const OpportunityOptions = ({ data, onConfirmMoveTo, onRename, customButton }: Props) => {
  const [isMoveToOpen, setIsMoveToOpen] = useState(false)
  const [isAddToAreasOpen, setIsAddToAreasOpen] = useState(false)
  const [isOptionsOpen, setIsOptionsOpen] = useState(false)

  const { t } = useTranslation()
  const { areas, isLoading } = useBasicAreaOfInterestQuery()

  const { linkOpportunityToAreas } = useOpportunityMutations()

  const onClickMoveToOpen = (event: Event) => {
    event.stopPropagation()
    event.preventDefault()

    setIsMoveToOpen(true)
  }

  const onClickRename = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    event.preventDefault()
    setIsMoveToOpen(false)
    setIsOptionsOpen(false)
    setIsAddToAreasOpen(false)
    onRename()
  }

  const onClickAdd = (event: Event) => {
    event.stopPropagation()
    event.preventDefault()

    setIsAddToAreasOpen(true)
  }

  const onAddAreas = (areaIds: string[]) => {
    linkOpportunityToAreas({
      opportunityId: data.id,
      areaIds
    })
  }

  const onOptionsOpenChange = (open: boolean) => {
    setIsOptionsOpen(open)
    if (!open && isMoveToOpen) setIsMoveToOpen(false)
    if (!open && isAddToAreasOpen) setIsAddToAreasOpen(false)
  }

  const onConfirmMoveArea = (areaId: string) => {
    setIsMoveToOpen(false)
    setIsOptionsOpen(false)
    const area = areas.find(area => area.id === areaId)
    if (!area) return
    onConfirmMoveTo(area)
  }

  return (
    <DropdownMenuPrimitive.Root modal onOpenChange={onOptionsOpenChange} open={isOptionsOpen}>
      <DropdownMenuPrimitive.Trigger asChild>
        {customButton ?? (
          <IconButton aria-label="options" onClick={e => e.stopPropagation()}>
            <DotsThreeVertical weight="bold" />
          </IconButton>
        )}
      </DropdownMenuPrimitive.Trigger>

      {!isMoveToOpen && !isAddToAreasOpen && (
        <DropdownMenuPrimitive.Portal>
          <DropdownMenuContent
            hideWhenDetached
            onClick={e => {
              e.stopPropagation()
            }}
            sideOffset={5}
          >
            <DropdownMenuItem onSelect={onClickAdd}>
              <ListChecks />
              {t('associateWith')}
            </DropdownMenuItem>

            <DropdownMenuItem onSelect={onClickMoveToOpen}>
              <ArrowRight />
              {t('moveTo')}
            </DropdownMenuItem>

            <DropdownMenuItem onClick={onClickRename}>
              <PencilSimple />
              {t('rename')}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenuPrimitive.Portal>
      )}

      {isMoveToOpen && (
        <MoveOpportunityModal
          open={isMoveToOpen}
          onOpenChange={setIsMoveToOpen}
          areas={areas}
          isLoading={isLoading}
          onMove={onConfirmMoveArea}
          selectedAreaId={data.relations[0]}
        />
      )}

      {isAddToAreasOpen && (
        <AssociateWithAreasModal
          open={isAddToAreasOpen}
          onOpenChange={setIsAddToAreasOpen}
          areas={areas}
          opportunity={data}
          isLoading={isLoading}
          onAddToAreas={onAddAreas}
        />
      )}
    </DropdownMenuPrimitive.Root>
  )
}

export default OpportunityOptions
