import { ActionBar } from '@/components/atoms/action-bar'
import Button from '@/components/atoms/button'
import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import { useUIStore } from '@/store'
import { ThumbsDown, X } from '@phosphor-icons/react'
import { shallow } from 'zustand/shallow'
import Checkbox from '@/components/atoms/checkbox'
import { useTranslation } from 'react-i18next'
import useOpportunityStore from '@/store/useOpportunityStore'

interface Props {
  selectedFeedback: string[]
  total: number
  isChecked: boolean
  onClearSelection: () => void
  onSelectAll?: () => void
  onConfirm?: () => void
}

const FeedbackActionBar = ({
  selectedFeedback,
  total,
  isChecked = false,
  onClearSelection,
  onConfirm,
  onSelectAll
}: Props) => {
  const currentOpportunity = useOpportunityStore(state => state.currentOpportunity, shallow)

  const count = selectedFeedback.length
  const enabled = Boolean(count > 0 && currentOpportunity)

  const onCheckedChange = () => {
    onSelectAll?.()
  }

  const { t } = useTranslation()

  const checkboxText = `Select all ${total} feedback`

  const isFiltersOpen = useUIStore(state => state.isAdvancedFiltersOpen, shallow)

  const remainingWidth = 120
  const filtersWidth = 400
  const width = isFiltersOpen
    ? `calc(100% - ${remainingWidth + filtersWidth}px)`
    : `calc(100% - ${remainingWidth}px)`

  const onRemoveClick = () => {
    onConfirm?.()
  }

  if (!enabled) return <></>

  return (
    <>
      <ActionBar css={{ width }}>
        <FlexContainer alignItems="center" fullWidth justifyContent="spaceBetween">
          <FlexContainer alignItems="center" gap="xxs">
            <Checkbox
              checked={isChecked}
              negative
              onCheckedChange={onCheckedChange}
              text={checkboxText}
              value="all-feedback"
            />
            <Text
              as="p"
              color="white"
              onClick={onClearSelection}
              role="button"
              typeface="textSemiboldSmall"
            >
              <span>{count} feedback selected</span>
              <X />
            </Text>
          </FlexContainer>
          <Button onClick={onRemoveClick} size="small" variant="exception">
            <ThumbsDown />
            <span>{t('itSInaccurate')}</span>
          </Button>
        </FlexContainer>
      </ActionBar>
    </>
  )
}

export default FeedbackActionBar
