import { useQuery } from '@tanstack/react-query'
import useUser from '../useUser'
import AudienceService from '@/services/AudienceService'
import { useMemo } from 'react'
import { snakeToTitle } from '@/utils/letterCase'
import { FilterItem } from '@/types/filters/Filters'
import useCustomerUsersStore from '@/store/useCustomerUsersStore'
import {
  CustomerRecordsField,
  CustomerRecordsFieldTypes
} from '@/types/manage-customers/CustomerRecordsFilters'

const parseName: Record<string, string> = {
  name: 'User name',
  ingested_id: 'User id'
}

const REMOVED_FIELDS = ['ingested_at']

const mapCustomerRecordsTypes: Record<CustomerRecordsFieldTypes, string> = {
  list: 'users-enum',
  enum: 'users-enum',
  id: 'users-id',
  unique: 'users-id',
  number: 'users-number',
  date: 'users-date',
  datetime: 'users-date',
  boolean: 'users-boolean'
}

const formatFilterTitle = (field: CustomerRecordsField): string => {
  const parsedName = parseName[field.name]
  if (parsedName) return parsedName

  const splittedFieldName = field.name.split('.')
  const filtersFormatted = splittedFieldName.map(name => snakeToTitle(name)).join(' ')

  return filtersFormatted
}

interface UserFiltersConfig {
  refetch?: boolean
}

const defaultConfig: UserFiltersConfig = {
  refetch: true
}

const useCustomerUsersFilters = ({ refetch = true }: UserFiltersConfig = defaultConfig) => {
  const { userPermissions } = useUser()
  const hasCustomerRecordsPermission = userPermissions.source.includes('customer_records')
  const hasUsers = useCustomerUsersStore(state => state.hasCustomerUsers)

  const { data, isLoading } = useQuery({
    queryKey: ['filters-customer-users-fields'],
    queryFn: async queryParams => AudienceService.getCustomerUsersFiltersFields(queryParams),
    refetchOnMount: refetch,
    enabled: hasCustomerRecordsPermission && hasUsers
  })

  const fields: FilterItem[] = useMemo(() => {
    if (!data) return []
    const [error, usersFieldsData] = data
    if (error) return []
    if (!usersFieldsData?.fields) return []

    return usersFieldsData.fields
      .filter(field => !REMOVED_FIELDS.includes(field.name))
      .map(filter => ({
        key: filter.name,
        type: mapCustomerRecordsTypes[filter.type],
        displayName: formatFilterTitle(filter),
        name: filter.name,
        path: filter.path
      }))
  }, [data])

  return {
    fields,
    isLoading,
    mapCustomerRecordsTypes
  }
}

export default useCustomerUsersFilters
