import { forwardRef } from 'react'
import { NavLink, NavLinkProps, useLocation } from 'react-router-dom'

/**
 * Custom, unstyled react router Link thats set the current path as "from" in the location state
 */
const NavLinkFromTo = forwardRef<HTMLAnchorElement, NavLinkProps>((props, ref) => {
  const { pathname } = useLocation()

  return <NavLink {...props} state={{ ...props.state, from: pathname }} ref={ref} />
})

export default NavLinkFromTo
