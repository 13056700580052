import FlexContainer from '@/components/atoms/flex-container'
import HomeMetricItem from './home-metric-item'
import Bone from '@/components/atoms/bone'
import AreaOfInterestList from '@/components/molecules/area-of-interest/AreaOfInterestList/AreaOfInterestList'
import useExplorationMetrics from '@/hooks/metrics/useExplorationMetrics'
import useCollections from '@/hooks/collections/useCollections'
import { useCallback, useMemo } from 'react'
import { getParamsFromFilterContent } from '@/utils/filters'
import { SavedFilterContent } from '@/types/filters/Filters'
import { MetricKey } from '@/types/metrics'

const OverallMetrics = () => {
  const { contentData } = useCollections()

  const filters = useMemo(() => {
    const rawContent = contentData?.content
    const content = Array.isArray(rawContent) ? (rawContent as SavedFilterContent[]) : []

    return {
      ...getParamsFromFilterContent(content),
      context: contentData?.context
    }
  }, [contentData])

  const { metrics, isLoading, satisfactionCountItems } = useExplorationMetrics({
    filters: filters ? [{ ...filters }] : []
  })
  const loading = useMemo(() => isLoading, [isLoading])

  const getSatisfactionMetricValue = useCallback(
    (metricKey: MetricKey) => {
      const metricItem = satisfactionCountItems.find(metric => metric.metricKey === metricKey)
      if (!metricItem) return undefined

      return metricItem.currentValue
    },
    [satisfactionCountItems]
  )

  return (
    <FlexContainer alignItems="center" justifyContent="spaceBetween">
      <FlexContainer
        alignItems="center"
        fullWidth
        gap={metrics.length > 5 ? 'xxs' : 'md'}
        justifyContent="flexEnd"
      >
        {loading && (
          <>
            <Bone css={{ width: 130, height: 70 }} />
            <Bone css={{ width: 130, height: 70 }} />
            <Bone css={{ width: 130, height: 70 }} />
            <Bone css={{ width: 130, height: 70 }} />
          </>
        )}
        {!loading &&
          metrics.map(metric => (
            <HomeMetricItem
              css={{ minWidth: 90 }}
              key={metric.metricKey}
              title={metric.title}
              countMetricValue={getSatisfactionMetricValue(metric.metricKey)}
              value={metric.currentValue}
              variation={
                <AreaOfInterestList.Variation
                  css={{ fontSize: '$xxxs' }}
                  formatter={metric.formatter}
                  type={metric.variationType}
                  value={metric.variation}
                />
              }
            />
          ))}
      </FlexContainer>
    </FlexContainer>
  )
}

export default OverallMetrics
