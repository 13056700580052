import { ReactNode } from 'react'
import { CSS } from '@/theme'
import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import { useTranslation } from 'react-i18next'

interface Props {
  title: string
  value: ReactNode
  variation?: ReactNode
  css?: CSS
  countMetricValue?: string
}

const HomeMetricItem = ({ title, value, variation, countMetricValue, css }: Props) => {
  const { t } = useTranslation()

  return (
    <FlexContainer css={css} direction="column" gap="nano">
      <Text color="neutralLowLight" css={{ lineHeight: '16.1px' }} fontSize="micro">
        {title}
      </Text>
      <FlexContainer alignItems="end">
        <Text
          color="neutralLowPure"
          css={{ lineHeight: '32px', mr: 2 }}
          fontSize="md"
          fontWeight="semibold"
        >
          {value}
        </Text>
        {variation}
      </FlexContainer>
      <FlexContainer css={{ height: 24 }}>
        <Text fontSize="micro" color="neutralLowLight">
          {countMetricValue ? `${t('count')}: ${countMetricValue}` : ''}
        </Text>
      </FlexContainer>
    </FlexContainer>
  )
}

export default HomeMetricItem
