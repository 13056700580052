import { DateValue } from '@react-types/calendar'
import { Period } from '../periods'
import { NotificationData } from '../notifications/Notifications'
import { AdvancedFilterContent } from './AdvancedFilters'
import { FeedbackFieldsResponse } from '../feedbacks/FeedbackRequests'

export type NumericRangeOption = 'between' | 'is' | 'null' | 'all'
export type FilterNumericValue = [number, number] | null
export type FilterDatetimeValue = RangeValue<DateValue> | null
export type FilterBooleanValue = boolean | null

export interface FilterItem {
  key: string
  type: string
  name: string
  displayName: string
  description?: string
  path?: string
}

export interface FilterFeedbackTypeItem {
  feedbackSourceIds: string[]
  value: string
  text: string
}

export interface FilterNumeric {
  key: string
  type: string
  name: string
  draftOption: NumericRangeOption
  option: NumericRangeOption
  draftValue: FilterNumericValue
  value: FilterNumericValue
}

export interface FilterDatetime {
  key: string
  type: string
  name: string
  draftValue: FilterDatetimeValue
  value: FilterDatetimeValue
  period: Period
  draftPeriod: Period
}

export interface MultiSelect<T = string> {
  draftSelected: T[]
  selected: T[]
}

export interface FilterString extends MultiSelect<string> {
  key: string
  type: string
  name: string
}

export interface FilterBoolean {
  key: string
  type: string
  name: string
  value: FilterBooleanValue
  draftValue: FilterBooleanValue
}

export type RadioBooleanOption = 'all' | 'true' | 'false'

export interface FilterText {
  key: string
  name: string
  value: string
}

type FilterTopicType = 'topic' | 'theme' | 'unclassified_topic'
export interface AppliedTopicFilterItem {
  type: FilterTopicType
  value: string
}

export interface SavedFilterContent {
  key: string
  type: string
  name: string
  values: string[]
  category?: string
}

export interface SavedFilterContentAdvanced {
  key: 'advanced'
  name: 'advanced'
  type: 'advanced'
  values: {
    filter: AdvancedFilterContent
  }
}

export interface FilterTopicOption {
  id: string
  text: string
}

export type VolumeByType = 'feedback-record' | 'ticket'

export interface SavedFilter {
  filterId: string
  organizationId: string
  name: string
  hash: string
  content: SavedFilterContent[] | [SavedFilterContentAdvanced]
  createdBy: string
  icon?: string
  notification?: NotificationData
}

export interface SavedFilterWithDisabled extends SavedFilter {
  disabled?: boolean
}

export type SavedFilterType = 'basic' | 'area_interest' | 'opportunity'

export enum SavedFilterTypeId {
  Basic = '04278a81-c3cd-4be9-9337-518214974bae',
  AreaOfInterest = 'd5cc8767-3ba5-4eb3-a2b0-7fd3ee853dfb',
  Opportunity = '09b7d434-f649-4ecc-852d-4fc756d1556f'
}

export type OpportunitySourcesV2Type =
  | 'supportTickets'
  | 'nps'
  | 'issue'
  | 'total'
  | 'csat'
  | 'review'
  | 'socialMediaPosts'
  | 'transcript'

export type OpportunityStatsType = {
  [source in OpportunitySourcesV2Type]?: number
}
export interface OpportunityStatsInfos {
  /** valores absolutos da area */
  area: OpportunityStatsType
  /** valores absolutos da oportunidade */
  opportunity: OpportunityStatsType
  /** metricas da oportunidade atual em relacao a area */
  metric: OpportunityStatsType
  /** comparacao da oportunidade com a organizacao inteira */
  overall: OpportunityStatsType
}

export type FilterField = FeedbackFieldsResponse['fields'][number]
