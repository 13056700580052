import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import { useEffect, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import useAdvancedFilters from './advancedFilters/useAdvancedFilters'
import { useQuery } from '@tanstack/react-query'
import { BaseInterestArea } from '@/types/area/AreaOfInterest'
import { stringToDate } from '@/utils/date'

import shortUUID from 'short-uuid'
import useOpportunityStore from '@/store/useOpportunityStore'
import useNavitagateTo from './useNavigateTo'
import AreaService from '@/services/AreaService'
import OpportunityService from '@/services/OpportunityService'
import { OpportunityItem } from '@/types/opportunity/Opportunity'

const usePageLink = () => {
  const translator = useMemo(() => shortUUID(), [])

  const { shortAreaId, shortOpportunityId } = useParams()
  const { search } = useLocation()

  const [areaId, setAreaId] = useState(() =>
    shortAreaId ? translator.toUUID(shortAreaId).toString() : undefined
  )
  const [opportunityId, setOpportunityId] = useState(() =>
    shortOpportunityId ? translator.toUUID(shortOpportunityId).toString() : undefined
  )

  useEffect(() => {
    if (search.includes('notificationId')) return

    setAreaId(shortAreaId ? translator.toUUID(shortAreaId) : undefined)
    setOpportunityId(shortOpportunityId ? translator.toUUID(shortOpportunityId) : undefined)
  }, [shortAreaId, shortOpportunityId, search, translator])

  const { navigateTo: navigate } = useNavitagateTo()
  const params = new URLSearchParams(search)
  const notificationId = params.get('notificationId')

  const currentAreaOfInterest = useCurrentInterestAreaStore(state => state.currentInterestArea)
  const setCurrentAreaOfInterest = useCurrentInterestAreaStore(
    state => state.setCurrentInterestArea
  )

  const currentOpportunity = useOpportunityStore(state => state.currentOpportunity)

  const { applyFilterFromArea, applyOpportunityFilters } = useAdvancedFilters()

  const isAreaQueryEnabled = (!!areaId && currentAreaOfInterest?.id !== areaId) || !!notificationId

  const areaQueryFn = async () => {
    const id = areaId ?? notificationId
    if (!id) {
      throw new Error(`No area id provided`)
    }

    const [error, response] = await AreaService.getArea(id)

    if (error) throw error

    const areaFound = response
    if (!areaFound) throw new Error(`Area ${areaId} not found`)

    const parsedArea: BaseInterestArea = {
      id: areaFound.area_id,
      filterId: areaFound.filter_id,
      name: areaFound.name,
      content: areaFound.content ?? [],
      context: areaFound.context,
      createdBy: areaFound.created_by ?? '',
      opportunityCount: areaFound.opportunities?.length ?? 0,
      useInUnmappedArea: areaFound.is_mapped ?? false,
      advanced: areaFound.advanced ?? false
    }

    if (notificationId) {
      setAreaId(areaFound.filter_id)

      params.delete('notificationId')
      params.delete('version')

      const areaUUID = translator.fromUUID(areaFound.filter_id)
      navigate(
        {
          pathname: `/area/${areaUUID}`,
          search: params.toString()
        },
        { replace: true }
      )
    }

    return parsedArea
  }

  const { data: area, isLoading: isAreaLoading } = useQuery({
    queryKey: ['area-of-interest', areaId, notificationId],
    queryFn: areaQueryFn,
    enabled: isAreaQueryEnabled,
    retry: false
  })

  const isOpportunityQueryEnabled =
    (!!opportunityId && currentOpportunity?.id !== opportunityId) || !!notificationId

  const opportunityQueryFn = async () => {
    const id = notificationId ?? opportunityId
    if (!id) {
      throw new Error(`No opportunity id provided`)
    }

    const [error, response] = await OpportunityService.getOpportunity(id)

    if (error) throw error

    const opportunityFound = response
    if (!opportunityFound) throw new Error(`Opportunity ${opportunityId} not found`)

    const parsedOpportunity: OpportunityItem = {
      name: opportunityFound.name,
      id: opportunityFound.opportunity_id,
      filterId: opportunityFound.filter_id,
      status: opportunityFound.opportunity_status_id,
      description: opportunityFound.description,
      createdAt: stringToDate(opportunityFound.created_at),
      new: opportunityFound.new ?? false,
      createdBy: opportunityFound.created_by,
      relations: opportunityFound.areas
    }

    if (notificationId) {
      setOpportunityId(opportunityFound.filter_id)
    }

    return parsedOpportunity
  }

  const { data: opportunity, isLoading: isOpportunityLoading } = useQuery({
    queryKey: ['opportunity', opportunityId, notificationId],
    queryFn: opportunityQueryFn,
    enabled: isOpportunityQueryEnabled,
    retry: false
  })

  // biome-ignore lint/correctness/useExhaustiveDependencies: should only react to area and opportunity changes
  useEffect(() => {
    if (areaId && currentAreaOfInterest?.id !== areaId && area) {
      setCurrentAreaOfInterest(area)
      applyFilterFromArea(area)
    }

    if (opportunityId && currentOpportunity?.id !== opportunityId && opportunity) {
      applyOpportunityFilters({ opportunity })
    }
  }, [area, opportunity])

  return { area, opportunity, isAreaLoading, isOpportunityLoading }
}

export default usePageLink
