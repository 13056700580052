import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import useSavedFilters from '../useSavedFilters'
import useUser from '../useUser'
import { AreaOfInterestData, BaseInterestArea } from '@/types/area/AreaOfInterest'
import { useMutation } from '@tanstack/react-query'
import { queryClient } from '@/plugins/reactQueryClient'
import useToastMessageStore from '@/store/useToastMessageStore'
import useLogging from '../useLogging'
import { UNMAPPED_AREA_QUERY_KEY } from './useUnmappedAreaQuery'
import useAdvancedFilters from '../advancedFilters/useAdvancedFilters'
import { useFeedFiltersStore } from '@/store/useFiltersStore'
import shortUUID from 'short-uuid'
import useCollections from '../collections/useCollections'
import useOpportunityStore from '@/store/useOpportunityStore'
import AreaService from '@/services/AreaService'
import useBasicAreaOfInterestQuery from './useBasicAreaOfInterestQuery'
import useAllAreasQuery, { AREAS_KEY_PREFIX } from './useAllAreasQuery'
import { AreaRequests } from '@/types/area'
import useNavitagateTo from '../useNavigateTo'

export const BASE_AREAS_KEY = 'interest-areas'

const useAreaOfInterest = () => {
  const { navigateTo: navigate } = useNavitagateTo()

  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const { logException } = useLogging({ context: 'area-of-interest' })

  const currentInterestArea = useCurrentInterestAreaStore(state => state.currentInterestArea)

  const setCurrentAreaOfInterest = useCurrentInterestAreaStore(
    state => state.setCurrentInterestArea
  )
  const setLastOrganizationId = useCurrentInterestAreaStore(state => state.setLastOrganizationId)

  const setCurrentOpportunity = useOpportunityStore(state => state.setCurrentOpportunity)

  const { currentUser } = useUser()

  const { applyFilterContent } = useSavedFilters({ newFeed: true })
  const resetAllFilters = useFeedFiltersStore(state => state.resetAll)
  const { applyFilterFromArea, clearFilters } = useAdvancedFilters()

  const setArea = (area: BaseInterestArea | AreaOfInterestData, preserveOpportunity?: boolean) => {
    setCurrentAreaOfInterest(area)
    !preserveOpportunity && setCurrentOpportunity(undefined)

    applyFilterFromArea(area)
    applyFilterContent([], true)
    setLastOrganizationId(currentUser?.organization_id)

    const translator = shortUUID()
    const shortAreaId = translator.fromUUID(area.id)

    navigate(`/area/${shortAreaId}`)
  }

  const setFreeExploration = () => {
    clearFilters()
    resetAllFilters({ keepDate: true })
    setCurrentAreaOfInterest(undefined)
    setCurrentOpportunity(undefined)
  }

  const { currentCollection } = useCollections({ enabled: false })
  const { queryKey: areasQueryKey } = useBasicAreaOfInterestQuery({
    enabled: false,
    collectionId: currentCollection?.collectionId
  })

  const { queryKey: listAreasQueryKey } = useAllAreasQuery({ enabled: false })

  const { mutate: renameArea } = useMutation({
    mutationKey: ['rename-area-of-interest'],
    mutationFn: async ({ area, newName }: { area: BaseInterestArea; newName: string }) => {
      const [error] = await AreaService.updateArea(area.id, {
        name: newName,
        filter_id: area.filterId
      })

      if (error) throw error
      return { area, newName }
    },
    onMutate: async ({ area, newName }: { area: BaseInterestArea; newName: string }) => {
      await queryClient.cancelQueries({ queryKey: [AREAS_KEY_PREFIX] })
      await queryClient.cancelQueries({ queryKey: [BASE_AREAS_KEY] })

      const previousAreas = queryClient.getQueryData<AreaOfInterestData[]>(listAreasQueryKey)
      queryClient.setQueryData<AreaOfInterestData[]>(listAreasQueryKey, old => {
        if (!old) return
        return old.map(item => (item.id === area.id ? { ...item, name: newName } : item))
      })

      const previousAdvancedAreas =
        queryClient.getQueryData<AreaRequests.SearchAreasResponse['areas']>(areasQueryKey)
      queryClient.setQueryData<AreaRequests.SearchAreasResponse['areas']>(areasQueryKey, old => {
        if (!old) return
        return old.map(item => (item.area_id === area.id ? { ...item, name: newName } : item))
      })

      return { previousAreas, previousAdvancedAreas }
    },
    onError: (error, __, context) => {
      const message = 'Failed to rename this area. Please try again.'
      logException(error, { message })
      addErrorToast({ text: message })
      queryClient.setQueryData(listAreasQueryKey, context?.previousAreas)
      queryClient.setQueryData(areasQueryKey, context?.previousAdvancedAreas)
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [BASE_AREAS_KEY] })
      queryClient.invalidateQueries({ queryKey: [AREAS_KEY_PREFIX] })
    }
  })

  const { mutate: deleteArea, isLoading: isDeleting } = useMutation({
    mutationKey: ['delete-area-of-interest'],
    mutationFn: async (areaId: string) => {
      const [error] = await AreaService.removeArea(areaId)

      if (error) throw error
      return areaId
    },
    onMutate: async (areaId: string) => {
      await queryClient.cancelQueries({ queryKey: [AREAS_KEY_PREFIX] })
      await queryClient.cancelQueries({ queryKey: [BASE_AREAS_KEY] })

      const previousAreas = queryClient.getQueryData<AreaOfInterestData[]>(listAreasQueryKey)
      queryClient.setQueryData<AreaOfInterestData[]>(listAreasQueryKey, old =>
        old?.filter(area => area.id !== areaId)
      )

      const previousAdvancedAreas =
        queryClient.getQueryData<AreaRequests.SearchAreasResponse['areas']>(areasQueryKey)
      queryClient.setQueryData<AreaRequests.SearchAreasResponse['areas']>(areasQueryKey, old => {
        if (!old) return
        return old.filter(area => area.area_id !== areaId)
      })

      return { previousAreas, previousAdvancedAreas }
    },
    onError: (error, __, context) => {
      const message = 'Failed to delete this area. Please try again.'
      logException(error, { message })
      addErrorToast({ text: message })
      queryClient.setQueryData(listAreasQueryKey, context?.previousAreas)
      queryClient.setQueryData(areasQueryKey, context?.previousAdvancedAreas)
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [AREAS_KEY_PREFIX] })
      queryClient.invalidateQueries({ queryKey: [BASE_AREAS_KEY] })

      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: [UNMAPPED_AREA_QUERY_KEY], exact: false })
      }, 500)
    }
  })

  const { mutate: updateUseInUnmapped } = useMutation({
    mutationKey: ['update-area-use-in-unmapped'],
    mutationFn: async ({
      area,
      newUseInUnmapped
    }: {
      area: BaseInterestArea
      newUseInUnmapped: boolean
    }) => {
      const [error] = await AreaService.updateArea(area.id, {
        name: area.name,
        filter_id: area.filterId,
        is_mapped: newUseInUnmapped
      })

      if (error) throw error
      return { area, newUseInUnmapped }
    },
    onMutate: async ({
      area,
      newUseInUnmapped
    }: {
      area: BaseInterestArea
      newUseInUnmapped: boolean
    }) => {
      await queryClient.cancelQueries({ queryKey: [AREAS_KEY_PREFIX] })
      await queryClient.cancelQueries({ queryKey: [BASE_AREAS_KEY] })

      const previousAreas = queryClient.getQueryData<AreaOfInterestData[]>(listAreasQueryKey)
      queryClient.setQueryData<AreaOfInterestData[]>(listAreasQueryKey, old =>
        old?.map(item =>
          item.id === area.id ? { ...item, useInUnmappedArea: newUseInUnmapped } : item
        )
      )

      const previousAdvancedAreas =
        queryClient.getQueryData<AreaRequests.SearchAreasResponse['areas']>(areasQueryKey)
      queryClient.setQueryData<AreaRequests.SearchAreasResponse['areas']>(areasQueryKey, old => {
        if (!old) return
        return old.map(item =>
          item.area_id === area.id ? { ...item, use_in_unmapped: newUseInUnmapped } : item
        )
      })

      if (currentInterestArea?.id === area.id) {
        setCurrentAreaOfInterest({ ...currentInterestArea, useInUnmappedArea: newUseInUnmapped })
      }

      return { previousAreas, previousAdvancedAreas }
    },
    onError: (error, __, context) => {
      const message = 'Failed to rename this area. Please try again.'
      logException(error, { message })
      addErrorToast({ text: message })
      queryClient.setQueryData(listAreasQueryKey, context?.previousAreas)
      queryClient.setQueryData(areasQueryKey, context?.previousAdvancedAreas)
    },
    onSettled: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: [UNMAPPED_AREA_QUERY_KEY], exact: false })
      }, 500)
    }
  })

  return {
    setArea,
    setFreeExploration,
    renameArea,
    deleteArea,
    isDeleting,
    updateUseInUnmapped
  }
}

export default useAreaOfInterest
