import useCompetitorsMode from '@/hooks/useCompetitorsMode'
import Button from '@/components/atoms/button'
import Checkbox from '@/components/atoms/checkbox'
import { useTranslation } from 'react-i18next'

const CompetitorsEnabledButton = () => {
  const { t } = useTranslation()

  const { competitorsModeEnabled, hasCompetitorsFilter, setCompetitorsModeEnabled } =
    useCompetitorsMode()

  return (
    <>
      {hasCompetitorsFilter && (
        <Button
          css={{ fontWeight: '$regular', alignSelf: 'flex-end' }}
          onClick={() => setCompetitorsModeEnabled(!competitorsModeEnabled)}
          size={'small'}
          variant="flat-bordered"
        >
          <Checkbox
            value={'competitorsEnabled'}
            checked={competitorsModeEnabled}
            onCheckedChange={setCompetitorsModeEnabled}
          />
          {t('competitors')}
        </Button>
      )}
    </>
  )
}

export default CompetitorsEnabledButton
