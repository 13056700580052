import Bone from '@/components/atoms/bone'
import { styled } from '@/theme'
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'

export const AreaList = styled(DropdownMenuPrimitive.Group, {
  display: 'flex',
  flexDirection: 'column',
  gap: '$micro',
  overflowY: 'auto',
  p: 0,
  // px: '$xxxs',
  maxHeight: 300
})

export const AreasSkeleton = () => (
  <>
    <Bone css={{ width: '100%' }} />
    <Bone css={{ width: '100%' }} />
    <Bone css={{ width: '100%' }} />
    <Bone css={{ width: '100%' }} />
    <Bone css={{ width: '100%' }} />
  </>
)
