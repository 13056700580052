import useSegment from '@/hooks/useSegment'
import * as Popover from '@radix-ui/react-popover'
import {
  AssistantBallonImage,
  AssistantImageCircle,
  AssistantOptionButton,
  AssistantOptionsContainer
} from './Assistant.styles'

import BalloonSvg from '@/assets/drawings/ask_birdie.svg'
import Drawing from '@/assets/drawings/assistant_bird.png'
import SingingBirdDrawing from '@/assets/drawings/singing_bird_inclined.png'
import { useState } from 'react'
import usePrompts from './usePrompts'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import { shallow } from 'zustand/shallow'
import { useFeedbackSearchChatStore } from '@/store/useChatStore'
import useUser from '@/hooks/useUser'
import { PromptTemplate } from '@/utils/chat'
import { useTranslation } from 'react-i18next'
import useOpportunityStore from '@/store/useOpportunityStore'

interface Props {
  onOpenChange?: (open: boolean) => void
  open?: boolean
  opportunityPlan?: boolean
}

const AssistantFloatingButton = ({ onOpenChange, open, opportunityPlan }: Props) => {
  const { track } = useSegment()

  const { userPermissions, currentUser } = useUser()

  const [optionsOpen, setOptionsOpen] = useState(false)

  const prompts = usePrompts()

  const { t } = useTranslation()

  const currentInterestArea = useCurrentInterestAreaStore(
    state => state.currentInterestArea,
    shallow
  )
  const currentOpportunity = useOpportunityStore(state => state.currentOpportunity, shallow)

  const onAssistantClick = () => {
    onOpenChange?.(!open)

    if (currentOpportunity) {
      track('opportunity_assistant')
    } else if (currentInterestArea) {
      track('area_assistant')
    } else {
      track('exploration_assistant')
    }
  }

  const setInitialPrompt = useFeedbackSearchChatStore(state => state.setInitialPrompt)

  const onPromptClick = (prompt: PromptTemplate) => {
    setInitialPrompt(prompt)
    onOpenChange?.(true)
  }

  const speechBallonVisible = !open && !optionsOpen && !opportunityPlan

  if (!userPermissions.feedback.includes('chat') && currentUser) {
    return <></>
  }

  return (
    <Popover.Root onOpenChange={setOptionsOpen} open={optionsOpen}>
      <AssistantBallonImage
        alt={'A speech balloon written: "Ask Birdie!"'}
        aria-hidden={!speechBallonVisible}
        src={BalloonSvg}
        visible={speechBallonVisible}
      />
      <Popover.Trigger asChild>
        <AssistantImageCircle
          alt="A drawing of a blue bird"
          opportunityPlan={opportunityPlan}
          src={opportunityPlan ? SingingBirdDrawing : Drawing}
        />
      </Popover.Trigger>
      <Popover.Portal>
        <AssistantOptionsContainer align="center" opportunityPlan={opportunityPlan} side="right">
          {prompts.map(prompt => (
            <AssistantOptionButton key={prompt.prompt} onClick={() => onPromptClick(prompt)}>
              {prompt.name}
            </AssistantOptionButton>
          ))}
          <AssistantOptionButton onClick={onAssistantClick}>
            {t('askAQuestion')}
          </AssistantOptionButton>
        </AssistantOptionsContainer>
      </Popover.Portal>
    </Popover.Root>
  )
}

export default AssistantFloatingButton
