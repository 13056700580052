import { FilterRequests } from '@/types/filters'
import {
  CreateFilterPayload,
  FilterValuesPayload,
  FilterValuesStringPayload,
  SavedFilterData
} from '@/types/filters/FilterRequests'
import Request from './Request'
import RequestHandler, { RequestReturnType } from './RequestHandlers/NewRequest'
import { AxiosRequestConfig } from 'axios'
import { FeedbackListQueryParams } from '@/types/feedbacks/FeedbackRequests'
import BirdieRequest from './RequestHandlers/BirdieRequest'

export default class FiltersService {
  static PREFIX = '/filters'

  static async listAllFilters(): Promise<RequestReturnType<SavedFilterData[]>> {
    const [error, data] = await RequestHandler.get<FilterRequests.SavedFilterData[]>(this.PREFIX, {
      filter_type: 'basic'
    })

    if (error) return [error, undefined]
    return [undefined, data]
  }

  static async createFilter(payload: CreateFilterPayload) {
    return await RequestHandler.post<FilterRequests.SavedFilterData>(this.PREFIX, payload)
  }

  static async updateFilter(id: string, payload: CreateFilterPayload) {
    return await RequestHandler.put(`${this.PREFIX}/${id}`, payload)
  }

  static async deleteFilter(id: string) {
    return await RequestHandler.del(`${this.PREFIX}/${id}`)
  }

  static async listAllTemplates() {
    return await Request.get<FilterRequests.SavedFilterData[]>(`${this.PREFIX}/templates`)
  }

  static async getFilterByHash(id: string) {
    return await Request.get<FilterRequests.SavedFilterData>(`${this.PREFIX}/${id}`)
  }

  static async list() {
    return await Request.get<FilterRequests.FiltersResponse>('feedback/search/all-filters')
  }

  static async getDatetimeValues(payload: FilterValuesPayload) {
    return (
      await Request.get<FilterRequests.FilterValueDatetimeResponse>(
        'feedback/search/filter-values',
        payload
      )
    ).field_values
  }

  static async getNumberValues(payload: FilterValuesPayload) {
    return (
      await Request.get<FilterRequests.FilterValueNumberResponse>(
        'feedback/search/filter-values',
        payload
      )
    ).field_values
  }

  static async getStringValues(payload: FilterValuesStringPayload) {
    return (
      await Request.get<FilterRequests.FilterValueStringResponse>(
        'feedback/search/filter-values',
        payload
      )
    ).field_values
  }

  static async listOpportunitiesByFilterId(
    id: string,
    params: FeedbackListQueryParams,
    config?: AxiosRequestConfig<unknown>
  ) {
    return await RequestHandler.get<FilterRequests.OpportunityData[]>(
      `${this.PREFIX}/${id}/opportunity`,
      params,
      {
        ...config,
        paramsSerializer: {
          indexes: null
        }
      }
    )
  }

  static async listAreaOfInterest(
    params: FilterRequests.FiltersParams,
    config?: AxiosRequestConfig<unknown>
  ) {
    return await RequestHandler.get<FilterRequests.SavedFiltersResponse>(
      this.PREFIX,
      params,
      config
    )
  }

  static async listAreaOfInterestWithMetrics(
    payload: FilterRequests.ListAreaOfInterestPayload,
    config?: AxiosRequestConfig<unknown>
  ) {
    return await RequestHandler.post<FilterRequests.AreaOfInterestResponse>(
      this.PREFIX + '/areas',
      payload,
      config
    )
  }

  static async areaOfInterestTrendline(
    params: FilterRequests.FiltersTrendlineParams,
    config?: AxiosRequestConfig<unknown>
  ) {
    return await RequestHandler.get<FilterRequests.FiltersTrendlineResponse>(
      this.PREFIX + '/analytics/trendline',
      params,
      config
    )
  }

  static async setAreaAsFavorite(id: string) {
    return await RequestHandler.put(`${this.PREFIX}/${id}/favorite`)
  }

  static async removeFavoriteFromArea(id: string) {
    return await RequestHandler.del(`${this.PREFIX}/${id}/favorite`)
  }

  static async listOpportunitiesByAreaIdV2(
    id: string,
    params: FeedbackListQueryParams,
    config?: AxiosRequestConfig<unknown>
  ) {
    return await RequestHandler.get<FilterRequests.OpportunityStatsDataV2[]>(
      `${this.PREFIX}/v2/${id}/opportunity`,
      params,
      {
        ...config,
        paramsSerializer: {
          indexes: null
        }
      }
    )
  }

  static async filterSearch(
    params: FilterRequests.FilterSearchParams,
    config?: AxiosRequestConfig<unknown>,
    overrideAdvancedFromParams = false
  ) {
    const newParams = { ...params }
    if (!overrideAdvancedFromParams) {
      newParams.transform = true
    }

    return await RequestHandler.get<FilterRequests.FilterSearchResponse>(
      this.PREFIX + '/v2/search',
      newParams,
      {
        ...config,
        paramsSerializer: {
          indexes: null
        }
      }
    )
  }

  static async advancedFilterContext(
    payload: FilterRequests.FilterContextPayload,
    config?: AxiosRequestConfig<unknown>
  ) {
    return await BirdieRequest.post<FilterRequests.FilterContextResponse>(
      '/feed/feedbacks/search/advanced',
      payload,
      {
        ...config,
        paramsSerializer: {
          indexes: null
        }
      }
    )
  }

  static async multipleAdvancedFilterContext(
    payload: FilterRequests.MultipleFilterContextPayload,
    config?: AxiosRequestConfig<unknown>
  ) {
    return await RequestHandler.post<FilterRequests.MultipleFilterContextResponse>(
      this.PREFIX + '/batch',
      payload,
      {
        ...config,
        paramsSerializer: {
          indexes: null
        }
      }
    )
  }

  static async getUnmappedArea(config?: AxiosRequestConfig<unknown>) {
    return await RequestHandler.get<FilterRequests.FilterUnmappedAreaItem>(
      this.PREFIX + '/areas/unmapped',
      undefined,
      {
        ...config,
        paramsSerializer: {
          indexes: null
        }
      }
    )
  }

  static async linkOpportunityToAreas(
    params: {
      opportunityId: string
      areaIds: string[]
    },
    config?: AxiosRequestConfig<unknown>
  ) {
    return await RequestHandler.put<void>(
      `${this.PREFIX}/${params.opportunityId}/areas`,
      {
        area_ids: params.areaIds,
        sync: true
      },
      config
    )
  }

  static async unlinkAreaFromOpportunity(
    params: {
      opportunityId: string
      areaId: string
    },
    config?: AxiosRequestConfig<unknown>
  ) {
    return await RequestHandler.del<void>(
      `${this.PREFIX}/${params.areaId}/opportunity/${params.opportunityId}`,
      undefined,
      config
    )
  }

  static async mergedAreas(
    payload: FilterRequests.MergedAreasPayload,
    config?: AxiosRequestConfig<unknown>
  ) {
    return await RequestHandler.post<FilterRequests.MergedAreasResponse>(
      this.PREFIX + '/areas/merged',
      payload,
      config
    )
  }
}
