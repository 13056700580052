import useUser from '@/hooks/useUser'
import AudienceService from '@/services/AudienceService'
import { QueryFunctionContext, useInfiniteQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { FilterItem } from '@/types/filters/Filters'
import useAccountStringFilterSelection from './useAccountStringFilterSelection'
import useAdvancedFilters from '@/hooks/advancedFilters/useAdvancedFilters'
import useStringSelection from '@/hooks/useStringSelection'
import { GenericFilter } from '@/types/filters/AdvancedFilters'
import useCustomerUsersStringFilterSelection from '../Users/useCustomerUsersStringFilterSelection'
import { AccountRequestItem, UsersRequestItem } from '@/types/manage-customers'
import { makeUniqueArray } from '@/utils/array'
import { prefixFilterName } from '@/utils/filters'

interface Params {
  enabled: boolean
  filter: FilterItem
  searchQuery?: string
  newFeed?: boolean
  index?: number
  initialValues?: string[]
  customerRecordType: 'account' | 'user'
}

const useCustomerRecordIdFilters = ({
  enabled,
  filter,
  searchQuery,
  newFeed = false,
  index,
  initialValues = [],
  customerRecordType
}: Params) => {
  const { currentUser } = useUser()

  const { addFilter, removeFilter, updateFilter } = useAdvancedFilters()

  const queryFunction = async (params: QueryFunctionContext) => {
    const { pageParam, signal, queryKey } = params

    const { q, perPage } = queryKey[2] as { q: string; perPage: number }
    const request =
      customerRecordType === 'account' ? AudienceService.getAccounts : AudienceService.getUsers

    const [error, response] = await request(
      {
        orgId: currentUser?.organization_id ?? '',
        per_page: perPage ?? 50,
        cursor: pageParam,
        q,
        raw: true
      },
      signal
    )

    if (error) throw error

    return response
  }

  const keyPrefix = customerRecordType === 'account' ? 'accounts-filters' : 'users-filters'

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery({
    queryKey: [keyPrefix, 'id', { q: searchQuery, perPage: 20 }],
    queryFn: queryFunction,
    getNextPageParam: lastPage => lastPage?.nextPage,
    retry: false,
    refetchOnMount: false,
    enabled
  })

  const useFilterSelection =
    customerRecordType === 'account'
      ? useAccountStringFilterSelection
      : useCustomerUsersStringFilterSelection

  const { apply, hasChanges, isChecked, selectOption } = useFilterSelection({
    filterKey: filter.key,
    newFeed,
    initialValues
  })

  const {
    selectOption: advancedSelectOption,
    isChecked: advancedIsChecked,
    selectedOptions: advancedSelectedOptions
  } = useStringSelection(initialValues)

  const advancedHasChanges = advancedSelectedOptions.length > 0

  const prefix = customerRecordType + '.'

  const advancedApply = () => {
    const value = advancedSelectedOptions
    const type = customerRecordType === 'account' ? 'accounts-id' : 'users-id'
    const newFilter: GenericFilter = {
      type,
      name: prefixFilterName(prefix, filter.name),
      value: advancedSelectedOptions,
      path: filter.path ?? filter.name,
      status: 'valid'
    }

    if (index === undefined) {
      addFilter(newFilter)
      return
    }

    if (value.length === 0) {
      removeFilter({ index })
    }

    updateFilter({
      index,
      filter: newFilter
    })
  }

  const isAdvanced = newFeed

  const accessKey = customerRecordType === 'account' ? 'accounts' : 'users'
  const values = useMemo(
    () => data?.pages.flatMap(page => (page as never)[accessKey]) ?? [],
    [data, accessKey]
  ) as (AccountRequestItem | UsersRequestItem)[]

  const options = useMemo(() => {
    return makeUniqueArray(
      'id',
      values.map(customer => {
        if (!filter.path)
          return {
            id: customer.id,
            text: customer.id
          }

        const pathArray = filter.path.split('.')

        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        let currentPath: any = customer
        pathArray.forEach(entry => {
          if (typeof currentPath !== 'object') return
          const newValue = currentPath[entry]
          if (!newValue) return
          currentPath = newValue
        })

        const text = currentPath as string
        return {
          id: customer.id,
          text
        }
      })
    )
  }, [values, filter.path])

  const isShowMoreDisabled = isLoading || isFetchingNextPage

  return {
    options,
    isLoading,
    isFetchingNextPage,
    isShowMoreDisabled,
    fetchNextPage,
    hasNextPage,
    apply: isAdvanced ? advancedApply : apply,
    hasChanges: isAdvanced ? advancedHasChanges : hasChanges,
    isChecked: isAdvanced ? advancedIsChecked : isChecked,
    selectOption: isAdvanced ? advancedSelectOption : selectOption
  }
}

export default useCustomerRecordIdFilters
