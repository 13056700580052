import useSegment from '@/hooks/useSegment'
import { useFiltersStore, useFeedbackStore, useKeywordsStore, useUIStore } from '@/store'
import useSearchInputStore from '@/store/useSearchInputStore'
import { shallow } from 'zustand/shallow'
import { useArchivedFeedbackStore, useChatFeedbackStore } from '@/store/useFeedbackStore'
import { CSS } from '@/theme'
import { FiltersContainer, HeaderContainer } from './HeaderFilters.styles'
import SearchFilter from './SearchFilter'
import PageOptions from './PageOptions'
import useTopicsStore from '@/store/useTopicsStore'
import NotificationFilterBanner from './NotificationFilterBanner'
import useFilters from '@/hooks/filters/useFilters'
import { useSearchParams } from 'react-router-dom'
import { SAVED_FILTER_NAME_QUERY_PARAM } from '@/utils/filters'
import { useFeedFiltersStore } from '@/store/useFiltersStore'
import Text from '@/components/atoms/text'
import useAreaOfInterestStore, { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import SavedFiltersArea from '../saved-filters/SavedFiltersArea'
import { useEffect, useState } from 'react'
import useFiltersCount from '@/hooks/filters/useFiltersCount'
import FiltersButton from './FiltersButton'
import FeedbackService from '@/services/FeedbackService'
import FlexContainer from '@/components/atoms/flex-container'
import DateFilter from './DateFilter'
import useDateFilterStore from '@/store/useFiltersStore/useDateFilterStore'
import { camelToSnakeCase } from '@/utils/letterCase'
import { Period } from '@/types/periods'
import useOpportunityStore from '@/store/useOpportunityStore'
import ExplorationBreadcrumb from '../exploration/ExplorationBreadcrumb'
import ExplorationBackButton from '../exploration/ExplorationBackButton'

interface Props {
  archived?: boolean
  css?: CSS
  searchPlaceholder?: string
  newFeed?: boolean
  title?: string
  opportunityPlan?: boolean
}

function FiltersHeader({
  archived,
  searchPlaceholder = 'Search for feedback',
  newFeed = false,
  title,
  opportunityPlan,
  ...props
}: Props) {
  const { track } = useSegment()

  const resetKeywords = useKeywordsStore(state => state.reset)
  const resetFeedbacks = useFeedbackStore(state => state.reset)
  const resetArchivedFeedbacks = useArchivedFeedbackStore(state => state.reset)
  const resetTopics = useTopicsStore(state => state.reset)
  const resetChatFeedbacks = useChatFeedbackStore(state => state.reset)
  const archivedCount = useArchivedFeedbackStore(state => state.data.length, shallow)

  const { totalCount } = useFiltersCount({ newFeed })

  const isFiltersOpen = useUIStore(state => state.isFiltersOpen)
  const toggleFiltersOpen = useUIStore(state => state.toggleFiltersOpen)
  const openClassification = useUIStore(state => state.openClassification)

  const useStore = newFeed ? useFeedFiltersStore : useFiltersStore

  const { search, areaSearch, filtersByURL, dateRange, datePeriod } = useStore(
    state => ({
      search: state.search,
      areaSearch: state.areaSearch,
      filtersByURL: state.filtersByURL,
      dateRange: state.dateRange,
      datePeriod: state.datePeriod
    }),
    shallow
  )

  const dateFilter = useDateFilterStore(state => state, shallow)
  const appliedDateRange = newFeed ? dateFilter.dateRange : dateRange
  const appliedDatePeriod = newFeed ? dateFilter.datePeriod : datePeriod

  const setDateRange = useStore(state => state.setDateRange)
  const applyDateFilter = newFeed ? dateFilter.setDateFilter : setDateRange

  const setSearch = useStore(state => state.setSearch)
  const setSearchArea = useStore(state => state.setAreaSearch)
  const setOrderBy = useStore(state => state.setOrderBy)

  const currentInterestArea = useCurrentInterestAreaStore(
    state => state.currentInterestArea,
    shallow
  )

  const setCurrentOpportunity = useOpportunityStore(state => state.setCurrentOpportunity)

  const onExitOpportunity = () => {
    setCurrentOpportunity(undefined)
  }

  const editMode = useAreaOfInterestStore(state => state.editMode)

  let searchValue = search
  let setSearchValue = setSearch

  if (newFeed) {
    searchValue = !currentInterestArea || editMode ? search : areaSearch
    setSearchValue = !currentInterestArea || editMode ? setSearch : setSearchArea
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: should happen once
  useEffect(() => {
    return () => {
      setSearchArea('')
    }
  }, [])

  const { textInput } = useSearchInputStore(
    state => ({
      textInput: state.textInput
    }),
    shallow
  )

  const resetAll = () => {
    resetFeedbacks()
    resetArchivedFeedbacks()
    resetKeywords()
    resetChatFeedbacks()
    resetTopics()
  }

  const [isSearchInvalid, setIsSearchInvalid] = useState(false)
  const invalidSearchError = isSearchInvalid ? 'Invalid text.' : undefined

  async function onSearch() {
    const newSearch = textInput.trim()
    const isNewSearch = newSearch !== searchValue
    if (isNewSearch) {
      track('explore_user_feedback_search')

      if (newSearch !== '') {
        const [error] = await FeedbackService.validateQuery(newSearch)
        if (error) {
          setIsSearchInvalid(true)
          return
        }
      }

      setSearchValue(newSearch)
      setOrderBy(!newSearch.length ? 'posted_at' : 'relevance')

      if (!newFeed) {
        resetAll()
      }
    } else {
      setIsSearchInvalid(false)
    }
  }

  function onClear() {
    setSearchValue('')
    setOrderBy('posted_at')

    if (!newFeed) {
      resetAll()
    }
  }

  function onOpen() {
    track('explore_user_date_range_filter-open')
  }

  function onApply() {
    track('explore_user_date_range_filter_apply')
  }

  function onChangePeriod(period: Period) {
    track('explore_user_date_range_filter-options', { data_type: camelToSnakeCase(period) })
  }

  function onSelectCustom() {
    track('explore_user_date_range_filter-options', { data_type: 'custom' })
  }

  function onSelectAllTime() {
    track('explore_user_date_range_filter-clear')
  }

  function onCalendarClose() {
    track('explore_user_date_range_filter-cancel')
  }

  const { clearFiltersByURL } = useFilters()
  function onDismiss() {
    clearFiltersByURL()
  }

  const [searchParams] = useSearchParams()

  const onFiltersClick = () => {
    toggleFiltersOpen()
    openClassification()

    if (!newFeed) return

    if (currentInterestArea) {
      track('area_in_filter')
    } else {
      track('exploration_in_filter')
    }
  }

  if (archived && !archivedCount && !searchValue.length) return <></>

  if (archived)
    return (
      <SearchFilter
        onClear={onClear}
        onSearch={onSearch}
        searchPlaceholder={searchPlaceholder}
        storedValue={search}
      />
    )

  if (filtersByURL) {
    track('explore_user_access_from_summary_notification')

    const filtersDateRange = filtersByURL.filter?.posted_at
    const startDate = filtersDateRange?.gte ? new Date(filtersDateRange.gte) : null
    const endDate = filtersDateRange?.gte ? new Date(filtersDateRange.gte) : null

    const dateRange: RangeValue<Date> | null =
      startDate && endDate ? { start: startDate, end: endDate } : null

    const savedFilterName = searchParams.get(SAVED_FILTER_NAME_QUERY_PARAM)

    return (
      <HeaderContainer>
        <NotificationFilterBanner
          dateRange={dateRange}
          onDismiss={onDismiss}
          savedFilterName={savedFilterName ?? ''}
        />
      </HeaderContainer>
    )
  }

  return (
    <HeaderContainer {...props} opportunityPlan={opportunityPlan}>
      <FlexContainer alignItems="start" direction="column" fullWidth gap="micro">
        <ExplorationBreadcrumb />

        <FlexContainer alignItems="start" fullWidth gap="micro">
          <ExplorationBackButton onClick={onExitOpportunity} />
          <FiltersContainer alignItems="center">
            {!!title && (
              <Text
                as="h1"
                color="neutralLowPure"
                css={{ mr: '$sm' }}
                fontSize="xxs"
                fontWeight="regular"
                lineHeight="default"
              >
                {title}
              </Text>
            )}
            {!newFeed && <SavedFiltersArea />}
            <FiltersButton
              count={totalCount}
              isOpen={isFiltersOpen}
              onClick={onFiltersClick}
              triggerCSS={{ bc: opportunityPlan ? '$neutralHighLight' : undefined }}
            />
            <DateFilter
              appliedPeriod={appliedDatePeriod}
              appliedRange={appliedDateRange}
              key={JSON.stringify(dateRange)}
              onApply={onApply}
              onCalendarClose={onCalendarClose}
              onChangePeriod={onChangePeriod}
              onOpen={onOpen}
              onSelectAllTime={onSelectAllTime}
              onSelectCustom={onSelectCustom}
              setDateRange={applyDateFilter}
              triggerCss={opportunityPlan ? { bc: '$neutralHighLight' } : undefined}
            />
            <SearchFilter
              error={invalidSearchError}
              onClear={onClear}
              onSearch={onSearch}
              searchPlaceholder={searchPlaceholder}
              storedValue={searchValue}
            />
          </FiltersContainer>
          <PageOptions />
        </FlexContainer>
      </FlexContainer>
    </HeaderContainer>
  )
}

export default FiltersHeader
