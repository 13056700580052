import useSegment from '@/hooks/useSegment'
import MetricsTrendlineCard from './MetricsTrendlineCard'
import { AllMetricsKey } from '@/types/metrics'
import { BreakdownOption } from '@/types/time-series/TimeSeries'
import { useMemo } from 'react'

const OverallMetricsTrendline = () => {
  const breakdownList: BreakdownOption[] = useMemo(
    () => [
      'org',
      'areas',
      'opportunity',
      'sentiment',
      'intention',
      'kind',
      'satisfaction',
      'feedbackDetail',
      'customField',
      'account',
      'user',
      'date'
    ],
    []
  )

  const { track } = useSegment()
  const trackMetric = (metric: AllMetricsKey) => {
    track('areas_and_opps_trendline_metrics', {
      selected_metric: metric
    })
  }

  const trackCreateAnnotation = () => {
    track('areas_and_opps_trendline_add_annotation')
  }

  const trackCopyTable = () => {
    track('areas_and_opps_trendline_copy_data_table')
  }

  return (
    <MetricsTrendlineCard
      annotationType="organization"
      breakdownList={breakdownList}
      defaultOption="areas"
      onCopyTable={trackCopyTable}
      trackCreateAnnotation={trackCreateAnnotation}
      trackMetric={trackMetric}
    />
  )
}
export default OverallMetricsTrendline
