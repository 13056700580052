import { CSS, styled } from '@/theme'
import * as Progress from '@radix-ui/react-progress'
import FlexContainer from '../flex-container'

const ProgressRoot = styled(Progress.Root, {
  position: 'relative',
  overflow: 'hidden',
  background: '$brandPrimaryLight',
  borderRadius: 9999,
  width: '100%',
  height: 8,

  // Fix overflow clipping in Safari
  // https://gist.github.com/domske/b66047671c780a238b51c51ffde8d3a0
  transform: 'translateZ(0)'
})

const ProgressIndicator = styled(Progress.Indicator, {
  width: '100%',
  height: '100%',
  background: '$brandPrimaryPure',
  transition: 'transform 660ms cubic-bezier(0.65, 0, 0.35, 1)'
})

interface IndicatorProps {
  css?: CSS
  asChild?: boolean
}

interface ProgressBarProps extends Progress.ProgressProps {
  css?: CSS
  indicatorProps?: IndicatorProps
}

const Bar = ({ css, indicatorProps, value, max = 100, ...props }: ProgressBarProps) => {
  const normalizedValue = ((value ?? 0) / max) * 100

  return (
    <ProgressRoot css={css} max={max} value={value} {...props}>
      <ProgressIndicator
        {...indicatorProps}
        style={{ transform: `translateX(-${100 - normalizedValue}%)` }}
      />
    </ProgressRoot>
  )
}

const Container = styled(FlexContainer, {
  flexDirection: 'column',
  width: '100%',
  gap: '$micro',
  transition: 'height .2s, opacity .2s',

  variants: {
    visible: {
      true: {
        opacity: 1,
        height: 32
      },
      false: {
        opacity: 0,
        height: 0
      }
    }
  }
})

const TextParagraph = styled('p', {
  fontSize: '$micro',
  color: '$neutralLowLight',

  em: {
    fontStyle: 'normal',
    fontWeight: '$regular',
    color: '$brandPrimaryMedium'
  }
})

const ProgressBar = {
  Bar,
  Container,
  TextParagraph
}

export default ProgressBar
