import MetricIndicator from './MetricIndicator'
import { colors } from '@/theme'
import Bone from '@/components/atoms/bone'
import useFeedQueryParams from '@/hooks/feedback/new/useFeedQueryParams'
import FlexContainer from '@/components/atoms/flex-container'
import useExplorationMetrics from '@/hooks/metrics/useExplorationMetrics'
import { MetricKey } from '@/types/metrics'
import { useCallback, useMemo } from 'react'
import i18n from '../../../../plugins/i18n/i18n'
import useOpportunityStore from '@/store/useOpportunityStore'

const mapMetricToMetricName: Partial<Record<MetricKey, string>> = {
  support_ticket: i18n.t('shareOfTotal'),
  transcript: i18n.t('shareOfTotal'),
  review: i18n.t('rating'),
  issue: i18n.t('shareOfTotal'),
  social_media_post: i18n.t('shareOfTotal')
}

const ExplorationIndicators = () => {
  const currentOpportunity = useOpportunityStore(state => state.currentOpportunity)

  const { queryParams } = useFeedQueryParams()

  // const { advancedAreas } = useAdvancedAreasOnly({ enabled: true })

  const filters = useMemo(() => {
    return {
      ...queryParams,
      opportunity_id: currentOpportunity?.id
    }
  }, [queryParams, currentOpportunity])

  const { metrics, isLoading, satisfactionCountItems } = useExplorationMetrics({
    filters: filters ? [{ ...filters }] : [],
    useShare: true
  })

  const getSatisfactionMetricValue = useCallback(
    (metricKey: MetricKey) => {
      const metricItem = satisfactionCountItems.find(metric => metric.metricKey === metricKey)
      if (!metricItem) return undefined

      return metricItem.currentValue
    },
    [satisfactionCountItems]
  )

  if (isLoading) {
    return (
      <FlexContainer alignItems="stretch" gap="xs">
        <Bone css={{ height: 132 }} />
        <Bone css={{ height: 132 }} />
        <Bone css={{ height: 132 }} />
      </FlexContainer>
    )
  }

  return (
    <FlexContainer gap={metrics.length > 4 ? 'xxxs' : 'xs'}>
      {metrics.map(metric => (
        <MetricIndicator
          expanded={metrics.length < 3}
          key={metric.metricKey}
          countMetricValue={getSatisfactionMetricValue(metric.metricKey)}
          {...metric}
          color={colors.brandPrimaryPure}
          metricName={mapMetricToMetricName[metric.metricKey] ?? metric.label}
        />
      ))}
    </FlexContainer>
  )
}

export default ExplorationIndicators
