import FiltersService from '@/services/FiltersService'
import { useFeedbackStore, useFiltersStore, useKeywordsStore, useUIStore } from '@/store'
import useSavedFiltersStore from '@/store/useSavedFiltersStore'
import useToastMessageStore from '@/store/useToastMessageStore'
import { SavedFilterData } from '@/types/filters/FilterRequests'
import { useCallback, useMemo } from 'react'
import { Link, useLocation, useSearchParams } from 'react-router-dom'
import { shallow } from 'zustand/shallow'
import useClassification from './useClassification'
import useSavedFiltersParsers from './useSavedFiltersParsers'
import { useArchivedFeedbackStore } from '@/store/useFeedbackStore'
import usePeriods from './usePeriods'
import useUser from './useUser'
import useSegment from './useSegment'
import useFiltersCount from './filters/useFiltersCount'
import {
  FilterDatetimeValue,
  SavedFilter,
  SavedFilterContent,
  SavedFilterContentAdvanced,
  SavedFilterTypeId
} from '@/types/filters/Filters'
import { useFeedbacks } from './feedback/useFeedbacks'
import { useKeywords } from './useKeywords/useKeywords'
import {
  getFromDatetimeFilters,
  getFromNumericFilters,
  getFromStringFilters,
  getTopicGroups
} from '@/utils/filters'
import { FilterRequests } from '@/types/filters'
import NotificationsService from '@/services/NotificationsService'
import { ThemeItemData } from '@/types/classification/Theme'
import useTopicsStore from '@/store/useTopicsStore'
import { useTopics } from './useTopics/useTopics'
import { useFeedFiltersStore } from '@/store/useFiltersStore'
import useAreaOfInterestStore, { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import useLogging from './useLogging'
import { BaseInterestArea } from '@/types/area/AreaOfInterest'
import { UNMAPPED_AREA_QUERY_KEY } from '@/hooks/areaOfInterest/useUnmappedAreaQuery'
import { BASE_AREAS_KEY } from './areaOfInterest/useAreaOfInterest'
import useAdvancedFilters from './advancedFilters/useAdvancedFilters'
import { advancedFilterToContent } from '@/utils/advancedFilter'
import useAdvancedFiltersStore from '@/store/useFiltersStore/useAdvancedFiltersStore'
import useAlerts from './alerts/useAlerts'
import AreaService from '@/services/AreaService'
import { AreaRequests } from '@/types/area'
import { AREAS_KEY_PREFIX } from './areaOfInterest/useAllAreasQuery'

export const FILTER_HASH_KEY = 'filter_hash'

const mapSavedFilterData = (savedFilterData: SavedFilterData): SavedFilter => ({
  filterId: savedFilterData.filter_id,
  organizationId: savedFilterData.organization_id,
  name: savedFilterData.name,
  hash: savedFilterData.hash,
  content: savedFilterData.content,
  createdBy: savedFilterData.created_by,
  icon: savedFilterData.icon
})

interface Params {
  newFeed?: boolean
}

const useSavedFilters = ({ newFeed = false }: Params = { newFeed: false }) => {
  const { filters: advancedFilters, context } = useAdvancedFilters()

  const transformIntoAreaFilters = useAdvancedFiltersStore(state => state.transformIntoAreaFilters)

  const { track } = useSegment()
  const { logException } = useLogging({ context: 'saved-filters' })
  const { currentUser } = useUser()

  const useStore = newFeed ? useFeedFiltersStore : useFiltersStore

  const selectThemes = useStore(state => state.selectThemes)
  const selectTopic = useStore(state => state.selectTopics)

  const setNumericFilter = useStore(state => state.setNumericFilter)
  const setNumericOption = useStore(state => state.setNumericOption)
  const setDatetimeFilter = useStore(state => state.setDatetimeFilter)
  const selectStringFilter = useStore(state => state.selectStringFilter)
  const setBooleanFilter = useStore(state => state.setBooleanFilter)
  const setTextFilter = useStore(state => state.setTextFilter)

  const setDateRange = useStore(state => state.setDateRange)
  const setSearch = useStore(state => state.setSearch)
  const setAreaSearch = useStore(state => state.setAreaSearch)
  const setUnclassifiedTopics = useStore(state => state.setUnclassifiedTopics)
  const selectUngroupedTopics = useStore(state => state.selectUngroupedTopics)
  const clearAndApplyFilters = useStore(state => state.clearAndApply)
  const applyFilters = useStore(state => state.apply)

  const checkAccount = useStore(state => state.checkAccount)
  const changeAccountDateFilter = useStore(state => state.changeAccountDateFilter)
  const setAccountNumericFilter = useStore(state => state.setAccountNumericFilter)
  const setAccountNumericOption = useStore(state => state.setAccountNumericOption)
  const checkUser = useStore(state => state.checkUser)

  const resetFeedbacks = useFeedbackStore(state => state.reset)
  const resetArchivedFeedbacks = useArchivedFeedbackStore(state => state.reset)
  const resetKeywords = useKeywordsStore(state => state.reset)
  const resetTopics = useTopicsStore(state => state.reset)

  const setShowFullScreenLoader = useUIStore(state => state.setShowFullScreenLoader)

  const { load: loadFeedbacks } = useFeedbacks({ store: 'explore' })
  const { load: loadKeywords } = useKeywords()
  const { load: loadTopics } = useTopics()

  const { getPeriod } = usePeriods()

  const {
    parseAllToContent,
    getThemesFromContent,
    getTopicsFromContent,
    getUnclassifiedTopicsFromContent,
    getUngroupedTopicsFromContent,
    getDateRangeFromContent,
    getSearchFilterFromContent,
    getStringFiltersFromContent,
    getNumericFiltersFromContent,
    getDatetimeFiltersFromContent,
    getBooleanFiltersFromContent,
    getTextFiltersFromContent,
    getAccountsFromContent,
    getUsersFromContent,
    getAccountDateFiltersFromContent,
    getAccountNumericFiltersFromContent
  } = useSavedFiltersParsers({ newFeed })
  const filterName = useSavedFiltersStore(state => state.filterName, shallow)

  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const addLoadingToast = useToastMessageStore(state => state.addLoadingToast)
  const removeToast = useToastMessageStore(state => state.removeToast)
  // const addWarningToast = useToastMessageStore(state => state.addWarningToast)

  // const { selectedFilters } = useSelectedFilters()
  const { totalCount } = useFiltersCount({ newFeed })
  const { period, search } = useStore(
    state => ({ period: state.datePeriod, search: state.search }),
    shallow
  )

  const [searchParams, setSearchParams] = useSearchParams()
  const { pathname } = useLocation()

  const currentSavedFilter = useSavedFiltersStore(state => state.currentSavedFilter, shallow)
  const setCurrentSavedFilter = useSavedFiltersStore(state => state.setCurrentSavedFilter)
  const setSavedFilters = useSavedFiltersStore(state => state.setSavedFilters)
  const setFilterName = useSavedFiltersStore(state => state.setFilterName)

  const setCurrentInterestArea = useCurrentInterestAreaStore(state => state.setCurrentInterestArea)
  const currentAreaOfInterest = useCurrentInterestAreaStore(state => state.currentInterestArea)
  const setEditMode = useAreaOfInterestStore(state => state.setEditMode)

  const { checkAndUpdateAreaAlert } = useAlerts({ enabled: true })

  const { themes, topics, verifyAllTopicAndThemes } = useClassification()

  const removeHashFromUrl = useCallback(() => {
    searchParams.delete(FILTER_HASH_KEY)
    setSearchParams(searchParams)
  }, [searchParams, setSearchParams])

  const onStartBlankFilter = (clearFilters = true) => {
    setCurrentSavedFilter(undefined)

    removeHashFromUrl()

    if (clearFilters) {
      track('explore_user_saved_view_applied', { filter_type: 'start_blank' })

      if (!newFeed) {
        resetKeywords()
        resetFeedbacks()
        resetArchivedFeedbacks()
        resetTopics()
      }

      const period = getPeriod('3months')
      if (period && period.range) {
        setDateRange(period.range, '3months')
      }
      setSearch('')

      clearAndApplyFilters(true)
    }
  }

  const { dateRange, datePeriod } = useStore(
    state => ({
      dateRange: state.dateRange,
      datePeriod: state.datePeriod
    }),
    shallow
  )

  const applyFilterContent = useCallback(
    (
      content: SavedFilterContent[] | [SavedFilterContentAdvanced],
      ignoreDate = false,
      allThemes?: ThemeItemData[]
    ) => {
      if (content[0]?.key === 'advanced') return

      const savedFilterContent = content as SavedFilterContent[]

      const currentDateRange = dateRange
      const currentDatePeriod = datePeriod

      clearAndApplyFilters(true)
      if (!newFeed) {
        resetKeywords()
        resetFeedbacks()
        resetArchivedFeedbacks()
        resetTopics()

        const { productAreaThemesIds, otherThemesIds, hasMissingThemes } = getThemesFromContent(
          savedFilterContent,
          allThemes
        )

        selectThemes({ themeIds: productAreaThemesIds, topicCategory: 'PRODUCT_AREA' })
        selectThemes({ themeIds: otherThemesIds, topicCategory: 'OTHER' })

        const { productAreaTopicIds, otherTopicIds, hasMissingTopics } = getTopicsFromContent(
          savedFilterContent,
          allThemes
        )
        selectTopic({ topicIds: productAreaTopicIds, topicCategory: 'PRODUCT_AREA' })
        selectTopic({ topicIds: otherTopicIds, topicCategory: 'OTHER' })

        if (themes.length && hasMissingThemes) {
          // addWarningToast({
          //   text: 'This view has missing themes that will not be applied. Please update the view.',
          //   duration: 4500
          // })
        }

        if (topics.length && hasMissingTopics) {
          // addWarningToast({
          //   text: 'This view has missing topics that will not be applied. Please update the view.',
          //   duration: 4500
          // })
        }

        const unclassifiedTopics = getUnclassifiedTopicsFromContent(savedFilterContent)
        setUnclassifiedTopics(unclassifiedTopics)

        const ungroupedTopics = getUngroupedTopicsFromContent(savedFilterContent)
        selectUngroupedTopics({ topics: ungroupedTopics })
      }

      const dateRangeFilter = getDateRangeFromContent(savedFilterContent)
      if (dateRangeFilter && !ignoreDate) {
        const { range, period } = dateRangeFilter
        setDateRange(range, period)
      }

      if (ignoreDate) {
        setDateRange(currentDateRange, currentDatePeriod)
      }

      setAreaSearch('')

      const searchFilter = getSearchFilterFromContent(savedFilterContent)
      setSearch(searchFilter)

      const stringFilters = getStringFiltersFromContent(savedFilterContent)
      stringFilters.forEach(stringFilter => {
        selectStringFilter(stringFilter)
      })

      const numericFilters = getNumericFiltersFromContent(savedFilterContent)
      numericFilters.forEach(numericFilter => {
        setNumericOption({
          key: numericFilter.key,
          name: numericFilter.name,
          type: numericFilter.type,
          option: numericFilter.option
        })
        setNumericFilter({
          key: numericFilter.key,
          name: numericFilter.name,
          type: numericFilter.type,
          value: numericFilter.value
        })
      })

      const datetimeFilters = getDatetimeFiltersFromContent(savedFilterContent)
      datetimeFilters.forEach(datetimeFilter => {
        setDatetimeFilter(datetimeFilter)
      })

      const booleanFilters = getBooleanFiltersFromContent(savedFilterContent)
      booleanFilters.forEach(booleanFilter => {
        setBooleanFilter(booleanFilter)
      })

      const textFilters = getTextFiltersFromContent(savedFilterContent)
      textFilters.forEach(textFilters => {
        setTextFilter(textFilters)
      })

      const accountStringFilters = getAccountsFromContent(savedFilterContent)
      accountStringFilters.forEach(accountFilter => {
        checkAccount({
          checked: true,
          filterName: accountFilter.key,
          value: accountFilter.values,
          apply: true
        })
      })

      const accountNumericFilters = getAccountNumericFiltersFromContent(savedFilterContent)
      accountNumericFilters.forEach(numericFilter => {
        setAccountNumericOption({
          key: numericFilter.key,
          name: numericFilter.name,
          type: numericFilter.type,
          option: numericFilter.option
        })
        setAccountNumericFilter({
          key: numericFilter.key,
          name: numericFilter.name,
          type: numericFilter.type,
          value: numericFilter.value
        })
      })

      const accountDateContent = getAccountDateFiltersFromContent(savedFilterContent)
      accountDateContent.forEach(accountDate => {
        changeAccountDateFilter({ key: accountDate.key, value: accountDate.value })
      })

      const usersContent = getUsersFromContent(savedFilterContent)
      usersContent.forEach(userFilter => {
        checkUser({
          checked: true,
          filterName: userFilter.key,
          value: userFilter.values,
          apply: true
        })
      })

      applyFilters()
    },
    [
      applyFilters,
      checkAccount,
      checkUser,
      clearAndApplyFilters,
      setAccountNumericFilter,
      changeAccountDateFilter,
      datePeriod,
      dateRange,
      getAccountDateFiltersFromContent,
      getAccountNumericFiltersFromContent,
      getAccountsFromContent,
      getBooleanFiltersFromContent,
      getDateRangeFromContent,
      getDatetimeFiltersFromContent,
      getNumericFiltersFromContent,
      getSearchFilterFromContent,
      getStringFiltersFromContent,
      getTextFiltersFromContent,
      getThemesFromContent,
      getTopicsFromContent,
      getUnclassifiedTopicsFromContent,
      getUngroupedTopicsFromContent,
      getUsersFromContent,
      newFeed,
      resetArchivedFeedbacks,
      resetFeedbacks,
      resetKeywords,
      resetTopics,
      selectStringFilter,
      selectTopic,
      selectThemes,
      selectUngroupedTopics,
      setAccountNumericOption,
      setAreaSearch,
      setBooleanFilter,
      setDateRange,
      setDatetimeFilter,
      setNumericFilter,
      setNumericOption,
      setSearch,
      setTextFilter,
      setUnclassifiedTopics,
      themes.length,
      topics.length
    ]
  )

  const applySavedFilter = useCallback(
    (filter: SavedFilter, allThemes?: ThemeItemData[]) => {
      clearAndApplyFilters(true)

      applyFilterContent(filter.content as SavedFilterContent[], false, allThemes)

      const filterType = 'custom'
      track('explore_user_saved_view_applied', { is_template: false, filter_type: filterType })

      setTimeout(() => {
        if (currentSavedFilter?.filterId === filter.filterId) {
          if (pathname.includes('feedback')) loadFeedbacks(undefined, true)
          if (['topics', 'keywords'].find(page => pathname.includes(page))) loadKeywords(true)
          if (pathname.includes('topic-management')) loadTopics(true)
        }
      }, 100)

      setSearchParams({ [FILTER_HASH_KEY]: filter.hash })
    },
    [
      currentSavedFilter,
      clearAndApplyFilters,
      loadFeedbacks,
      loadKeywords,
      loadTopics,
      pathname,
      setSearchParams,
      track,
      applyFilterContent
    ]
  )

  const fetchFilters = useCallback(
    async (justUpdatedContent?: SavedFilterContent[]) => {
      const [savedFiltersResponse, [, notificationData]] = await Promise.all([
        FiltersService.listAllFilters(),
        NotificationsService.getNotifications()
      ])

      const [savedFiltersError, savedFiltersData] = savedFiltersResponse

      if (savedFiltersError) {
        const message = 'Failed to fetch saved filters'
        logException(savedFiltersError, { message })
        addErrorToast({ text: message })
        return
      }

      const filters: SavedFilter[] = savedFiltersData.map(filterData => {
        const notificationFound = notificationData?.find(
          notification =>
            notification.id === filterData.filter_id || notification.description === filterData.name
        )

        if (currentSavedFilter?.name === filterData.name) {
          setCurrentSavedFilter({
            ...currentSavedFilter,
            content: justUpdatedContent ?? currentSavedFilter.content,
            notification: notificationFound
          })
        }

        return {
          ...mapSavedFilterData(filterData),
          notification: notificationFound
        }
      })

      setSavedFilters(filters)
    },
    [currentSavedFilter, addErrorToast, logException, setCurrentSavedFilter, setSavedFilters]
  )

  const invalidViewMessage = useCallback(() => {
    let message = ''
    if (!filterName.length) message = 'type a name for the filters to save.'
    else if (totalCount === 0 && !search.length) message = 'apply at least one filter to save.'

    if (!message) {
      addErrorToast({ text: 'Unable to save.' })
    }

    addErrorToast({ text: `You need to ${message}` })
  }, [filterName, totalCount, search, addErrorToast])

  const handleError = useCallback(
    (error: Error) => {
      console.error(error)
      // if ((error as Error).message === 'filter_already_existent') {
      //   addErrorToast({ text: 'A filter with same name already exists.' })
      //   return
      // }
      const message =
        (error as unknown as RequestError).details?.message ??
        error.message ??
        'Failed to save your filters.'
      addErrorToast({ text: message })
    },
    [addErrorToast]
  )

  const createViewAndApply = useCallback(
    async (content: SavedFilterContent[], name?: string) => {
      const [error, created] = await FiltersService.createFilter({
        name: name || filterName,
        content,
        filter_type_id: SavedFilterTypeId.Basic
      })

      if (error) {
        const message = 'Failed to save your filters.'
        logException(error, { message })
        addErrorToast({ text: message })
        throw error
      }

      const newFilter: SavedFilter = mapSavedFilterData(created)
      setCurrentSavedFilter(newFilter)
      setSearchParams({ [FILTER_HASH_KEY]: created.hash })

      track('explore_user_saved_view_created')
      return newFilter
    },
    [filterName, addErrorToast, logException, setCurrentSavedFilter, setSearchParams, track]
  )

  const queryClient = useQueryClient()
  const { mutate: createAreaAndApply } = useMutation({
    mutationKey: ['create-interest-area'],
    mutationFn: async ([content, name]: [[SavedFilterContentAdvanced], string?]) => {
      const [error, created] = await AreaService.createArea({
        name: name || filterName,
        content,
        advanced: true
      })

      if (error) throw error

      return created
    },
    onMutate: () => {
      addLoadingToast({ text: 'Saving filters...', id: 'create-interest-area' })
    },
    onError: error => {
      removeToast('create-interest-area')
      handleError(error as Error)
    },
    onSuccess: (data, [content]) => {
      removeToast('create-interest-area')
      addSuccessToast({ text: 'Filters successfully saved.' })

      const newArea: BaseInterestArea = {
        id: data.area_id,
        filterId: data.filter_id,
        name: data.name,
        content,
        context: context ?? undefined,
        opportunityCount: 0,
        advanced: true,
        useInUnmappedArea: data.is_mapped ?? false,
        createdBy: data.created_by
      }

      setCurrentInterestArea(newArea)

      transformIntoAreaFilters()

      queryClient.invalidateQueries({ queryKey: [AREAS_KEY_PREFIX] })
      queryClient.invalidateQueries({ queryKey: ['area-of-interest-trendline'] })
      queryClient.invalidateQueries({ queryKey: [BASE_AREAS_KEY] })

      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: [UNMAPPED_AREA_QUERY_KEY], exact: false })
      }, 500)

      return newArea
    }
  })

  const { mutate: updateAreaMutate, isLoading: isUpdatingArea } = useMutation({
    mutationKey: ['edit-interest-area'],
    mutationFn: async (newArea: BaseInterestArea) => {
      const [error] = await AreaService.updateArea(newArea.id, {
        filter_id: newArea.filterId,
        name: newArea.name,
        content: newArea.content as [SavedFilterContentAdvanced],
        advanced: true
      })

      if (error) throw error

      return newArea
    },
    onMutate: () => {
      addLoadingToast({ text: 'Updating area...', id: 'edit-interest-area' })
    },
    onError: error => {
      console.error(error)
      const message = 'Failed to update area.'
      logException(error, { message })
      addErrorToast({ text: message })
    },
    onSettled: () => {
      removeToast('edit-interest-area')
      setEditMode(false)
    },
    onSuccess: async newArea => {
      addSuccessToast({ text: 'Area updated.' })

      setCurrentInterestArea({
        ...newArea,
        context: context ?? undefined
      })

      transformIntoAreaFilters()

      queryClient.invalidateQueries({ queryKey: [AREAS_KEY_PREFIX] })
      queryClient.invalidateQueries({ queryKey: ['area-of-interest-trendline'] })
      queryClient.invalidateQueries({
        queryKey: [BASE_AREAS_KEY],
        exact: false,
        refetchType: 'all'
      })

      const advancedAreas = await queryClient.fetchQuery<AreaRequests.SearchAreasResponse['areas']>(
        {
          queryKey: [BASE_AREAS_KEY, undefined]
        }
      )
      const newAdvancedContent = advancedAreas.find(
        advArea => advArea.area_id === newArea.id
      )?.content

      if (newAdvancedContent) {
        checkAndUpdateAreaAlert({
          filterId: newArea.filterId,
          newContent: newAdvancedContent as [SavedFilterContentAdvanced]
        })
      }

      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: [UNMAPPED_AREA_QUERY_KEY], exact: false })
      }, 500)
    }
  })

  const updateArea = () => {
    if (!currentAreaOfInterest) return
    const content: [SavedFilterContentAdvanced] = [
      {
        key: 'advanced',
        name: 'advanced',
        type: 'advanced',
        values: { filter: advancedFilterToContent(advancedFilters) }
      }
    ]
    const newArea: BaseInterestArea = {
      ...currentAreaOfInterest,
      advanced: true,
      content
    }

    updateAreaMutate(newArea)
  }

  const updateCurrentView = useCallback(
    async (content: SavedFilterContent[]) => {
      if (!currentSavedFilter) return

      await FiltersService.updateFilter(currentSavedFilter.filterId, {
        name: filterName,
        content
      })

      if (currentSavedFilter.notification) {
        await NotificationsService.updateNotification({
          ...currentSavedFilter.notification,
          description: filterName,
          filter: getFeedbackFilterFromContent(content)
        })
      }

      setCurrentSavedFilter({
        ...currentSavedFilter,
        content,
        name: filterName
      })
      track('explore_user_saved_view_update')
      setSearchParams({ [FILTER_HASH_KEY]: currentSavedFilter.hash })
    },
    [currentSavedFilter, filterName, setCurrentSavedFilter, setSearchParams, track]
  )

  const getFeedbackFilterFromContent = useCallback(
    (content: SavedFilterContent[]) => {
      const search = getSearchFilterFromContent(content)

      const stringFilters = getStringFiltersFromContent(content)
      const {
        regularStringFilters,
        schemaFieldsStringFilters,
        customFieldsStringFilters,
        sentiment,
        feedbackSourceIds,
        dataType
      } = getFromStringFilters(stringFilters)

      const numericFilters = getNumericFiltersFromContent(content)
      const {
        regularNumericFilters,
        schemaFieldsNumberFilters,
        schemaFieldsIntegerFilters,
        customFieldsNumberFilters,
        customFieldsIntegerFilters
      } = getFromNumericFilters(numericFilters)

      const datetimeFilters = getDatetimeFiltersFromContent(content)
      const { schemaFieldsDatetimeFilters, customFieldsDatetimeFilters } =
        getFromDatetimeFilters(datetimeFilters)

      const { productAreaThemesIds, otherThemesIds } = getThemesFromContent(content)
      const { productAreaTopicIds, otherTopicIds } = getTopicsFromContent(content)
      const unclassifiedTopics = getUnclassifiedTopicsFromContent(content)
      const ungroupedTopics = getUngroupedTopicsFromContent(content)
      const topicGroups = getTopicGroups({
        unclassifiedTopics,
        selectedProductAreaThemes: productAreaThemesIds,
        selectedProductAreaTopics: productAreaTopicIds,
        selectedOtherTopicsThemes: otherThemesIds,
        selectedOtherTopics: otherTopicIds,
        selectedUngroupedTopics: ungroupedTopics.map(topic => topic.id)
      })

      const dateRangeContent = getDateRangeFromContent(content)
      let dateRange: FilterDatetimeValue
      if (dateRangeContent) {
        if (dateRangeContent.period !== 'custom' && dateRangeContent.period !== 'allTime') {
          dateRange = getPeriod(dateRangeContent.period)?.range ?? null
        } else {
          dateRange = dateRangeContent.range
        }
      } else {
        dateRange = null
      }

      return {
        filter: {
          search_text: search,
          posted_at: dateRange
            ? {
                gte: dateRange.start.toString(),
                lte: dateRange.end.toString()
              }
            : {},
          sentiment,
          feedback_source_ids: feedbackSourceIds,
          data_type: dataType,
          feedback_group_enable: true,
          schema_fields_datetime: schemaFieldsDatetimeFilters,
          schema_fields_integer: schemaFieldsIntegerFilters,
          schema_fields_number: schemaFieldsNumberFilters,
          schema_fields_string: schemaFieldsStringFilters,
          custom_fields_datetime: customFieldsDatetimeFilters,
          custom_fields_integer: customFieldsIntegerFilters,
          custom_fields_number: customFieldsNumberFilters,
          custom_fields_string: customFieldsStringFilters,
          topic_groups: topicGroups,
          ...regularStringFilters,
          ...regularNumericFilters
        }
      } as FilterRequests.FeedbackFilter
    },
    [
      getThemesFromContent,
      getDateRangeFromContent,
      getDatetimeFiltersFromContent,
      getNumericFiltersFromContent,
      getPeriod,
      getSearchFilterFromContent,
      getStringFiltersFromContent,
      getTopicsFromContent,
      getUnclassifiedTopicsFromContent,
      getUngroupedTopicsFromContent
    ]
  )

  const saveView = useCallback(
    async (enableNotifications: boolean) => {
      if (!filterName.length || (totalCount === 0 && period === '30days' && !search.length)) {
        invalidViewMessage()
        return
      }

      const removeLoading = addLoadingToast({ text: 'Saving filters...' })
      try {
        const content = parseAllToContent()

        const toUpdate = currentSavedFilter?.filterId

        if (toUpdate) {
          await updateCurrentView(content)
        } else {
          if (enableNotifications) {
            const feedbackFilter = getFeedbackFilterFromContent(content)

            const newSavedView = await createViewAndApply(content)
            const [, notificationData] = await NotificationsService.updateNotification({
              id: newSavedView.filterId,
              description: filterName,
              enabled: true,
              filter: feedbackFilter,
              notificationConfig: [
                { type: 'email', value: currentUser ? [currentUser.email] : [] }
              ],
              toggleDisabled: false,
              notificationSchedule: 'weekly',
              version: 'v1',
              createdBy: ''
            })

            setCurrentSavedFilter({ ...newSavedView, notification: notificationData })
          } else {
            await createViewAndApply(content)
          }
        }
        await fetchFilters(toUpdate ? content : undefined)

        removeLoading()
        let toastChildren: React.ReactNode = enableNotifications ? (
          <p>
            Filters successfully saved. You turned on a notification,{' '}
            <Link to="/settings/topic_notifications">go to settings</Link> to customize{' '}
          </p>
        ) : (
          <p>
            Filters successfully saved. You can turn on a notification at the{' '}
            <Link to="/settings/topic_notifications">settings page</Link>.
          </p>
        )
        if (toUpdate) {
          toastChildren = 'Filters successfully updated.'
        }
        addSuccessToast({ children: toastChildren })
      } catch (error) {
        removeLoading()
        handleError(error as Error)
      }
    },
    [
      filterName,
      totalCount,
      currentSavedFilter,
      period,
      parseAllToContent,
      fetchFilters,
      invalidViewMessage,
      updateCurrentView,
      addLoadingToast,
      addSuccessToast,
      createViewAndApply,
      currentUser,
      handleError,
      search.length,
      setCurrentSavedFilter,
      getFeedbackFilterFromContent
    ]
  )

  const saveAreaOfInterest = useCallback(async () => {
    if (!filterName.length) {
      invalidViewMessage()
      return
    }

    const content: SavedFilterContentAdvanced = {
      key: 'advanced',
      name: 'advanced',
      type: 'advanced',
      values: { filter: advancedFilterToContent(advancedFilters) }
    }

    createAreaAndApply([[content]])
    return
  }, [filterName, invalidViewMessage, advancedFilters, createAreaAndApply])

  const onSaveAsNew = useCallback(
    async (filter: SavedFilter) => {
      const removeLoading = addLoadingToast({ text: 'Saving filters...' })
      try {
        const newSavedFilters = {
          name: `${filter.name} (Copy)`,
          content: filter.content
        }

        await createViewAndApply(
          newSavedFilters.content as SavedFilterContent[],
          newSavedFilters.name
        )
        await fetchFilters()

        removeLoading()
        addSuccessToast({ text: 'Filters saved successfully.' })
      } catch (error) {
        console.error(error)
        const message = 'Failed to save these filters'
        logException(error, { message })
        addErrorToast({ text: message })
        removeLoading()
      }
    },
    [
      addErrorToast,
      addLoadingToast,
      addSuccessToast,
      createViewAndApply,
      fetchFilters,
      logException
    ]
  )

  const hasChanges = useMemo(() => {
    if (currentSavedFilter) {
      if (currentSavedFilter.name !== filterName) return true
      const currentContent = (currentSavedFilter.content as SavedFilterContent[]).sort((a, b) =>
        a.type.localeCompare(b.type)
      )
      const selectedContent = parseAllToContent().sort((a, b) => a.type.localeCompare(b.type))

      const sortedCurrent = currentContent.map(filter => ({
        key: filter.key,
        values: filter.values.sort((a, b) => a.localeCompare(b))
      }))

      const sortedSelected = selectedContent.map(filter => ({
        key: filter.key,
        values: filter.values.sort((a, b) => a.localeCompare(b))
      }))

      const _hasChanges = JSON.stringify(sortedCurrent) !== JSON.stringify(sortedSelected)

      return _hasChanges
    }

    return filterName?.length > 0 || totalCount > 0 || period !== '30days' || search.length > 0
  }, [currentSavedFilter, filterName, totalCount, period, parseAllToContent, search.length])

  // TODO: Use another way, since we don't have all the filters options available
  const isViewInvalid = useCallback(() => false, [])

  const fetchFilterByHash = async (filterHash: string) => {
    try {
      if (!filterHash) return

      if (currentSavedFilter?.hash === filterHash) return

      const [filterData, allThemes] = await Promise.all([
        FiltersService.getFilterByHash(filterHash),
        verifyAllTopicAndThemes()
      ])

      const fetchedFilter = mapSavedFilterData(filterData)

      if (isViewInvalid()) {
        const message = `The view "${fetchedFilter.name}" is not compatible with your workspace.`
        logException(new Error(message), { message })
        addErrorToast({
          text: message,
          duration: 3000
        })
        setShowFullScreenLoader(false)
        removeHashFromUrl()
        return
      }

      setFilterName(fetchedFilter.name)
      setCurrentSavedFilter(fetchedFilter)

      applySavedFilter(fetchedFilter, allThemes)

      setShowFullScreenLoader(false)

      addSuccessToast({ text: 'View applied successfully.' })
    } catch (error) {
      console.error(error)
      setShowFullScreenLoader(false)
      removeHashFromUrl()

      const message = 'Failed to apply your view.'
      logException(error, { message })
      addErrorToast({ text: message, duration: 3000 })
    }
  }

  const fetchAll = async () => {
    try {
      await fetchFilters()
    } catch (error) {
      console.error(error)
      const message = 'Failed to load your views.'
      logException(error, { message })
      addErrorToast({ text: message, duration: 2500 })
    }
  }

  return {
    applyFilterContent,
    applySavedFilter,
    onStartBlankFilter,
    onSaveAsNew,
    removeHashFromUrl,
    fetchFilters,
    getFeedbackFilterFromContent,
    saveView,
    saveAreaOfInterest,
    updateArea,
    isUpdatingArea,
    isViewInvalid,
    createViewAndApply,
    updateCurrentView,
    fetchFilterByHash,
    fetchAll,
    hasChanges
  }
}

export default useSavedFilters
