import { CSS, colors, styled } from '@/theme'
import {
  formatPercentageNoDecimalWithSymbol,
  formatPercentageVariationWithSymbol,
  roundAndSuffix
} from '@/utils/numbers'
import { CaretDown, CaretUp, IconContext } from '@phosphor-icons/react'

const VariationContainer = styled('span', {
  display: 'inline-flex',
  gap: 2,
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '$micro',
  width: 'fit-content',

  variants: {
    variation: {
      positive: {
        '.value': {
          color: '$feedbackPositivePure'
        }
      },
      negative: {
        '.value': {
          color: '$feedbackNegativePure'
        }
      }
    }
  }
})

interface Props {
  value: number
  type?: 'percentage' | 'points' | 'promoters' | 'detractors' | 'count'
  css?: CSS
  neutral?: boolean
  formatter?: (value: number) => string
}

const ExplorationNumberVariation = ({
  value,
  css,
  type = 'percentage',
  neutral = false,
  formatter
}: Props) => {
  const formattedValue =
    type === 'percentage'
      ? formatPercentageVariationWithSymbol(value)
      : type === 'count'
        ? formatter?.(value) ?? roundAndSuffix(value)
        : formatPercentageNoDecimalWithSymbol(value).replace('-', '').replace('%', 'pts')

  const good = ['percentage', 'detractors'].includes(type) ? value <= 0 : value >= 0
  let color = good ? colors.feedbackPositivePure : colors.feedbackNegativePure
  if (neutral) {
    color = colors.neutralLowPure
  }

  if (value === 0) return '---'

  return (
    <VariationContainer css={css} variation={good ? 'positive' : 'negative'}>
      <IconContext.Provider value={{ size: 12, weight: 'fill' }}>
        {value > 0 ? <CaretUp color={color} /> : <CaretDown color={color} />}
      </IconContext.Provider>
      <span className="value">{formattedValue.replace('-', '')}</span>
    </VariationContainer>
  )
}

export default ExplorationNumberVariation
