import { AreaRequests } from '@/types/area'
import RequestHandler from './RequestHandlers/NewRequest'
import { AxiosRequestConfig } from 'axios'

export default class AreaService {
  static PREFIX = '/areas'

  static async searchAreas(
    params: AreaRequests.SearchAreasParams,
    config?: AxiosRequestConfig<unknown>
  ) {
    return await RequestHandler.get<AreaRequests.SearchAreasResponse>(`${this.PREFIX}`, params, {
      ...config,
      paramsSerializer: {
        indexes: null
      }
    })
  }

  static async createArea(
    payload: AreaRequests.CreateAreaPayload,
    config?: AxiosRequestConfig<unknown>
  ) {
    return await RequestHandler.post<AreaRequests.CreateAreaResponse>(`${this.PREFIX}`, payload, {
      ...config,
      paramsSerializer: {
        indexes: null
      }
    })
  }

  static async getArea(areaId: string, config?: AxiosRequestConfig<unknown>) {
    return RequestHandler.get<AreaRequests.GetAreaResponse>(`${this.PREFIX}/${areaId}`, undefined, {
      ...config,
      paramsSerializer: {
        indexes: null
      }
    })
  }

  static async updateArea(
    areaId: string,
    payload: AreaRequests.UpdateAreaPayload,
    config?: AxiosRequestConfig<unknown>
  ) {
    return RequestHandler.put<AreaRequests.UpdateAreaResponse>(
      `${this.PREFIX}/${areaId}`,
      payload,
      {
        ...config,
        paramsSerializer: {
          indexes: null
        }
      }
    )
  }

  static async removeArea(areaId: string, config?: AxiosRequestConfig<unknown>) {
    return RequestHandler.del<void>(`${this.PREFIX}/${areaId}`, undefined, config)
  }

  static async getMergedAreas(
    payload: AreaRequests.MergedAreasPayload,
    config?: AxiosRequestConfig<unknown>
  ) {
    return RequestHandler.post<AreaRequests.MergedAreasResponse>(`${this.PREFIX}/merged`, payload, {
      ...config,
      paramsSerializer: {
        indexes: null
      }
    })
  }

  static async getUnmappedArea(config?: AxiosRequestConfig<unknown>) {
    return RequestHandler.get<AreaRequests.UnmappedResponse>(`${this.PREFIX}/unmapped`, undefined, {
      ...config,
      paramsSerializer: {
        indexes: null
      }
    })
  }
}
