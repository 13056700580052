import FlexContainer from '@/components/atoms/flex-container'
import { keyframes, styled } from '@/theme'
import { EditorContent } from '@tiptap/react'

export const TextEditorContainer = styled(FlexContainer, {
  flexDirection: 'column',
  lineHeight: '1.5em',

  variants: {
    editable: {
      true: {
        background: '$neutralHighLight',
        borderRadius: 8,

        outline: '1px solid $neutralHighPure',

        '&:focus-within': {
          outline: '2px solid $brandPrimaryPure'
        }
      },
      false: {
        background: 'transparent',
        my: '-$xxs'
      }
    },
    collapsed: {
      true: {
        '.tiptap.ProseMirror': {
          // display: '-webkit-box',
          // '-webkit-line-clamp': 2,
          // '-webkit-box-orient': 'vertical',
          // textOverflow: 'clip',
          // wordWrap: 'break-word',
          // overflow: 'hidden',
          // maxHeight: '2.5em'

          display: '-webkit-box',
          WebKitLineClamp: 2,
          '-webkit-line-clamp': 2,
          WebKitBoxOrient: 'vertical',
          '-webkit-box-orient': 'vertical',
          overflow: 'hidden'
        },

        'h1, h2, h3, h4, h5, h6': {
          fontSize: 'inherit'
        }
      },
      false: {
        '.tiptap.ProseMirror': {
          height: 'auto'
        }
      }
    }
  }
})

export const TextEditorContent = styled(EditorContent, {
  fontWeight: '$light',
  padding: '$xxxs $xs',
  pl: 0,
  '.tiptap.ProseMirror': {
    outline: 'none',
    '&:focus': { outline: 'none' },
    '&:active': { outline: 'none' }
  },

  '.editor-link': {
    color: '$blueHighlight',
    fontWeight: '$bold',
    textDecoration: 'underline'
  },

  li: {
    ml: '$xxs'
  },

  variants: {
    editable: {
      true: {},
      false: {
        pb: 0
      }
    }
  }
})

export const EditorToolbarContainer = styled(FlexContainer, {
  gap: '$nano',
  padding: '$nano $sm',
  bBottom: '$neutralHighPure'
})

const fadeAnimation = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 }
})

export const OptionsContainer = styled(FlexContainer, {
  gap: '$xxs',
  alignItems: 'center',
  transition: '0.2s',

  animation: `${fadeAnimation} 750ms ease-out`
})
