import useOppRelatedAreas from '@/hooks/opportunity/useOppRelatedAreas'
import Card from '../card'
import { ArrowsIn } from '@phosphor-icons/react'
import { Trans, useTranslation } from 'react-i18next'
import DistributionChart from '@/components/atoms/distribution-chart'
import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import Bone from '@/components/atoms/bone'
import { chartColors } from '@/theme/colors'
import { useCallback, useMemo, useState } from 'react'
import shortUUID from 'short-uuid'
import useAdvancedFilters from '@/hooks/advancedFilters/useAdvancedFilters'
import Button from '@/components/atoms/button'
import useOpportunityStore from '@/store/useOpportunityStore'
import Tooltip from '@/components/atoms/tooltip'
import useNavitagateTo from '@/hooks/useNavigateTo'

const TOP_AREAS_SIZE = 3

const Skeleton = () => {
  return (
    <FlexContainer gap="nano" fullWidth>
      <FlexContainer direction="column" gap="micro" fullWidth css={{ width: '40%' }}>
        <Bone css={{ height: 16, width: 120 }} />
        <Bone css={{ height: 24, width: '100%' }} />
      </FlexContainer>
      <FlexContainer direction="column" gap="micro" fullWidth css={{ width: '35%' }}>
        <Bone css={{ height: 16, width: 120 }} />
        <Bone css={{ height: 24, width: '100%' }} />
      </FlexContainer>
      <FlexContainer direction="column" gap="micro" fullWidth css={{ width: '25%' }}>
        <Bone css={{ height: 16, width: 120 }} />
        <Bone css={{ height: 24, width: '100%' }} />
      </FlexContainer>
    </FlexContainer>
  )
}

const AffectedAreas = () => {
  const { t } = useTranslation()
  const { relatedAreas, isLoading } = useOppRelatedAreas()

  const [isExpanded, setIsExpanded] = useState(false)

  const topAffectedAreas = useMemo(() => {
    const topAreas = relatedAreas.slice(0, TOP_AREAS_SIZE)

    return topAreas
  }, [relatedAreas])

  const othersAreasLength = useMemo(() => relatedAreas.length - TOP_AREAS_SIZE, [relatedAreas])

  const setCurrentOpportunity = useOpportunityStore(state => state.setCurrentOpportunity)
  const currentOpportunity = useOpportunityStore(state => state.currentOpportunity)
  const selectedAreas = useOpportunityStore(state => state.selectedAreas)
  const setSelectedAreas = useOpportunityStore(state => state.setSelectedAreas)

  const currentArea = useMemo(
    () => (selectedAreas.length === 1 ? selectedAreas[0] : undefined),
    [selectedAreas]
  )

  const { navigateTo: navigate } = useNavitagateTo()
  const translator = useMemo(() => shortUUID(), [])
  const shortOpportunityId = useMemo(
    () => translator.fromUUID(currentOpportunity?.id ?? ''),
    [currentOpportunity, translator]
  )

  const { applyOpportunityFilters } = useAdvancedFilters()

  const [areaHovered, setAreaHovered] = useState<string | undefined>(undefined)

  const isMuted = useCallback(
    (areaId: string) => {
      if (areaHovered) {
        return areaId !== areaHovered
      }

      return currentArea ? areaId !== currentArea?.id : false
    },
    [currentArea, areaHovered]
  )

  const showResetButton = useMemo(
    () => relatedAreas.length > 1 && (currentArea !== undefined || isExpanded),
    [currentArea, isExpanded, relatedAreas]
  )

  const onResetClick = useCallback(() => {
    setIsExpanded(false)
    if (currentArea) {
      setSelectedAreas([])
      if (currentOpportunity) {
        // applyFilterFromOpportunity(currentOpportunity)
        applyOpportunityFilters({ opportunity: currentOpportunity, areaIds: [] })
        setCurrentOpportunity(currentOpportunity)
      }
      navigate(`/opportunity/${shortOpportunityId}`)
    }
  }, [
    currentOpportunity,
    shortOpportunityId,
    applyOpportunityFilters,
    navigate,
    setSelectedAreas,
    setCurrentOpportunity,
    currentArea
  ])

  const onAreaClick = useCallback(
    (areaId: string) => {
      if (!currentOpportunity) return
      if (relatedAreas.length === 1) return
      if (currentArea?.id !== areaId || !currentArea) {
        applyOpportunityFilters({ opportunity: currentOpportunity, areaIds: [areaId] })
      } else {
        applyOpportunityFilters({ opportunity: currentOpportunity, areaIds: [] })
      }
    },
    [currentArea, applyOpportunityFilters, currentOpportunity, relatedAreas.length]
  )

  const getPercentage = useCallback(
    (count: number) => {
      const top = relatedAreas[0].count
      return (count / (top === 0 ? 1 : top)) * 100
    },
    [relatedAreas]
  )

  return (
    <Card.Root>
      <FlexContainer direction="column" gap="micro" fullWidth>
        <FlexContainer alignItems="center" justifyContent="spaceBetween" css={{ height: 24 }}>
          <Card.Header>
            <Card.IconBox>
              <ArrowsIn />
            </Card.IconBox>
            <Card.Title>{t('affectedAreas')}</Card.Title>
          </Card.Header>
          {showResetButton && (
            <Button variant="flat" onClick={onResetClick} size="small">
              {t('reset')}
            </Button>
          )}
        </FlexContainer>
        {isLoading ? (
          <Skeleton />
        ) : (
          <>
            {isExpanded ? (
              <FlexContainer direction="column" gap="micro">
                {relatedAreas.map((area, index) => (
                  <FlexContainer
                    direction="column"
                    gap="micro"
                    key={area.id}
                    css={{ cursor: 'pointer' }}
                    fullWidth
                    onClick={() => onAreaClick(area.id)}
                    onMouseEnter={() => setAreaHovered(area.id)}
                    onMouseLeave={() => setAreaHovered(undefined)}
                  >
                    <FlexContainer
                      gap="nano"
                      fullWidth
                      css={{ maxHeight: 16, overflow: 'truncate' }}
                    >
                      <Text fontSize="xxxs" color="neutralLowPure">
                        {area.name}
                      </Text>
                      <Text fontSize="xxxs" color="neutralLowLight" fontWeight="semibold">
                        {area.count}
                      </Text>
                    </FlexContainer>

                    <DistributionChart.Section
                      customColor={chartColors[index]}
                      index={index}
                      muted={isMuted(area.id)}
                      css={{ height: 4 }}
                      percentage={getPercentage(area.count)}
                    />
                  </FlexContainer>
                ))}
              </FlexContainer>
            ) : (
              <FlexContainer direction="column" gap="micro">
                <DistributionChart.Root css={{ minHeight: 48 }}>
                  {topAffectedAreas.map((area, index) => (
                    <FlexContainer
                      direction="column"
                      gap="micro"
                      key={area.id}
                      css={{ cursor: 'pointer', width: `${area.percentage}%`, minWidth: 160 }}
                      fullHeight
                      onClick={() => onAreaClick(area.id)}
                      onMouseEnter={() => setAreaHovered(area.id)}
                      onMouseLeave={() => setAreaHovered(undefined)}
                    >
                      <Tooltip variant="small" side="top" text={area.name} delayDuration={1000}>
                        <FlexContainer
                          gap="nano"
                          css={{ maxHeight: 16, overflow: 'truncate', width: 'fit-content' }}
                        >
                          <Text fontSize="xxxs" color="neutralLowPure">
                            {area.name}
                          </Text>
                          <Text fontSize="xxxs" color="neutralLowLight" fontWeight="semibold">
                            {area.count}
                          </Text>
                        </FlexContainer>
                      </Tooltip>

                      <DistributionChart.Section
                        customColor={chartColors[index]}
                        index={index}
                        muted={isMuted(area.id)}
                        percentage={100}
                      />
                    </FlexContainer>
                  ))}
                </DistributionChart.Root>
                {relatedAreas.length > TOP_AREAS_SIZE && (
                  <Button
                    variant="link"
                    css={{ px: 0 }}
                    onClick={() => setIsExpanded(true)}
                    size="small"
                  >
                    <Trans i18nKey="seeNMore" values={{ n: othersAreasLength }} />
                  </Button>
                )}
              </FlexContainer>
            )}
          </>
        )}
      </FlexContainer>
    </Card.Root>
  )
}

export default AffectedAreas
