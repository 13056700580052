import useMetricListPayload from '@/hooks/metrics/useMetricListPayload'
import useLogging from '@/hooks/useLogging'
import useSourcesQuery from '@/hooks/useSourcesQuery'
import MetricsService from '@/services/MetricsService'
import useCompetitorsModeStore from '@/store/useCompetitorsModeStore'
import useAdvancedFiltersStore from '@/store/useFiltersStore/useAdvancedFiltersStore'
import useDateFilterStore from '@/store/useFiltersStore/useDateFilterStore'
import useOpportunityStore from '@/store/useOpportunityStore'
import { FeedbackListQueryParams } from '@/types/feedbacks/FeedbackRequests'
import { AllMetricsKey, MetricsRequests } from '@/types/metrics'
import { BreakdownOption } from '@/types/time-series/TimeSeries'
import { endDateParam, startDateParam } from '@/utils/date'
import { delay } from '@/utils/delay'
import { intentionFeedQueryParam } from '@/utils/intention'
import { allMetricItems } from '@/utils/metrics'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

export const mapBreakdownToBaseFilter = {
  intention: [...Object.values(intentionFeedQueryParam).map(intention => ({ intention }))],
  sentiment: [{ sentiment: 'POSITIVE' }, { sentiment: 'NEGATIVE' }, { sentiment: 'NEUTRAL' }]
}

interface Params {
  metric?: AllMetricsKey
  rowBreakdown: BreakdownOption
  columnBreakdown: BreakdownOption
  breakdownFilters: {
    areas: FeedbackListQueryParams[]
    opportunity: FeedbackListQueryParams[]
    npsSatisfactionFilters: (FeedbackListQueryParams & Record<string, number | ParamValue>)[]
    csatSatisfactionFilters: (FeedbackListQueryParams & Record<string, number | ParamValue>)[]
    reviewSatisfactionFilters: (FeedbackListQueryParams & Record<string, number | ParamValue>)[]
    base: FeedbackListQueryParams[]
    all: FeedbackListQueryParams[]
    fields: FeedbackListQueryParams[]
  }
}

const useComparativeTableMetrics = ({
  rowBreakdown,
  columnBreakdown,
  metric = 'count',
  breakdownFilters
}: Params) => {
  const { logException } = useLogging({ context: 'useComparativeTableMetrics' })

  const { dateRange, datePeriod } = useDateFilterStore(state => ({
    dateRange: state.dateRange,
    datePeriod: state.datePeriod
  }))
  const { data: sourcesData, isLoading: isLoadingSources } = useSourcesQuery({ enabled: true })

  const isFetchingContext = useAdvancedFiltersStore(state => state.isFetchingContext)

  const currentOpportunity = useOpportunityStore(state => state.currentOpportunity)

  const mapBreakdownToFilters: Record<BreakdownOption, FeedbackListQueryParams[]> = useMemo(() => {
    return {
      intention: [
        ...mapBreakdownToBaseFilter.intention,
        { has_aspect: false }
      ] as FeedbackListQueryParams[],
      sentiment: mapBreakdownToBaseFilter.sentiment as FeedbackListQueryParams[],
      kind: (sourcesData?.values ?? []).map(source => ({ kind: source })),
      areas: breakdownFilters.areas,
      opportunity: breakdownFilters.opportunity,
      satisfaction: breakdownFilters.npsSatisfactionFilters,
      satisfaction_by_csat: breakdownFilters.csatSatisfactionFilters,
      satisfaction_by_nps: breakdownFilters.npsSatisfactionFilters,
      satisfaction_by_review: breakdownFilters.reviewSatisfactionFilters,
      all: breakdownFilters.all,
      feedbackDetail: breakdownFilters.fields,
      customField: breakdownFilters.fields,
      account: breakdownFilters.fields,
      user: breakdownFilters.fields,
      org: [{}],
      date: [{}]
    }
  }, [breakdownFilters, sourcesData])

  const rowFilterList = useMemo(
    () => mapBreakdownToFilters[rowBreakdown],
    [mapBreakdownToFilters, rowBreakdown]
  )
  const columnFilterList = useMemo(
    () => mapBreakdownToFilters[columnBreakdown],
    [mapBreakdownToFilters, columnBreakdown]
  )

  const filters = useMemo(() => {
    const filterList: FeedbackListQueryParams[] = []

    rowFilterList.forEach(rowItem => {
      columnFilterList.forEach(columnItem => {
        filterList.push({
          ...rowItem,
          ...columnItem,
          context: rowItem.context ?? columnItem.context,
          opportunity_id: rowItem.opportunity_id ?? columnItem.opportunity_id
        })
      })
    })

    return filterList.map(filter => {
      return {
        ...filter,
        opportunity_id: filter.opportunity_id || currentOpportunity?.id,
        label: undefined,
        'posted_at.lt': undefined,
        'posted_at.gte': undefined
      }
    })
  }, [currentOpportunity, columnFilterList, rowFilterList])

  const { getMetricAndAddShareFilter } = useMetricListPayload()

  const { competitorsModeEnabled, competitorsFilterValues } = useCompetitorsModeStore(state => ({
    competitorsModeEnabled: state.competitorsModeEnabled,
    competitorsFilterValues: state.competitorsFilterValues
  }))

  const queryFn = async () => {
    let startDate: string | undefined
    let endDate: string | undefined
    if (datePeriod !== 'allTime' && dateRange) {
      startDate = startDateParam(dateRange.start)
      endDate = endDateParam(dateRange.end)
    }

    const metricItem = allMetricItems[metric]
    if (!metricItem) {
      const metricError = new Error(`Metric '${metric}' not found`)
      logException(metricError)
      throw metricError
    }

    const currentMetric = getMetricAndAddShareFilter(metric)
    if (competitorsModeEnabled) {
      currentMetric.share_filter = {
        ...currentMetric.share_filter,
        'review.owner': competitorsFilterValues
      } as FeedbackListQueryParams
    }

    const chunkSize = 5
    const chunks: FeedbackListQueryParams[][] = []

    for (let i = 0; i < filters.length; i += chunkSize) {
      chunks.push(filters.slice(i, i + chunkSize))
    }

    const promises = chunks.map(async (chunk, index) => {
      const metricsPayload: MetricsRequests.MetricsPayload = {
        filter_list: chunk.map((filter): FeedbackListQueryParams => filter),
        metric_list: [
          {
            ...currentMetric,
            include_previous_value: false
          }
        ],
        posted_at_gte: startDate,
        posted_at_lt: endDate
      }

      await delay(index * 250)
      return MetricsService.metrics(metricsPayload)
    })

    const responses = await Promise.all(promises)
    const someError = responses.find(response => response[0])
    if (someError) throw someError

    const data = responses.flatMap(response => response[1]) as MetricsRequests.MetricsResponse
    return data
  }

  const queryKey = useMemo(() => {
    return [
      'comparative-table-metrics',
      { metric, filters, dateRange, datePeriod, rowBreakdown, columnBreakdown }
    ]
  }, [metric, filters, dateRange, datePeriod, rowBreakdown, columnBreakdown])

  const isEnabled =
    rowBreakdown !== columnBreakdown &&
    rowBreakdown !== 'date' &&
    columnBreakdown !== 'date' &&
    !isLoadingSources &&
    !isFetchingContext &&
    filters.length > 0

  const { data, isLoading } = useQuery({
    queryKey,
    queryFn,
    enabled: isEnabled,
    retry: false
  })

  const isEmptyFilters = !filters.length

  return { data, isLoading, columnFilterList, rowFilterList, isEmptyFilters }
}

export default useComparativeTableMetrics
