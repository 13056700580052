import { FeedbackListQueryParams } from '@/types/feedbacks/FeedbackRequests'
import {
  AdvancedFilterContent,
  AdvancedFilterContentKeyValue,
  AdvancedFilterContentValue,
  DateFilterValue,
  FilterLogicOperator,
  FilterMap,
  FilterNode,
  FilterOption,
  FilterType,
  UniqueFilterValue,
  GenericFilter,
  NumberFilterValue
} from '@/types/filters/AdvancedFilters'
import { AreaOfInterestData, BaseInterestArea } from '@/types/area/AreaOfInterest'
import { SavedFilterContent, SavedFilterContentAdvanced } from '@/types/filters/Filters'
import { getParamsFromFilterContent } from './filters'

interface ContentToAdvancedFilterParams {
  content: AdvancedFilterContent
  filterMap: FilterMap
  isFromArea?: boolean
}

interface GetFilterOptionParams {
  type: FilterType
  value: AdvancedFilterContentValue
  negative: boolean
}

const getFilterOption = ({ type, value, negative }: GetFilterOptionParams): FilterOption => {
  if (type.includes('boolean')) {
    return 'matches'
  }

  if (type.includes('text')) {
    if (negative) return 'not_contains'
    return 'contains'
  }

  if (type.includes('enum')) {
    if (negative) return 'does_not_match'
    return 'matches'
  }

  // TODO: handle "starts_with"
  if (type.includes('unique')) {
    const v = value as UniqueFilterValue
    if (v.$startsWith) return 'starts_with'
    if (negative) return 'does_not_match'
    return 'matches'
  }

  if (type.includes('number') || type.includes('integer')) {
    const v = value as NumberFilterValue | null
    if (v?.$eq && !negative) {
      return 'matches'
    } else if (v?.$eq && negative) {
      return 'does_not_match'
    } else if (v?.$gt && !negative) {
      return 'greater'
    } else if (v?.$gte && !negative) {
      return 'greater_or_equal'
    } else if (v?.$lt && !negative) {
      return 'less'
    } else if (v?.$lte && !negative) {
      return 'less_or_equal'
    } else if (v?.$gte && v?.$lte && !negative) {
      return 'range'
    } else if (v === null && !negative) {
      return 'empty'
    } else if (v === null && negative) {
      return 'not_empty'
    }

    return 'empty'
  }

  if (type.includes('date')) {
    const v = value as DateFilterValue | null
    if (v?.$gte && v?.$lt && !negative) {
      return 'matches'
    } else if (v?.$gte && v?.$lt && negative) {
      return 'does_not_match'
    } else if (v?.$gte && !v?.$lt && !negative) {
      return 'after'
    } else if (!v?.$gte && v?.$lt && !negative) {
      return 'before'
    } else if (v === null && !negative) {
      return 'empty'
    } else if (v === null && negative) {
      return 'not_empty'
    }

    return 'empty'
  }

  return 'matches'
}

const handleSpecialCasesToAdvancedFilter = ({
  filterValue,
  innerFilterKey,
  filterType,
  negative
}: {
  filterValue: AdvancedFilterContentValue
  innerFilterKey: string
  filterType: FilterType
  negative: boolean
}) => {
  let value = filterValue
  let option = getFilterOption({ type: filterType, value, negative })

  if (innerFilterKey === '$missing') {
    option = 'empty'
    value = null
  } else if (innerFilterKey === '$exists') {
    option = 'not_empty'
    value = null
  } else if (filterType === 'enum' && typeof filterValue === 'string') {
    value = [filterValue]
  }

  return { value, option }
}

export const contentToAdvancedFilter = ({
  content,
  filterMap,
  isFromArea = true
}: ContentToAdvancedFilterParams): FilterNode | GenericFilter => {
  if (content.$not) {
    const innerFilter = content.$not
    if (!innerFilter.$not && !innerFilter.$or && !innerFilter.$and) {
      const innerFilterKey = Object.keys(innerFilter)[0]

      if (!innerFilterKey) {
        throw new Error(`Empty filter object: ${JSON.stringify(innerFilter)}`)
      }

      const filterValue = (innerFilter as AdvancedFilterContentKeyValue)[innerFilterKey]

      let name = innerFilterKey
      if (innerFilterKey === '$missing' || innerFilterKey === '$exists') {
        if (typeof filterValue !== 'string') {
          throw new Error(`Invalid filter value: ${JSON.stringify(filterValue)}`)
        }
        name = filterValue as string
      }

      const filterType = filterMap.get(name)?.type
      if (!filterType) {
        throw new Error(`Unknown filter type: ${Object.keys(innerFilter)[0]}`)
      }

      const filterPath = filterMap.get(name)?.path ?? name

      const { value, option } = handleSpecialCasesToAdvancedFilter({
        filterValue,
        innerFilterKey,
        filterType,
        negative: true
      })

      return {
        type: filterType,
        name,
        value,
        option,
        isFromArea,
        path: filterPath,
        status: 'valid'
      }
    } else {
      return {
        operator: '$not',
        isFromArea,
        value: contentToAdvancedFilter({ content: innerFilter, filterMap, isFromArea })
      }
    }
  } else {
    const filterKey = Object.keys(content)[0]
    if (!filterKey) {
      throw new Error(`Empty filter object: ${JSON.stringify(content)}`)
    }

    const innerFilter = content[filterKey as '$or' | '$and']
    if (!innerFilter) {
      throw new Error(`Empty filter object: ${JSON.stringify(content)}`)
    }

    const nodeValue: (FilterNode | GenericFilter)[] = innerFilter.map(filterItem => {
      if (!filterItem.$not && !filterItem.$or && !filterItem.$and) {
        const filterItemKey = Object.keys(filterItem)[0]
        if (!filterItemKey) {
          throw new Error(`Empty filter object: ${JSON.stringify(filterItem)}`)
        }

        const filterValue = (filterItem as AdvancedFilterContentKeyValue)[filterItemKey]

        let name = filterItemKey
        if (filterItemKey === '$missing' || filterItemKey === '$exists') {
          if (typeof filterValue !== 'string') {
            throw new Error(`Invalid filter value: ${JSON.stringify(filterValue)}`)
          }
          name = filterValue as string
        }

        const filterType = filterMap.get(name)?.type
        if (!filterType) {
          throw new Error(`Unknown filter type: ${Object.keys(filterItem)[0]}`)
        }

        const filterPath = filterMap.get(name)?.path ?? name

        const { value, option } = handleSpecialCasesToAdvancedFilter({
          filterValue,
          innerFilterKey: filterItemKey,
          filterType,
          negative: false
        })

        return {
          type: filterType,
          name,
          value,
          option,
          isFromArea,
          path: filterPath,
          status: 'valid'
        }
      }

      return contentToAdvancedFilter({ content: filterItem, filterMap, isFromArea })
    })

    return {
      operator: filterKey as FilterLogicOperator,
      isFromArea,
      value: nodeValue
    }
  }
}

const negativeOptions: FilterOption[] = ['not_contains', 'does_not_match']

const handleSpecialCasesToContent = (
  filterValue: GenericFilter
): { [x: string]: AdvancedFilterContentValue } => {
  let filterKeyValueObject = { [filterValue.name]: filterValue.value }

  // handle missing and exists
  if (filterValue.option === 'empty') {
    filterKeyValueObject = { $missing: filterValue.name }
  } else if (filterValue.option === 'not_empty') {
    filterKeyValueObject = { $exists: filterValue.name }
  }

  return filterKeyValueObject
}

export const advancedFilterToContent = (advancedFilter: FilterNode): AdvancedFilterContent => {
  if (advancedFilter.operator === '$not') {
    const innerFilter = advancedFilter.value as FilterNode | GenericFilter
    if (!Object.keys(innerFilter).includes('operator')) {
      const filterValue = innerFilter as GenericFilter

      const filterKeyValueObject = handleSpecialCasesToContent(filterValue)

      return { $not: filterKeyValueObject }
    } else {
      return { $not: advancedFilterToContent(innerFilter as FilterNode) }
    }
  } else {
    const filterKey = advancedFilter.operator
    const innerFilter = advancedFilter.value as (FilterNode | GenericFilter)[]

    const filterValue = innerFilter.map(filterItem => {
      if (!Object.keys(filterItem).includes('operator')) {
        const filterValue = filterItem as GenericFilter

        const filterKeyValueObject = handleSpecialCasesToContent(filterValue)

        if (filterValue.option && negativeOptions.includes(filterValue.option)) {
          return { $not: filterKeyValueObject }
        }
        return filterKeyValueObject
      } else {
        return advancedFilterToContent(filterItem as FilterNode)
      }
    })

    return { [filterKey]: filterValue }
  }
}

export const logicOperatorToText = (operator: FilterLogicOperator) => {
  const map: Record<FilterLogicOperator, string> = {
    $and: 'AND',
    $or: 'OR',
    $not: 'NOT'
  }

  return map[operator]
}

export const isAdvancedContent = (
  content: SavedFilterContent[] | [SavedFilterContentAdvanced]
): content is [SavedFilterContentAdvanced] => {
  if (!content || !content?.length) return false
  return content?.[0].key === 'advanced'
}

export const searchParamsToAdvancedFilterContent = (
  queryParams: FeedbackListQueryParams
): AdvancedFilterContentKeyValue => {
  const advancedContent: AdvancedFilterContentKeyValue = {}

  Object.entries(queryParams).forEach(([key, value]) => {
    const filterValue = value as ParamValue | number | boolean

    if (
      key.endsWith('.gte') ||
      key.endsWith('.lte') ||
      key.endsWith('.gt') ||
      key.endsWith('.lt') ||
      key.endsWith('.eq')
    ) {
      const [field, conditionKey] = key.split('.')
      if (!advancedContent[field]) advancedContent[field] = {}

      const dateOrNumberFilter: DateFilterValue | NumberFilterValue = {
        [`$${conditionKey}`]: filterValue,
        ...(advancedContent[field] as DateFilterValue | NumberFilterValue)
      }

      advancedContent[field] = dateOrNumberFilter
    } else {
      advancedContent[key] = filterValue
    }
  })

  return advancedContent
}

export const mergeAdvancedFilters = (
  filters: AdvancedFilterContent[],
  operator: '$and' | '$or' = '$or'
): AdvancedFilterContent => {
  return { [operator]: filters.filter(filter => Object.keys(filter).length > 0) }
}

export const getAdvancedFilterFromContent = (
  content: SavedFilterContent[] | [SavedFilterContentAdvanced]
) => {
  if (isAdvancedContent(content)) {
    const advancedFilterContent = (content[0] as SavedFilterContentAdvanced).values.filter
    return advancedFilterContent
  }

  const params = getParamsFromFilterContent(content) as FeedbackListQueryParams
  const advancedFilterContent = searchParamsToAdvancedFilterContent(params)

  return advancedFilterContent
}

export const mergeAreasToAdvancedFilterContent = (
  areas: (BaseInterestArea | AreaOfInterestData)[],
  operator: '$and' | '$or' = '$or'
): AdvancedFilterContent => {
  const filters = areas.map(area => {
    const advancedFilterContent = getAdvancedFilterFromContent(area.content)
    return advancedFilterContent
  })

  return mergeAdvancedFilters(filters, operator)
}

export const isFilterNode = (value: FilterNode | GenericFilter): value is FilterNode => {
  if (!value) return false

  return typeof (value as FilterNode).operator === 'string'
}
