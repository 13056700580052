import {
  DropdownMenuSubContent,
  DropdownMenuSubTrigger
} from '@/components/atoms/options-menu/OptionsMenu.styles'
import { ScrollAreaRoot } from '@/components/molecules/scroll-area'
import { styled } from '@/theme'

export const FilterSubTrigger = styled(DropdownMenuSubTrigger, {
  margin: 0,
  maxWidth: 320,
  boxSizing: 'border-box',

  '& > .chevron': {
    opacity: 0,
    ml: 'auto'
  },

  '&[data-state="open"]': {
    backgroundColor: '$brandPrimaryLight',
    color: '$brandPrimaryPure',
    '& > .chevron': {
      opacity: 1
    }
  },

  '&:hover': {
    backgroundColor: 'unset'
  },

  '&[data-highlighted]': {
    backgroundColor: '$brandPrimaryLight',
    color: '$brandPrimaryPure',
    '& > .chevron': {
      opacity: 1
    }
  }
})

export const FilterSubContent = styled(DropdownMenuSubContent, {
  padding: '$xxxs'
})

export const SelectionContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: 320,
  // maxHeight: 500,
  boxSizing: 'border-box',
  gap: '$micro',
  mt: -16,

  variants: {
    compact: {
      true: {
        pAll: 0,
        pt: '$xxxs',
        mx: '-$micro',
        mb: '-$micro'
      },
      false: {
        padding: '$xxxs 0'
      }
    }
  },

  defaultVariants: {
    compact: 'false'
  }
})

export const ValueBox = styled('div', {
  fontSize: '$xxxs',
  borderRadius: '$nano',
  background: '$neutralHighMedium',
  color: '$neutralLowMedium',
  padding: '$nano $micro',
  transition: '.2s',

  variants: {
    visible: {
      true: {
        opacity: 1
      },
      false: {
        opacity: 0
      }
    }
  }
})

export const FilterSubMenuScrollArea = styled(ScrollAreaRoot, {
  overflow: 'hidden'
})
