import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

interface HideMetricsState {
  hasCompetitorsFilter: boolean
  setHasCompetitorsMode: (hasCompetitorsFilter: boolean) => void
  competitorsModeEnabled: boolean
  setCompetitorsModeEnabled: (hasCompetitorsFilter: boolean) => void
  competitorsFilterValues: string[]
  setCompetitorsFilterValues: (hasCompetitorsFilter: string[]) => void
}

export const useCompetitorsModeStore = createWithEqualityFn<HideMetricsState>()(
  set => ({
    hasCompetitorsFilter: false,
    competitorsModeEnabled: false,
    competitorsFilterValues: ['owner', 'Competitor'],

    setHasCompetitorsMode: hasCompetitorsFilter => set({ hasCompetitorsFilter }),
    setCompetitorsModeEnabled: competitorsModeEnabled => set({ competitorsModeEnabled }),
    setCompetitorsFilterValues: competitorsFilterValues => set({ competitorsFilterValues })
  }),
  shallow
)

export default useCompetitorsModeStore
