import { styled } from '@/theme'
import NavigationItem, { NavigationItemProps } from './NavigationItem'
import {
  navigationItemDisbledStyle,
  navigationItemStyle,
  opportunityNavigationItemStyle
} from './NavigationItem.styles'
import { NavLinkProps, useLocation } from 'react-router-dom'
import { NavLinkFromTo } from '../links'

const StyledNavigationItemLink = styled(NavLinkFromTo, {
  ...navigationItemStyle,
  variants: {
    disabled: {
      true: navigationItemDisbledStyle
    },
    opportunityPlan: {
      true: {
        ...opportunityNavigationItemStyle
      },
      false: {}
    }
  },
  defaultVariants: {
    opportunityPlan: false
  }
})

interface NavigationItemLinkProps
  extends NavigationItemProps,
    Omit<NavLinkProps, 'children' | 'title'> {
  to: string
  selected?: boolean
}

function NavigationItemLink({
  to,
  title,
  children,
  disabled,
  selected = false,
  ...props
}: NavigationItemLinkProps) {
  const activeClassName = ({ isActive }: { isActive: boolean }) => (isActive ? 'active' : undefined)
  const customActiveClassName = selected ? 'active' : undefined
  const className = [activeClassName, customActiveClassName].filter(Boolean).join(' ')

  const { search } = useLocation()
  const id = 'nav-' + to

  return (
    <StyledNavigationItemLink
      className={className}
      disabled={disabled}
      id={id}
      to={to + search}
      {...props}
    >
      <NavigationItem title={title + `${selected ? ' *' : ''}`}>{children}</NavigationItem>
    </StyledNavigationItemLink>
  )
}

export default NavigationItemLink
