import {
  AlertData,
  AlertWithType,
  NotificationConfigModel,
  NotificationSchedule
} from '@/types/alerts/Alerts'
import useToastMessageStore from '@/store/useToastMessageStore'
import useLogging from '../useLogging'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import { useMemo } from 'react'
import AlertsService from '@/services/AlertsService'
import { useMutation, useQuery } from '@tanstack/react-query'
import { AlertResponseItem } from '@/types/alerts/AlertsRequests'
import { SavedFilterContentAdvanced } from '@/types/filters/Filters'
import { queryClient } from '@/plugins/reactQueryClient'
import DefaultErrorHandler from '@/services/DefaultError'
import { ALERTS_LIST_KEY_PREFIX } from './useAlertList'
import useOpportunityStore from '@/store/useOpportunityStore'
import { OpportunityRequests } from '@/types/opportunity'
import OpportunityService from '@/services/OpportunityService'
import useBasicAreaOfInterestQuery from '../areaOfInterest/useBasicAreaOfInterestQuery'

export const ALERTS_QUERY_KEY_PREFIX = 'alerts-query'

interface CreateAlertParams {
  name: string
  schedule: NotificationSchedule
  emails: string[]
  channels: string[]
}

interface UpdateAlertParams extends CreateAlertParams {
  originalAlert: AlertData | AlertWithType
}

type AlertContent = [SavedFilterContentAdvanced] | undefined

interface Props {
  enabled: boolean
  defaultAlert?: AlertData
}

export const parseAlert = (alert: AlertResponseItem): AlertData => ({
  id: alert.id,
  areaId: alert.area_id,
  opportunityId: alert.opportunity_id,
  filter: alert.filter,
  notificationSchedule: alert.notification_schedule,
  version: alert.version,
  enabled: alert.enabled,
  description: alert.description,
  condition: alert.condition,
  notificationType: alert.notification_type,
  notificationConfig: alert.notification_config,
  organizationId: alert.organization_id,
  createdBy: alert.created_by,
  createdAt: alert.created_at,
  updatedBy: alert.updated_by,
  updatedAt: alert.updated_at
})

const useAlerts = ({ enabled, defaultAlert }: Props) => {
  const currentInterestArea = useCurrentInterestAreaStore(state => state.currentInterestArea)
  const currentOpportunity = useOpportunityStore(state => state.currentOpportunity)

  const areaFilterId = useMemo(() => currentInterestArea?.filterId, [currentInterestArea])
  const opportunityFilterId = useMemo(() => currentOpportunity?.filterId, [currentOpportunity])

  const { areas: advancedAreas } = useBasicAreaOfInterestQuery()

  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const { logException } = useLogging({ context: 'alerts' })

  const queryFn = async () => {
    if (opportunityFilterId && !areaFilterId) {
      return []
    }

    const [error, data] = await AlertsService.searchAlerts({
      version: 'v2',
      area_id: areaFilterId,
      opportunity_id: opportunityFilterId,
      per_page: 1
    })

    if (error) {
      const message = 'Failed to load alerts.'
      addErrorToast({ text: message })
      logException(error, { message })

      throw error
    }

    return data.alerts.map(parseAlert)
  }

  const queryKey = useMemo(
    () => [ALERTS_QUERY_KEY_PREFIX, areaFilterId, opportunityFilterId],
    [areaFilterId, opportunityFilterId]
  )

  const { data, isLoading, isError, error } = useQuery({
    queryKey,
    queryFn,
    // do not load alerts on an opportunity without area
    enabled: enabled && !(opportunityFilterId && !areaFilterId),
    retry: false
  })

  const currentAlert = useMemo(() => defaultAlert ?? data?.[0] ?? null, [defaultAlert, data])

  const {
    mutate: createAlert,
    isError: isCreateError,
    isLoading: isCreateLoading
  } = useMutation({
    mutationFn: async (config: CreateAlertParams) => {
      const getContent = (): AlertContent => {
        let advancedContent: AlertContent

        if (currentInterestArea?.isUnmappedArea) {
          advancedContent = currentInterestArea.content as [SavedFilterContentAdvanced]
          return advancedContent
        }

        advancedContent = advancedAreas.find(area => area.filterId === areaFilterId)?.content as [
          SavedFilterContentAdvanced
        ]

        if (!advancedContent || !advancedContent.length) return

        return advancedContent
      }

      const content = getContent()
      if (!content) {
        const contentNotFoundError = new Error(
          `Content filter not found for notification "${config.name}": Filter not found.`
        )
        logException(contentNotFoundError, { message: 'Content filter not found' })
        throw contentNotFoundError
      }

      if (content[0].key !== 'advanced') {
        const invalidContentType = new Error(
          `Could not register notification "${config.name}": Invalid filter.`
        )

        logException(invalidContentType, {
          message: `Filter is not advanced filter.`
        })
        throw invalidContentType
      }

      const contentFilter = (content[0] as SavedFilterContentAdvanced).values.filter

      const filter: SavedFilterContentAdvanced = {
        ...content[0],
        values: {
          filter: {
            ...contentFilter,
            $and: [
              ...(contentFilter?.$and ?? []),
              opportunityFilterId
                ? { $and: { label: `opportunity_id:${opportunityFilterId}:true` } }
                : undefined
            ]
          }
        }
      } as SavedFilterContentAdvanced

      const notificationConfig: NotificationConfigModel[] = [
        { type: 'email', value: config.emails }
      ]

      if (config.channels.length > 0) {
        notificationConfig.push({ type: 'slack', value: config.channels })
      }

      // at least one config must be set
      if (notificationConfig.every(nConfig => !nConfig.value.length))
        throw new Error('No notification config set.')

      const [error, data] = await AlertsService.createAlert({
        areaId: areaFilterId,
        opportunityId: opportunityFilterId,
        filter: filter ?? {},
        notificationSchedule: config.schedule,
        name: config.name,
        notificationConfig: notificationConfig
      })

      if (error) throw error
      return data
    },
    onError: error => {
      const message = (error as Error).message ?? 'Failed to create notification.'
      logException(error, { message })
      addErrorToast({ text: message })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey })
      queryClient.invalidateQueries({ queryKey: [ALERTS_LIST_KEY_PREFIX], exact: false })
    },
    retry: false
  })

  const {
    mutate: updateAlert,
    isError: isUpdateError,
    isLoading: isUpdateLoading
  } = useMutation({
    mutationFn: async (config: UpdateAlertParams) => {
      const notificationConfig: NotificationConfigModel[] = [
        { type: 'email', value: config.emails }
      ]

      if (config.channels.length > 0) {
        notificationConfig.push({ type: 'slack', value: config.channels })
      }

      // at least one config must be set
      if (notificationConfig.every(nConfig => !nConfig.value.length))
        throw new Error('No notification config set.')

      const [error, data] = await AlertsService.updateAlert(config.originalAlert.id, {
        areaId: config.originalAlert.areaId,
        opportunityId: config.originalAlert.opportunityId,
        filter: config.originalAlert.filter,
        notificationSchedule: config.schedule,
        name: config.name,
        notificationConfig: notificationConfig
      })

      if (error) throw error
      return data
    },
    onError: error => {
      const message = (error as Error).message ?? 'Failed to create notification.'
      logException(error, { message })
      addErrorToast({ text: message })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey })
      queryClient.invalidateQueries({ queryKey: [ALERTS_LIST_KEY_PREFIX], exact: false })
    },
    retry: false
  })

  const {
    mutateAsync: removeAlert,
    isError: isRemoveError,
    isLoading: isRemoveLoading
  } = useMutation({
    mutationFn: async (alertId: string) => {
      const [error] = await AlertsService.removeAlert(alertId)

      if (error) throw error
      return alertId
    },
    onError: error => {
      let message = (error as Error).message ?? 'Failed to delete this notification.'
      if (error instanceof DefaultErrorHandler) {
        addErrorToast({ text: message })
      } else {
        message = 'Failed to delete this notification.'
        addErrorToast({ text: message })
      }
      logException(error, { message })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey })
      queryClient.invalidateQueries({ queryKey: [ALERTS_LIST_KEY_PREFIX], exact: false })
    },
    retry: false
  })

  const { mutate: checkAndUpdateAreaAlert } = useMutation({
    mutationFn: async (config: { filterId: string; newContent: [SavedFilterContentAdvanced] }) => {
      const { filterId, newContent } = config
      if (!newContent.length) return

      const [areaAlertError, areaAlertData] = await AlertsService.searchAlerts({
        version: 'v2',
        area_id: filterId,
        per_page: 1
      })

      if (areaAlertError) {
        logException(areaAlertError, {
          message: `Error while checking if the area ${filterId} has an alert.`
        })
      }

      const areaAlert = areaAlertData?.alerts[0]

      // already exists an alert, update its filters
      if (areaAlert) {
        const [updateAreaAlertError] = await AlertsService.updateAlert(areaAlert.id, {
          areaId: filterId,
          notificationSchedule: areaAlert.notification_schedule,
          name: areaAlert.description,
          notificationConfig: areaAlert.notification_config,
          filter: newContent.length > 0 ? (newContent[0] as SavedFilterContentAdvanced) : {}
        })

        if (updateAreaAlertError) {
          logException(updateAreaAlertError, {
            message: `Failed to update area alert ${areaAlert.id} after updating the area.`
          })
        }
      }

      const searchParams: OpportunityRequests.SearchOpportunityParams = {
        limit: 200,
        area_id: [filterId]
      }

      const [opportunitiesError, opportunitiesData] =
        await OpportunityService.searchOpportunities(searchParams)

      if (opportunitiesError) {
        logException(opportunitiesError, {
          message: `Failed to fetch opportunities for area ${filterId}.`
        })
      }

      // update opportunities alerts with the new filter, if they exisits
      if (opportunitiesData && opportunitiesData.opportunities.length > 0) {
        const opportunities = opportunitiesData.opportunities
        const opportunitiesPromises = opportunities.map(async opportunity => {
          const [opportunityAlertError, opportunityAlertData] = await AlertsService.searchAlerts({
            version: 'v2',
            area_id: filterId,
            opportunity_id: opportunity.filter_id,
            per_page: 1
          })

          if (opportunityAlertError) {
            logException(opportunityAlertError, {
              message: `Error while checking if the opportunity ${opportunity.filter_id} has an alert.`
            })
          }

          const opportunityAlert = opportunityAlertData?.alerts[0]
          if (opportunityAlert) {
            const [updateOpportunityAlertError] = await AlertsService.updateAlert(
              opportunityAlert.id,
              {
                areaId: filterId,
                opportunityId: opportunity.opportunity_id,
                notificationSchedule: opportunityAlert.notification_schedule,
                name: opportunityAlert.description,
                notificationConfig: opportunityAlert.notification_config,
                filter: newContent.length > 0 ? (newContent[0] as SavedFilterContentAdvanced) : {}
              }
            )

            if (updateOpportunityAlertError) {
              logException(updateOpportunityAlertError, {
                message: `Failed to update opportunity alert ${opportunityAlert.id} after updating the area.`
              })
            }
          }
        })

        await Promise.all(opportunitiesPromises)
      }
    },
    onError: error => {
      logException(error, {
        message: 'Failed to check and update area alerts.'
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey, exact: false })
      queryClient.invalidateQueries({ queryKey: [ALERTS_LIST_KEY_PREFIX], exact: false })
    },
    retry: false
  })

  return {
    currentAlert,
    isLoading,
    isError,
    error,
    queryKey,
    createAlert,
    isCreateError,
    isCreateLoading,
    updateAlert,
    isUpdateError,
    isUpdateLoading,
    removeAlert,
    isRemoveError,
    isRemoveLoading,
    checkAndUpdateAreaAlert
  }
}

export default useAlerts
