import { forwardRef } from 'react'
import { Link, LinkProps, useLocation } from 'react-router-dom'

/**
 * Custom, unstyled react router Link thats set the current path as "from" in the location state
 */
const LinkFromTo = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  const { pathname } = useLocation()

  return <Link {...props} state={{ ...props.state, from: pathname }} ref={ref} />
})

export default LinkFromTo
