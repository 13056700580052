import useFeedQueryParams from '@/hooks/feedback/new/useFeedQueryParams'
import FeedService from '@/services/FeedService'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import useAdvancedFiltersStore from '@/store/useFiltersStore/useAdvancedFiltersStore'
import useOpportunityStore from '@/store/useOpportunityStore'
import { FeedRequests } from '@/types/feed'
import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { shallow } from 'zustand/shallow'

const useSummaryQuery = () => {
  const isFetchingContext = useAdvancedFiltersStore(state => state.isFetchingContext)

  const { queryParams } = useFeedQueryParams()

  const currentInterestArea = useCurrentInterestAreaStore(
    state => state.currentInterestArea,
    shallow
  )
  const currentOpportunity = useOpportunityStore(state => state.currentOpportunity, shallow)

  const focus = useMemo(() => {
    const _focus: string[] = []
    if (currentInterestArea) _focus.push(currentInterestArea.name)
    if (currentOpportunity) _focus.push(currentOpportunity.name)
    return _focus
  }, [currentInterestArea, currentOpportunity])

  const queryFn = async (
    params: QueryFunctionContext<[string, FeedRequests.SummaryParams, string[]], string>
  ) => {
    const { queryKey, signal } = params
    const filterParams = queryKey[1]
    const focus = queryKey[2]

    const [error, response] = await FeedService.summary(
      {
        ...filterParams,
        focus
      },
      { signal }
    )

    if (error) throw error
    return response
  }

  const { data, isLoading, ...query } = useQuery({
    queryKey: ['feed-summary', queryParams, focus],
    queryFn
  })

  return {
    summary: data?.short ?? '',
    isLoading: isLoading || isFetchingContext,
    ...query
  }
}

export default useSummaryQuery
