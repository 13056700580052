import useFiltersToText from '@/hooks/filters/useFiltersToText'
import useOpportunitiesQuery from '@/hooks/opportunity/useOpportunitiesQuery'
import useUser from '@/hooks/useUser'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import { PromptTemplate } from '@/utils/chat'
import { shallow } from 'zustand/shallow'
import i18n from '../../../plugins/i18n/i18n'
import useOpportunityStore from '@/store/useOpportunityStore'

const usePrompts = () => {
  const { organization } = useUser()

  const currentInterestArea = useCurrentInterestAreaStore(
    state => state.currentInterestArea,
    shallow
  )
  const currentOpportunity = useOpportunityStore(state => state.currentOpportunity, shallow)

  const { allFiltersText } = useFiltersToText()

  const organizationName = organization?.name ?? 'company'
  const targetLanguage = organization?.targetLanguage ?? 'en-US'
  const areaOfInterest = currentInterestArea?.name

  const { opportunities } = useOpportunitiesQuery({
    area: currentInterestArea,
    enabled: !!currentInterestArea
  })

  if (currentOpportunity) {
    const granularOpportunities: PromptTemplate = {
      name: 'Refine opportunity details',
      message: `Refine details about ${currentOpportunity.name}`,
      prompt: `
You are an expert in user feedback analysis for the company ${organizationName}.
Carefully analyze each piece of feedback from the list to perform the following task:

List the main issue reasons (reasons for a given issue to happen) reported by the users specifically related to the issue "${currentOpportunity.name}". This list of issue reasons will be provided to the Customer Experience and Product departments of the company ${organizationName} and will be used to understand how these areas can improve the customer service process and the product itself. 
An issue reason must provide more details on why the given issue occurred. 

Generate a table titled “Potential reasons on the issue ‘${currentOpportunity.name}’ at ${organizationName}" listing the main issue reasons containing the following columns:
* Id - a sequential incremental number for the issue reason starting with 1
* Reference - containing a reference to feedback corresponding to the issue reason
* Issue Reason - the issue reason found
* Description - a better description of the issue reason

List up to five issue reasons, prioritizing the most prevalent ones.

After the table generation, remember the user that he/she can ask you to explore more or change (add, remove, merge, modify items) the table as desired.Write the title and the table in ${targetLanguage}.
      `
    }

    return [granularOpportunities]
  }

  const relatedSection = areaOfInterest ? `the theme "${areaOfInterest}"` : allFiltersText

  const opportunitySection = opportunities.length
    ? `
These are issues the company already knows: 
${opportunities.map(opportunity => `- ${opportunity.name}`).join('\n')}

You must not list any issues similar to the ones the company already knows. 
For me, to double-check it, add a new column “Possible relation to already known issue” where you state a possible similar already known issue if it has any.
  `
    : ''

  const issues: PromptTemplate = {
    name: i18n.t('uncoverOpportunitiesInReportedIssues'),
    message: i18n.t('listMainIssuesReportedByUsers', {
      areaOfInterest: areaOfInterest ? ` - ${areaOfInterest}` : ''
    }),
    prompt: `
You are an expert in user feedback analysis for the company ${organizationName}.
Carefully analyze each piece of feedback from the list to perform the following task:

List the main issues reported by the users specifically related to ${relatedSection}. This list of issues will be provided to the Customer Experience and Product departments of the company ${organizationName} and will be used to understand how these areas can improve the customer service process and the product itself. An issue can be a relevant problem reported by the users or a frequent request or question made by the users.

Generate a table titled "Main issues ${
      areaOfInterest ? `specifically related to ‘${areaOfInterest}’ ` : ''
    }at ${organizationName}" listing the main issues containing the following columns:
* Id - a sequential incremental number for the issue starting with 1
* Reference - containing a reference to feedback corresponding to the issue
* Issue - the issue found
* Description - a better description of the issue found

${opportunitySection}

List up to seven issues, prioritizing the most prevalent ${
      opportunities.length ? 'and unknown' : ''
    } ones.
Write the title and the table in ${targetLanguage}.
    `
  }

  const featureRequest: PromptTemplate = {
    name: i18n.t('uncoverRequestedFeatures'),
    message: i18n.t('listTheCommonFeaturesRequestedByUsers', {
      areaOfInterest: areaOfInterest ? ` - ${areaOfInterest}` : ''
    }),
    prompt: `
You are an expert in user feedback analysis for the company ${organizationName}. 

Carefully analyze each piece of feedback from the list to perform the following task:

Find the main feature requests the users from ${organizationName} are reporting to support ${organizationName} product roadmap prioritization.

Generate a table titled "Features requested by ${organizationName} users" listing the main features requested by the users with the following columns:

- Id - a sequential incremental number for the issue starting with 1
- Reference - containing one reference to feedback corresponding to the feature request
- Feature requested - the feature requested by the users
- Description - a better description of the feature

List up to ten feature requests, prioritizing the most prevalent ones.

Write the title and the table in ${targetLanguage}.
    `
  }

  return [issues, featureRequest]
}

export default usePrompts
