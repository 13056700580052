import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import { filterItemCSS } from '../../saved-filters/SavedFiltersPopover.styles'
import Text from '@/components/atoms/text'
import FlexContainer from '@/components/atoms/flex-container'
import { CheckCircle, TrashSimple } from '@phosphor-icons/react'
import { colors, styled } from '@/theme'
import OptionsMenu, { OptionsMenuItem } from '@/components/atoms/options-menu'
import { PencilSimple } from '@phosphor-icons/react/dist/ssr'
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import { BaseInterestArea } from '@/types/area/AreaOfInterest'
import { snakeToTitle } from '@/utils/letterCase'

const DropdownItem = styled(DropdownMenuPrimitive.Item, {
  ...filterItemCSS,
  width: '100%',
  '.options': {
    ml: 'auto'
  },

  '&[data-highlighted]': {
    backgroundColor: '$brandPrimaryLight',
    color: '$brandPrimaryPure',
    '& > .options': {
      opacity: 1
    }
  }
  // maxWidth: 'calc(380px - $(micro * 2)px)'
})

interface Props {
  area: BaseInterestArea
  hideOptions?: boolean
  onAreaClick: (area: BaseInterestArea) => void
  onRenameAreaClick: (area: BaseInterestArea) => void
  onDeleteAreaClick: (area: BaseInterestArea) => void
}

const AreaOfInterestDropdownItem = ({
  area,
  hideOptions,
  onAreaClick,
  onRenameAreaClick,
  onDeleteAreaClick
}: Props) => {
  const currentAreaOfInterest = useCurrentInterestAreaStore(state => state.currentInterestArea)
  const isApplied = currentAreaOfInterest?.id === area.id

  const onClick = () => {
    if (isApplied) return
    onAreaClick(area)
  }

  const options: OptionsMenuItem[] = [
    {
      text: 'Rename',
      icon: <PencilSimple size={16} />,
      onClick: () => onRenameAreaClick(area)
    },
    {
      text: 'Delete',
      icon: <TrashSimple size={16} />,
      onClick: () => onDeleteAreaClick(area)
    }
  ]

  const name = area.isUnmappedArea ? snakeToTitle(area.name) : area.name

  return (
    <DropdownItem onClick={onClick}>
      <FlexContainer alignItems="center" fullWidth gap="nano" title={name}>
        <Text as="p" fontSize="xxxs" lineHeight="xxl" title={name} truncate>
          {name}
        </Text>
        {isApplied && <CheckCircle color={colors.feedbackPositivePure} size={16} />}
        <span className="options">
          {!hideOptions && <OptionsMenu options={options} stopPropagation />}
        </span>
      </FlexContainer>
    </DropdownItem>
  )
}

export default AreaOfInterestDropdownItem
