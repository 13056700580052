import Search, { SearchProps } from '@/components/molecules/search'

const SearchInputForFilters = (props: SearchProps) => {
  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = e => {
    // Prevent losing focus on input when pressing the letter "a", the focus is moved to the button "Apply filter"
    if (['a', 'p', 'o'].includes(e.key.toLowerCase())) e.stopPropagation()

    props.onKeyDown?.(e)
  }

  return (
    <Search
      {...props}
      css={{
        ...props.css,

        '&:focus-within': {
          border: '2px solid $brandPrimaryPure'
        }
      }}
      onKeyDown={onKeyDown}
    />
  )
}

export default SearchInputForFilters
