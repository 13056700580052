import useCompetitorsModeStore from '@/store/useCompetitorsModeStore'
import useEnumFiltersQuery from '@/hooks/filters/useEnumFiltersQuery'
import { useEffect } from 'react'
import useFieldsQuery from './useFieldsQuery'

const REVIEW_OWNER_FILTER_NAME = 'review.owner'

const useCompetitorsMode = () => {
  const {
    competitorsModeEnabled,
    hasCompetitorsFilter,
    setCompetitorsModeEnabled,
    setHasCompetitorsMode,
    setCompetitorsFilterValues
  } = useCompetitorsModeStore()

  const { data, isFetched } = useEnumFiltersQuery({ name: REVIEW_OWNER_FILTER_NAME, enabled: true })
  const { data: fieldsData } = useFieldsQuery()

  useEffect(() => {
    if (
      isFetched &&
      data?.values &&
      data.values.map(value => value.toLowerCase()).includes('competitor') &&
      fieldsData?.fields.find(field => field.name === REVIEW_OWNER_FILTER_NAME)
    ) {
      setHasCompetitorsMode(true)
      setCompetitorsFilterValues(data.values)
    }
  }, [data, isFetched, fieldsData, setCompetitorsFilterValues, setHasCompetitorsMode])

  return {
    competitorsModeEnabled,
    hasCompetitorsFilter,
    setCompetitorsModeEnabled,
    setHasCompetitorsMode
  }
}

export default useCompetitorsMode
