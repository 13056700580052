import { colors } from '@/theme'
import { ChartType } from '@/types/dashboard'
import {
  BreakdownOption,
  TimeSeriesAnnotation,
  TimeSeriesGroup,
  TimeSeriesIntervalOption
} from '@/types/time-series/TimeSeries'
import { EChartOption } from 'echarts'
import { useCallback, useMemo } from 'react'
import getTrendLineEChartOptions from '../analytics/trend-line/trendLineEChartOptions'
import { mapTimeSeriesIntervalToAnalyticsInterval } from '@/hooks/analytics/useTimeSeriesOptions'
import ChartSkeleton from '../analytics/skeletons/ChartSkeleton'
import EChart from '@/components/atoms/echart'
import moment from 'moment'
import { dateShortMonthFormat, intervalFormat } from '@/utils/date'
import { scrollableContainer, tooltipContainer, tooltipSection, valueRow } from './tooltip'
import { allMetricItems } from '@/utils/metrics'
import TimeseriesTable from '../table/TimeseriesTable'
import { EchartCustomSeries } from '@/types'
import { intentionTextColor } from '@/utils/intention'
import { AllMetricsKey } from '@/types/metrics'
import NoResults from '@/components/atoms/no-results'
import CompetitorsEnabledButton from './CompetitorsEnabledButton'

interface Props {
  timestamps: moment.Moment[]
  timeSeries: TimeSeriesGroup[]
  interval: TimeSeriesIntervalOption
  metric: AllMetricsKey
  breakdownText?: string
  chartType?: ChartType
  isLoading?: boolean
  annotations?: TimeSeriesAnnotation[]
  columnBreakdown: BreakdownOption
  breakdown: BreakdownOption
  onTransposeClick: () => void
  onAnnotationsClick?: (annotations: TimeSeriesAnnotation[]) => void
  onCopy?: () => void
}

const seriesColor: Record<string, string> = {
  negative: colors.feedbackNegativePure,
  neutral: colors.neutralLowLight,
  positive: colors.feedbackPositivePure,
  problem: colors.feedbackNegativePure,
  ...intentionTextColor
}

const getSeriesColor = (seriesName: string) => {
  return seriesColor[seriesName.toLowerCase()] ?? undefined
}

const MetricsTrendLine = ({
  timestamps,
  timeSeries,
  interval,
  metric,
  chartType = 'line',
  isLoading = false,
  breakdownText,
  annotations = [],
  breakdown,
  columnBreakdown,
  onAnnotationsClick,
  onCopy,
  onTransposeClick
}: Props) => {
  const legend: EChartOption.Legend = useMemo(
    () => ({
      type: 'scroll',
      pageTextStyle: {
        fontFamily: 'Lexend',
        color: colors.neutralLowPure
      },
      pageIconColor: colors.brandPrimaryPure,
      pageIconInactiveColor: colors.brandPrimaryLight,
      pageIconSize: 12
    }),
    []
  )

  const annotationsByXAxis = useMemo(() => {
    const _annotationByXAxis: Record<string, TimeSeriesAnnotation[]> = {}
    annotations.forEach(annotation => {
      const xAxis = intervalFormat(
        annotation.timestamp,
        mapTimeSeriesIntervalToAnalyticsInterval[interval]
      )
      _annotationByXAxis[xAxis] = _annotationByXAxis[xAxis] || []
      _annotationByXAxis[xAxis].push(annotation)
    })
    return _annotationByXAxis
  }, [annotations, interval])

  const annotationsMarklines = useMemo(() => {
    return Object.entries(annotationsByXAxis).map(([xAxis, annotations]) => ({
      name: xAxis,
      xAxis,
      lineStyle: {
        color: colors.brandPrimaryPure,
        type: 'solid'
      },
      label: {
        color: colors.neutralHighLight,
        backgroundColor: colors.brandPrimaryPure,
        distance: chartType === 'line' ? -40 : 4,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: colors.neutralHighLight,
        padding: [8, 12],
        formatter: `${annotations.length}`,
        fontFamily: 'Lexend',
        fontSize: 12
      },
      emphasis: {
        label: {
          shadowColor: colors.neutralLowLight50,
          shadowBlur: 12
        }
      }
    }))
  }, [chartType, annotationsByXAxis])

  const marklines = annotationsMarklines

  const tooltipFormatter = useCallback(
    (params: EChartOption.Tooltip.Format[]) => {
      const generalData = params[0]
      if (!generalData) return ''

      const initialDate = moment(timestamps[generalData.dataIndex ?? 0]).add(1, 'day')
      const intervalUnit = mapTimeSeriesIntervalToAnalyticsInterval[interval].at(
        1
      ) as moment.unitOfTime.DurationConstructor
      const finalDateOfRange = moment(initialDate).add(1, intervalUnit).subtract(1, 'day')
      const nowDate = moment()
      const finalDate = finalDateOfRange.isSameOrAfter(nowDate) ? nowDate : finalDateOfRange

      const dateContent =
        interval === TimeSeriesIntervalOption.Day
          ? generalData.name
          : `${dateShortMonthFormat(initialDate.toDate())} to ${dateShortMonthFormat(
              finalDate.toDate()
            )}`

      const tooltipAnnotations =
        annotationsByXAxis[generalData.name ?? '']?.map(annotation => {
          const annotationDate = dateShortMonthFormat(annotation.annotationDate.toDate())

          return /* html */ `
        <div class="${tooltipSection}">
          <span class="muted">${annotationDate}</span> 
          <span>${annotation.name}</span>
        </div>
      `
        }) ?? []

      const sortedParams = [...params].sort(
        (a, b) =>
          (typeof b.value === 'number' ? b.value : 0) - (typeof a.value === 'number' ? a.value : 0)
      )
      const rows = sortedParams
        .map(item => {
          const name: string = item.seriesName ?? ''

          const timeseriesMetric = allMetricItems[metric]
          if (!timeseriesMetric) return null
          const value: string = timeseriesMetric.formatter(
            typeof item.value === 'number' ? item.value : 0
          )
          const color: string = item.color ?? colors.neutralHighMedium

          return /* html */ `
        <div class="${tooltipSection}" style="border-color: ${color} !important">
          <div class="${valueRow}">
            <span>${name}</span>
            <span>${value}</span>
          </div>
        </div>
      `
        })
        .filter(Boolean) as string[]

      return /* html */ `
      <div class="${tooltipContainer}">
        <span class="muted">${allMetricItems[metric]?.label ?? ''} - ${dateContent}</span>
        <div class="${scrollableContainer}">
          ${rows.join('\n')}
        </div>
        ${tooltipAnnotations.join('\n')}
      </div> 
    `
    },
    [timestamps, metric, interval, annotationsByXAxis]
  )

  const lineSeries = useMemo(() => {
    return timeSeries.length
      ? timeSeries.map(
          (series, index): EChartOption.Series =>
            ({
              data: series.values,
              type: 'line',
              name: series.name,
              smooth: true,
              showSymbol: false,
              animation: !(annotations.length > 0),
              color: getSeriesColor(series.name),
              markLine:
                index === 0 ? { symbol: 'none', data: marklines } : { symbol: 'none', data: [] }
            }) as EChartOption.SeriesLine
        )
      : [{ data: [], type: 'line' }]
  }, [timeSeries, marklines, annotations])

  const lineChartOptions: EChartOption = useMemo(
    () =>
      getTrendLineEChartOptions({
        timestamps,
        timeSeries,
        interval: mapTimeSeriesIntervalToAnalyticsInterval[interval],
        xAxis: {
          boundaryGap: [],
          axisTick: {
            show: false
          }
        },
        enableDataZoom: false,
        legend,
        tooltipFormatter,
        disableSortSeries: true,
        series: [...lineSeries]
      }),
    [lineSeries, timestamps, interval, timeSeries, legend, tooltipFormatter]
  )

  const barSeries = useMemo(() => {
    const baseSeriesProps = {
      type: 'bar',
      barGap: '5%',
      stack: chartType === 'stacked-bar' ? 'total' : undefined,
      barCategoryGap: '10%',
      barMaxWidth: 40
    }

    return timeSeries.length
      ? timeSeries.map(
          (series, index): EchartCustomSeries =>
            ({
              data: series.values,
              ...baseSeriesProps,
              labelLayout: {
                hideOverlap: true
              },
              label: {
                show: true,
                overflow: 'truncate',
                // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                formatter: (params: any) => {
                  const value = Number(params.value)
                  const currentMetric = allMetricItems[metric]
                  return value === 0 ? '' : currentMetric.formatter(value)
                }
              },
              animation: !(annotations.length > 0),
              itemStyle: {
                color: getSeriesColor(series.name)
              },
              markLine:
                index === 0 ? { symbol: 'none', data: marklines } : { symbol: 'none', data: [] },
              name: series.name
            }) as EchartCustomSeries
        )
      : [{ data: [], type: 'bar' }]
  }, [timeSeries, chartType, metric, annotations.length, marklines])

  const barChartOptions: EChartOption = useMemo(
    () =>
      getTrendLineEChartOptions({
        timestamps,
        timeSeries,
        interval: mapTimeSeriesIntervalToAnalyticsInterval[interval],
        xAxis: {
          boundaryGap: [],
          axisTick: {
            show: false
          }
        },
        enableDataZoom: false,
        legend,
        series: [...barSeries],
        tooltipFormatter
      }),
    [barSeries, timestamps, interval, timeSeries, legend, tooltipFormatter]
  )

  const chartOptions = chartType === 'line' ? lineChartOptions : barChartOptions

  const onEvents = {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    click: (params: any) => {
      const annotations = annotationsByXAxis[params.name]
      if (annotations) {
        onAnnotationsClick?.(annotations)
      }
    }
  }

  const isTimeseriesTransposed = breakdown === 'date'

  const isEmptyTimeseries =
    !isLoading &&
    (timeSeries.every(serie => serie.values.every(value => value === 0)) || timeSeries.length === 0)

  if (isLoading) {
    return <ChartSkeleton height={280} />
  }

  if (isEmptyTimeseries) {
    return (
      <>
        <NoResults css={{ mt: 0, gap: '$xs', color: '$neutralLowPure' }} hideDescription />
        <CompetitorsEnabledButton />
      </>
    )
  }

  if (chartType === 'table') {
    if (breakdown === 'date' || columnBreakdown === 'date') {
      return (
        <TimeseriesTable
          interval={interval}
          isTransposed={isTimeseriesTransposed}
          key={JSON.stringify({ timestamps, interval, timeSeries, breakdownText })}
          onCopy={onCopy}
          onTransposeClick={onTransposeClick}
          timeSeries={timeSeries}
          timestamps={timestamps}
        />
      )
    }
  }

  return (
    <>
      <EChart
        height="300px"
        notMerge
        onEvents={onEvents}
        option={chartOptions}
        style={{ marginLeft: -52, marginRight: -32 }}
      />

      <CompetitorsEnabledButton />
    </>
  )
}

export default MetricsTrendLine
