import useAdvancedFilters from '@/hooks/advancedFilters/useAdvancedFilters'
import useSegment from '@/hooks/useSegment'
import useSearchInputStore from '@/store/useSearchInputStore'
import { CSS } from '@/theme'
import { shallow } from 'zustand/shallow'
import { FiltersContainer, HeaderContainer } from '../HeaderFilters.styles'
import SearchFilter from '../SearchFilter'
import { useMemo, useState } from 'react'
import useDateFilterStore from '@/store/useFiltersStore/useDateFilterStore'
import DateFilter from '../DateFilter'
import { camelToSnakeCase } from '@/utils/letterCase'
import { Period } from '@/types/periods'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import { useUIStore } from '@/store'
import FiltersButton from '../FiltersButton'
import PageOptions from '../PageOptions'
import { GenericFilter } from '@/types/filters/AdvancedFilters'
import FeedbackService from '@/services/FeedbackService'
import FlexContainer from '@/components/atoms/flex-container'
import useOpportunityStore from '@/store/useOpportunityStore'
import ExplorationBreadcrumb from '../../exploration/ExplorationBreadcrumb'
import ExplorationBackButton from '../../exploration/ExplorationBackButton'

interface Props {
  css?: CSS
  searchPlaceholder?: string
}

const AdvancedFiltersHeader = ({ css, searchPlaceholder = 'Search for feedback' }: Props) => {
  const { track } = useSegment()

  const isFiltersOpen = useUIStore(state => state.isAdvancedFiltersOpen)
  const toggleFiltersOpen = useUIStore(state => state.toggleAdvancedFiltersOpen)

  const setCurrentOpportunity = useOpportunityStore(state => state.setCurrentOpportunity)

  const currentInterestArea = useCurrentInterestAreaStore(state => state.currentInterestArea)
  const {
    upinsertFilter,
    filters,
    simpleFiltersCount: filterCount,
    removeFilter
  } = useAdvancedFilters()
  const dateFilter = useDateFilterStore(state => state, shallow)

  const { textInput } = useSearchInputStore(
    state => ({
      textInput: state.textInput
    }),
    shallow
  )

  const searchFilter = useMemo(() => {
    if (filters.operator !== '$and') return
    const foundFilter = (filters.value as GenericFilter[]).find(
      filter => filter.name === 'text.feedback' && !filter.isFromArea
    )
    return foundFilter
  }, [filters])

  const searchValue = useMemo(() => (searchFilter?.value ?? '') as string, [searchFilter?.value])

  const [isSearchInvalid, setIsSearchInvalid] = useState(false)
  const invalidSearchError = isSearchInvalid ? 'Invalid text.' : undefined

  const onClear = () => {
    if (filters.operator !== '$and') return
    const findFilterIndex = (filters.value as GenericFilter[]).findIndex(
      filter => filter.name === 'text.feedback' && !filter.isFromArea
    )

    removeFilter({ index: findFilterIndex })
  }

  const onSearch = async () => {
    const newSearch = textInput.trim()
    const isNewSearch = newSearch !== searchValue

    if (newSearch === '') {
      setIsSearchInvalid(false)
    }

    if (!isNewSearch) {
      setIsSearchInvalid(false)
      return
    }

    track('explore_user_feedback_search')

    if (newSearch === '') {
      onClear()
      return
    }

    const [error] = await FeedbackService.validateQuery(newSearch)
    if (error) {
      setIsSearchInvalid(true)
      return
    }

    setIsSearchInvalid(false)

    upinsertFilter({
      status: 'valid',
      name: 'text.feedback',
      type: 'text',
      value: newSearch,
      path: 'text'
    })
  }

  function onOpen() {
    track('explore_user_date_range_filter-open')
  }

  function onApply() {
    track('explore_user_date_range_filter_apply')
  }

  function onChangePeriod(period: Period) {
    track('explore_user_date_range_filter-options', { data_type: camelToSnakeCase(period) })
  }

  function onSelectCustom() {
    track('explore_user_date_range_filter-options', { data_type: 'custom' })
  }

  function onSelectAllTime() {
    track('explore_user_date_range_filter-clear')
  }

  function onCalendarClose() {
    track('explore_user_date_range_filter-cancel')
  }

  const onFiltersClick = () => {
    toggleFiltersOpen()

    if (currentInterestArea) {
      track('area_in_filter')
    } else {
      track('exploration_in_filter')
    }
  }

  const onExitOpportunity = () => {
    setCurrentOpportunity(undefined)
  }

  return (
    <HeaderContainer css={css} opportunityPlan>
      <FlexContainer alignItems="start" direction="column" fullWidth gap="micro">
        <ExplorationBreadcrumb />
        <FlexContainer alignItems="center" gap="micro" fullWidth>
          <FiltersContainer>
            <ExplorationBackButton onClick={onExitOpportunity} />
            <FiltersButton count={filterCount} isOpen={isFiltersOpen} onClick={onFiltersClick} />
            <DateFilter
              appliedPeriod={dateFilter.datePeriod}
              appliedRange={dateFilter.dateRange}
              key={JSON.stringify(dateFilter)}
              onApply={onApply}
              onCalendarClose={onCalendarClose}
              onChangePeriod={onChangePeriod}
              onOpen={onOpen}
              onSelectAllTime={onSelectAllTime}
              onSelectCustom={onSelectCustom}
              setDateRange={dateFilter.setDateFilter}
              triggerCss={{ bc: '$neutralHighLight' }}
            />
            <SearchFilter
              error={invalidSearchError}
              onClear={onClear}
              onSearch={onSearch}
              searchPlaceholder={searchPlaceholder}
              storedValue={searchValue}
            />
          </FiltersContainer>
          <PageOptions />
        </FlexContainer>
      </FlexContainer>
    </HeaderContainer>
  )
}

export default AdvancedFiltersHeader
