import Button, { ButtonProps } from '@/components/atoms/button'
import useAdvancedFilters from '@/hooks/advancedFilters/useAdvancedFilters'
import useSavedFilters from '@/hooks/useSavedFilters'
import useUser from '@/hooks/useUser'
import { CSS } from '@/theme'

interface Props {
  css?: CSS
  buttonSize?: ButtonProps['size']
  buttonVariant?: ButtonProps['variant']
}

const UpdateAreaButton = ({ css, buttonSize = 'small', buttonVariant = 'flat' }: Props) => {
  const { hasChanges, simpleFiltersCount: filtersCount } = useAdvancedFilters()

  const { updateArea, isUpdatingArea } = useSavedFilters({ newFeed: true })

  const isEmpty = hasChanges && filtersCount === 0

  const disabled = !hasChanges || isEmpty || isUpdatingArea

  const { userPermissions } = useUser()

  const canUpdate = userPermissions.areas.includes('manager')

  if (!canUpdate) return <></>

  return (
    <Button
      css={css}
      disabled={disabled}
      onClick={updateArea}
      size={buttonSize}
      variant={buttonVariant}
    >
      Update area
    </Button>
  )
}

export default UpdateAreaButton
